/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M224,124H32a20,20,0,0,0-20,20v32a20,20,0,0,0,20,20H224a20,20,0,0,0,20-20V144A20,20,0,0,0,224,124ZM36,148H76v24H36Zm184,24H100V148H220ZM201.67,55.89c7.53-12.71,7.12-19.63,5.07-21.1A12,12,0,0,1,216.67,13a25.9,25.9,0,0,1,13.8,15.79c3.6,10.92.86,24.17-8.14,39.38-7.53,12.7-7.12,19.63-5.07,21.1a12,12,0,0,1-9.93,21.84,26,26,0,0,1-13.8-15.79C189.93,84.34,192.67,71.09,201.67,55.89Zm-48,0c7.53-12.71,7.12-19.63,5.07-21.1A12,12,0,0,1,168.67,13a25.9,25.9,0,0,1,13.8,15.79c3.6,10.92.86,24.17-8.14,39.38-7.53,12.7-7.12,19.63-5.07,21.1a12,12,0,0,1-9.93,21.84,26,26,0,0,1-13.8-15.79C141.93,84.34,144.67,71.09,153.67,55.89Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M88,136v48H32a8,8,0,0,1-8-8V144a8,8,0,0,1,8-8Z" opacity="0.2"/><path d="M224,128H32a16,16,0,0,0-16,16v32a16,16,0,0,0,16,16H224a16,16,0,0,0,16-16V144A16,16,0,0,0,224,128ZM32,144H80v32H32Zm192,32H96V144H224v32ZM201,60.08c8-14.23,7.42-21.71,6.36-24.91a7.79,7.79,0,0,0-2.64-3.86,8,8,0,1,1,6.5-14.62,22.57,22.57,0,0,1,11.32,13.44c3.36,10.14.81,22.85-7.6,37.79-8,14.23-7.42,21.72-6.36,24.92a7.79,7.79,0,0,0,2.64,3.85,8,8,0,1,1-6.5,14.62,22.53,22.53,0,0,1-11.32-13.44C190.07,87.73,192.62,75,201,60.08Zm-40,0c8-14.23,7.42-21.71,6.36-24.91a7.79,7.79,0,0,0-2.64-3.86,8,8,0,1,1,6.5-14.62,22.57,22.57,0,0,1,11.32,13.44c3.36,10.14.81,22.85-7.6,37.79-8,14.23-7.42,21.72-6.36,24.92a7.79,7.79,0,0,0,2.64,3.85,8,8,0,1,1-6.5,14.62,22.53,22.53,0,0,1-11.32-13.44C150.07,87.73,152.62,75,161,60.08Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M224,128H32a16,16,0,0,0-16,16v32a16,16,0,0,0,16,16H224a16,16,0,0,0,16-16V144A16,16,0,0,0,224,128Zm0,48H96V144H224v32ZM201,60.08c8-14.23,7.42-21.71,6.36-24.91a7.79,7.79,0,0,0-2.64-3.86,8,8,0,1,1,6.5-14.62,22.57,22.57,0,0,1,11.32,13.44c3.36,10.14.81,22.85-7.6,37.79-8,14.23-7.42,21.72-6.36,24.92a7.79,7.79,0,0,0,2.64,3.85,8,8,0,1,1-6.5,14.62,22.53,22.53,0,0,1-11.32-13.44C190.07,87.73,192.62,75,201,60.08Zm-40,0c8-14.23,7.42-21.71,6.36-24.91a7.79,7.79,0,0,0-2.64-3.86,8,8,0,1,1,6.5-14.62,22.57,22.57,0,0,1,11.32,13.44c3.36,10.14.81,22.85-7.6,37.79-8,14.23-7.42,21.72-6.36,24.92a7.79,7.79,0,0,0,2.64,3.85,8,8,0,1,1-6.5,14.62,22.53,22.53,0,0,1-11.32-13.44C150.07,87.73,152.62,75,161,60.08Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M224,130H32a14,14,0,0,0-14,14v32a14,14,0,0,0,14,14H224a14,14,0,0,0,14-14V144A14,14,0,0,0,224,130ZM30,176V144a2,2,0,0,1,2-2H82v36H32A2,2,0,0,1,30,176Zm196,0a2,2,0,0,1-2,2H94V142H224a2,2,0,0,1,2,2ZM202.77,61.06c7.81-13.88,7.91-21.91,6.62-26.2a9,9,0,0,0-3.93-5.42A6,6,0,0,1,208,18a5.87,5.87,0,0,1,2.44.52,20.42,20.42,0,0,1,10.23,12.24c3.18,9.58.68,21.75-7.44,36.18-7.81,13.88-7.91,21.91-6.62,26.2a8.94,8.94,0,0,0,3.88,5.4A6,6,0,0,1,208,110a5.87,5.87,0,0,1-2.44-.52,20.42,20.42,0,0,1-10.23-12.24C192.15,87.66,194.65,75.49,202.77,61.06Zm-40,0c7.81-13.88,7.91-21.91,6.62-26.2a9,9,0,0,0-3.93-5.42A6,6,0,0,1,168,18a5.87,5.87,0,0,1,2.44.52,20.42,20.42,0,0,1,10.23,12.24c3.18,9.58.68,21.75-7.44,36.18-7.81,13.88-7.91,21.91-6.62,26.2a8.94,8.94,0,0,0,3.88,5.4A6,6,0,0,1,168,110a5.87,5.87,0,0,1-2.44-.52,20.42,20.42,0,0,1-10.23-12.24C152.15,87.66,154.65,75.49,162.77,61.06Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M224,128H32a16,16,0,0,0-16,16v32a16,16,0,0,0,16,16H224a16,16,0,0,0,16-16V144A16,16,0,0,0,224,128ZM32,144H80v32H32Zm192,32H96V144H224v32ZM201,60.08c8-14.23,7.42-21.71,6.36-24.91a7.79,7.79,0,0,0-2.64-3.86,8,8,0,1,1,6.5-14.62,22.57,22.57,0,0,1,11.32,13.44c3.36,10.14.81,22.85-7.6,37.79-8,14.23-7.42,21.72-6.36,24.91a7.79,7.79,0,0,0,2.64,3.86,8,8,0,1,1-6.5,14.62,22.57,22.57,0,0,1-11.32-13.44C190.07,87.73,192.62,75,201,60.08Zm-40,0c8-14.23,7.42-21.71,6.36-24.91a7.79,7.79,0,0,0-2.64-3.86,8,8,0,1,1,6.5-14.62,22.57,22.57,0,0,1,11.32,13.44c3.36,10.14.81,22.85-7.6,37.79-8,14.23-7.42,21.72-6.36,24.91a7.79,7.79,0,0,0,2.64,3.86,8,8,0,1,1-6.5,14.62,22.57,22.57,0,0,1-11.32-13.44C150.07,87.73,152.62,75,161,60.08Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M224,132H32a12,12,0,0,0-12,12v32a12,12,0,0,0,12,12H224a12,12,0,0,0,12-12V144A12,12,0,0,0,224,132ZM28,176V144a4,4,0,0,1,4-4H84v40H32A4,4,0,0,1,28,176Zm200,0a4,4,0,0,1-4,4H92V140H224a4,4,0,0,1,4,4ZM204.51,62c8.22-14.61,8.21-23.23,6.75-27.9a10.82,10.82,0,0,0-4.94-6.51,4,4,0,0,1-2-5.26,4,4,0,0,1,5.3-2c.66.29,6.52,3.09,9.16,11,3,9,.53,20.66-7.29,34.57-8.22,14.61-8.21,23.24-6.75,27.91a10.74,10.74,0,0,0,4.94,6.5A4,4,0,0,1,208,108a4.09,4.09,0,0,1-1.63-.35c-.66-.3-6.52-3.09-9.16-11C194.23,87.59,196.69,76,204.51,62Zm-40,0c8.22-14.61,8.21-23.23,6.75-27.9a10.82,10.82,0,0,0-4.94-6.51,4,4,0,0,1-2-5.26,4,4,0,0,1,5.3-2c.66.29,6.52,3.09,9.16,11,3,9,.53,20.66-7.29,34.57-8.22,14.61-8.21,23.24-6.75,27.91a10.74,10.74,0,0,0,4.94,6.5A4,4,0,0,1,168,108a4.09,4.09,0,0,1-1.63-.35c-.66-.3-6.52-3.09-9.16-11C154.23,87.59,156.69,76,164.51,62Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
