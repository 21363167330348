/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M208.5,192.49a10.6,10.6,0,0,0,0,15,12,12,0,0,1-17,17,34.62,34.62,0,0,1,0-49l9.37-9.37a10.63,10.63,0,0,0-15-15l-66.76,66.75a34.63,34.63,0,0,1-49-49l98.76-98.75a10.63,10.63,0,0,0-15-15L87.11,121.86a34.63,34.63,0,0,1-49-49L79.51,31.51a12,12,0,1,1,17,17L55.1,89.86a10.63,10.63,0,0,0,15,15l66.76-66.75a34.63,34.63,0,1,1,49,49L87.11,185.86a10.63,10.63,0,0,0,15,15l66.75-66.75a34.63,34.63,0,0,1,49,49Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M216,56V200a16,16,0,0,1-16,16H56a16,16,0,0,1-16-16V56A16,16,0,0,1,56,40H200A16,16,0,0,1,216,56Z" opacity="0.2"/><path d="M205.67,189.66a14.61,14.61,0,0,0,0,20.68,8,8,0,0,1-11.32,11.32,30.64,30.64,0,0,1,0-43.32l9.38-9.37A14.63,14.63,0,0,0,183,148.28L116.28,215A30.63,30.63,0,0,1,73,171.72L171.72,73A14.63,14.63,0,0,0,151,52.28L84.28,119A30.63,30.63,0,0,1,41,75.72L82.34,34.34A8,8,0,0,1,93.65,45.66L52.27,87A14.63,14.63,0,0,0,73,107.72L139.72,41A30.63,30.63,0,0,1,183,84.28L84.28,183A14.63,14.63,0,0,0,105,203.72L171.72,137A30.63,30.63,0,0,1,215,180.28Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM193.66,169.66l-8,8a9,9,0,0,0,0,12.68l4,4a8,8,0,0,1-11.32,11.32l-4-4a25,25,0,0,1,0-35.32l8-8a9,9,0,0,0,0-12.68,9,9,0,0,0-12.68,0l-48,48a25,25,0,0,1-35.32-35.32l72-72a9,9,0,0,0,0-12.68,9,9,0,0,0-12.68,0l-48,48A25,25,0,0,1,62.34,86.34l28-28a8,8,0,0,1,11.32,11.32l-28,28a9,9,0,0,0,0,12.68,9,9,0,0,0,12.68,0l48-48a25,25,0,0,1,35.32,35.32l-72,72a9,9,0,0,0,0,12.68,9,9,0,0,0,12.68,0l48-48a25,25,0,0,1,35.32,35.32Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M204.25,188.24a16.63,16.63,0,0,0,0,23.52,6,6,0,1,1-8.48,8.48,28.61,28.61,0,0,1,0-40.48l9.37-9.38a16.63,16.63,0,0,0-23.52-23.51l-66.75,66.75a28.63,28.63,0,0,1-40.49-40.49l98.76-98.75a16.63,16.63,0,0,0-23.52-23.51L82.86,117.62A28.63,28.63,0,0,1,42.37,77.13L83.75,35.76a6,6,0,1,1,8.49,8.48L50.86,85.62a16.63,16.63,0,0,0,23.52,23.51l66.75-66.75a28.63,28.63,0,0,1,40.49,40.49L82.86,181.62a16.63,16.63,0,0,0,23.52,23.51l66.76-66.75a28.63,28.63,0,0,1,40.49,40.49Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M205.67,189.66a14.61,14.61,0,0,0,0,20.68,8,8,0,0,1-11.32,11.32,30.64,30.64,0,0,1,0-43.32l9.38-9.37A14.63,14.63,0,0,0,183,148.28L116.28,215A30.63,30.63,0,0,1,73,171.72L171.72,73A14.63,14.63,0,0,0,151,52.28L84.28,119A30.63,30.63,0,0,1,41,75.72L82.34,34.34A8,8,0,0,1,93.65,45.66L52.27,87A14.63,14.63,0,0,0,73,107.72L139.72,41A30.63,30.63,0,0,1,183,84.28L84.28,183A14.63,14.63,0,0,0,105,203.72L171.72,137A30.63,30.63,0,0,1,215,180.28Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M202.84,186.83a18.61,18.61,0,0,0,0,26.34,4,4,0,0,1-5.66,5.66,26.63,26.63,0,0,1,0-37.66l9.38-9.37a18.63,18.63,0,1,0-26.35-26.34L113.45,212.2a26.63,26.63,0,0,1-37.66-37.66L174.55,75.8A18.63,18.63,0,1,0,148.2,49.46L81.45,116.2A26.63,26.63,0,0,1,43.79,78.54L85.17,37.17a4,4,0,1,1,5.65,5.66L49.44,84.2a18.63,18.63,0,1,0,26.35,26.34L142.55,43.8a26.63,26.63,0,0,1,37.66,37.66L81.45,180.2a18.63,18.63,0,1,0,26.35,26.34l66.75-66.74a26.63,26.63,0,0,1,37.66,37.66Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
