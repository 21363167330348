/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M176,84a36,36,0,1,0-36-36A36,36,0,0,0,176,84Zm0-48a12,12,0,1,1-12,12A12,12,0,0,1,176,36Zm29.28,96.39a12,12,0,0,1,2.49,10l-16,80A12,12,0,0,1,180,232a11.87,11.87,0,0,1-2.36-.23,12,12,0,0,1-9.42-14.12L181.36,152H124a12,12,0,0,1-10.4-18l17.92-31.2a76.1,76.1,0,0,0-79.88,10.55A12,12,0,0,1,36.36,94.86a100,100,0,0,1,118.19-6.68,12,12,0,0,1,3.85,16L144.74,128H196A12,12,0,0,1,205.28,132.39Zm-41.47,44.52a64,64,0,1,1-73.64-68.16,12,12,0,0,1,3.66,23.72,40,40,0,1,0,46.05,42.62,12,12,0,1,1,23.93,1.82Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M200,48a24,24,0,1,1-24-24A24,24,0,0,1,200,48Z" opacity="0.2"/><path d="M176,80a32,32,0,1,0-32-32A32,32,0,0,0,176,80Zm0-48a16,16,0,1,1-16,16A16,16,0,0,1,176,32Zm-8,136a64,64,0,1,1-64-64,8,8,0,0,1,0,16,48,48,0,1,0,48,48,8,8,0,0,1,16,0Zm38.19-37.07a8,8,0,0,1,1.65,6.64l-16,80A8,8,0,0,1,184,224a7.77,7.77,0,0,1-1.58-.16,8,8,0,0,1-6.27-9.41L190.24,144H128a8,8,0,0,1-6.94-12l20.06-34.9a80.09,80.09,0,0,0-88,9.17A8,8,0,1,1,42.91,94a96,96,0,0,1,113.46-6.42,8,8,0,0,1,2.57,10.69L141.82,128H200A8,8,0,0,1,206.19,130.93Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M144,48a32,32,0,1,1,32,32A32,32,0,0,1,144,48Zm16,112a8,8,0,0,0-8,8,48,48,0,1,1-48-48,8,8,0,0,0,0-16,64,64,0,1,0,64,64A8,8,0,0,0,160,160Zm40-32H141.82l17.12-29.78a8,8,0,0,0-2.57-10.69A96,96,0,0,0,42.91,94a8,8,0,1,0,10.18,12.33,80.09,80.09,0,0,1,88-9.17L121.06,132A8,8,0,0,0,128,144h62.24l-14.08,70.43a8,8,0,0,0,6.27,9.41A7.77,7.77,0,0,0,184,224a8,8,0,0,0,7.83-6.43l16-80A8,8,0,0,0,200,128Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M176,78a30,30,0,1,0-30-30A30,30,0,0,0,176,78Zm0-48a18,18,0,1,1-18,18A18,18,0,0,1,176,30ZM166,168a62,62,0,1,1-62-62,6,6,0,0,1,0,12,50,50,0,1,0,50,50,6,6,0,0,1,12,0Zm38.64-35.8a6,6,0,0,1,1.24,5l-16,80A6,6,0,0,1,184,222a6.08,6.08,0,0,1-1.19-.12,6,6,0,0,1-4.7-7.06L192.68,142H128a6,6,0,0,1-5.2-9l21.07-36.68a82.05,82.05,0,0,0-92.05,8.41,6,6,0,1,1-7.64-9.25,94,94,0,0,1,111.1-6.28,6,6,0,0,1,1.92,8L138.37,130H200A6,6,0,0,1,204.64,132.2Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M176,80a32,32,0,1,0-32-32A32,32,0,0,0,176,80Zm0-48a16,16,0,1,1-16,16A16,16,0,0,1,176,32Zm-8,136a64,64,0,1,1-64-64,8,8,0,0,1,0,16,48,48,0,1,0,48,48,8,8,0,0,1,16,0Zm38.19-37.07a8,8,0,0,1,1.65,6.64l-16,80A8,8,0,0,1,184,224a7.77,7.77,0,0,1-1.58-.16,8,8,0,0,1-6.27-9.41L190.24,144H128a8,8,0,0,1-6.94-12l20.06-34.9a80.09,80.09,0,0,0-88,9.17A8,8,0,1,1,42.91,94a96,96,0,0,1,113.46-6.42,8,8,0,0,1,2.57,10.69L141.82,128H200A8,8,0,0,1,206.19,130.93Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M176,76a28,28,0,1,0-28-28A28,28,0,0,0,176,76Zm0-48a20,20,0,1,1-20,20A20,20,0,0,1,176,28ZM164,168a60,60,0,1,1-60-60,4,4,0,0,1,0,8,52,52,0,1,0,52,52,4,4,0,0,1,8,0Zm39.09-34.54a4,4,0,0,1,.83,3.32l-16,80A4,4,0,0,1,184,220a3.44,3.44,0,0,1-.78-.08,4,4,0,0,1-3.14-4.7l15-75.22H128a4,4,0,0,1-3.47-6l22.08-38.42a84.05,84.05,0,0,0-96.06,7.61A4,4,0,0,1,45.45,97a92,92,0,0,1,108.73-6.15,4,4,0,0,1,1.29,5.34L134.91,132H200A4,4,0,0,1,203.09,133.46Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
