/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M224,148H132.75L41.14,40.23A12,12,0,0,0,22.86,55.77l95.16,112,.06.07L156.46,213A20,20,0,0,0,171.7,220H224a20,20,0,0,0,20-20V168A20,20,0,0,0,224,148Zm-70.85,24H180v24h-6.45ZM220,196H204V172h16Zm-66.34-83.77,61.2-72a12,12,0,0,1,18.28,15.54l-61.2,72a12,12,0,1,1-18.28-15.54Zm-44.14,70.45a12,12,0,0,0-16.91,1.37L82.45,196H76V171.93A12,12,0,0,0,74.7,148H32a20,20,0,0,0-20,20v32a20,20,0,0,0,20,20H84.3A20,20,0,0,0,99.54,213l11.35-13.36A12,12,0,0,0,109.52,182.68ZM36,172H52v24H36Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M200,160v48H171.7a8,8,0,0,1-6.1-2.82l-38-44.7L90.4,205.12A8,8,0,0,1,84.25,208H56V160H200Z" opacity="0.2"/><path d="M224,152H130.9L38.1,42.82A8,8,0,0,0,25.9,53.18L159.51,210.36A16,16,0,0,0,171.7,216H224a16,16,0,0,0,16-16V168A16,16,0,0,0,224,152Zm-79.5,16H192v32H171.7ZM224,200H208V168h16ZM112.18,179.55a8,8,0,0,0-11.27.92L84.3,200H64V168H85.2a8,8,0,0,0,0-16H32a16,16,0,0,0-16,16v32a16,16,0,0,0,16,16H84.3a16,16,0,0,0,12.19-5.64l16.61-19.53A8,8,0,0,0,112.18,179.55ZM32,168H48v32H32Zm117.9-45.18,68-80a8,8,0,0,1,12.2,10.36l-68,80a8,8,0,1,1-12.2-10.36Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M149.9,122.82l68-80a8,8,0,0,1,12.2,10.36l-68,80a8,8,0,1,1-12.2-10.36ZM240,168v32a16,16,0,0,1-16,16H171.7a16,16,0,0,1-12.19-5.64L25.9,53.18h0A8,8,0,0,1,38.1,42.82L130.9,152H224A16,16,0,0,1,240,168Zm-16,0H208v32h16ZM115.3,183.06a4,4,0,0,1,0,5.18L96.49,210.36A16,16,0,0,1,84.3,216H32a16,16,0,0,1-16-16V168a16,16,0,0,1,16-16H87.05a4,4,0,0,1,3,1.41ZM48,168H32v32H48Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M224,154H130L36.57,44.12a6,6,0,1,0-9.14,7.77L161,209.07A14,14,0,0,0,171.7,214H224a14,14,0,0,0,14-14V168A14,14,0,0,0,224,154Zm-53.82,47.3-30-35.3H194v36H171.7A2,2,0,0,1,170.18,201.3ZM226,200a2,2,0,0,1-2,2H206V166h18a2,2,0,0,1,2,2ZM110.89,181.08a6,6,0,0,0-8.46.68L85.82,201.3a2,2,0,0,1-1.52.7H62V166H85.2a6,6,0,0,0,0-12H32a14,14,0,0,0-14,14v32a14,14,0,0,0,14,14H84.3A14,14,0,0,0,95,209.07l16.6-19.54A6,6,0,0,0,110.89,181.08ZM30,200V168a2,2,0,0,1,2-2H50v36H32A2,2,0,0,1,30,200Zm121.43-75.88,68-80a6,6,0,1,1,9.14,7.77l-68,80a6,6,0,0,1-9.14-7.77Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M224,152H130.9L38.1,42.82A8,8,0,0,0,25.9,53.18L159.51,210.36A16,16,0,0,0,171.7,216H224a16,16,0,0,0,16-16V168A16,16,0,0,0,224,152Zm-79.5,16H192v32H171.7ZM224,200H208V168h16ZM112.18,179.55a8,8,0,0,0-11.27.91L84.3,200H64V168H85.2a8,8,0,0,0,0-16H32a16,16,0,0,0-16,16v32a16,16,0,0,0,16,16H84.3a16,16,0,0,0,12.19-5.64l16.61-19.53A8,8,0,0,0,112.18,179.55ZM32,168H48v32H32Zm117.9-45.18,68-80a8,8,0,0,1,12.2,10.36l-68,80a8,8,0,1,1-12.2-10.36Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M224,156H129.05l-94-110.59A4,4,0,1,0,29,50.59L162.56,207.77A12,12,0,0,0,171.7,212H224a12,12,0,0,0,12-12V168A12,12,0,0,0,224,156Zm-55.35,46.59L135.85,164H196v40H171.7A4,4,0,0,1,168.65,202.59ZM228,200a4,4,0,0,1-4,4H204V164h20a4,4,0,0,1,4,4ZM104,183.06l-16.6,19.53a4,4,0,0,1-3,1.41H60V164H85.2a4,4,0,0,0,0-8H32a12,12,0,0,0-12,12v32a12,12,0,0,0,12,12H84.3a12,12,0,0,0,9.14-4.23l16.61-19.53a4,4,0,0,0-6.1-5.18ZM28,200V168a4,4,0,0,1,4-4H52v40H32A4,4,0,0,1,28,200Zm125.41-68.95a4,4,0,0,1-.46-5.64l68-80a4,4,0,1,1,6.1,5.18l-68,80a4,4,0,0,1-5.64.46Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
