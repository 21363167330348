/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M234.42,162A12,12,0,1,1,213.58,174l-16.86-29.5a127.19,127.19,0,0,1-30.17,13.86L171.84,190a12,12,0,0,1-9.87,13.8,11.22,11.22,0,0,1-2,.17,12,12,0,0,1-11.82-10L143,163.17a136.5,136.5,0,0,1-30.06,0L107.84,194A12,12,0,0,1,96,204a11.22,11.22,0,0,1-2-.17A12,12,0,0,1,84.16,190l5.29-31.72a127.19,127.19,0,0,1-30.17-13.86L42.42,174A12,12,0,1,1,21.58,162L40,129.85a159.73,159.73,0,0,1-17.31-18.31A12,12,0,0,1,41.34,96.46C57.38,116.32,85.44,140,128,140s70.62-23.68,86.66-43.54a12,12,0,0,1,18.67,15.08A159.73,159.73,0,0,1,216,129.85Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M224,104c-16.81,20.81-47.63,48-96,48s-79.19-27.19-96-48c16.81-20.81,47.63-48,96-48S207.19,83.19,224,104Z" opacity="0.2"/><path d="M228,175a8,8,0,0,1-10.92-3l-19-33.2A123.23,123.23,0,0,1,162,155.46l5.87,35.22a8,8,0,0,1-6.58,9.21A8.4,8.4,0,0,1,160,200a8,8,0,0,1-7.88-6.69l-5.77-34.58a133.06,133.06,0,0,1-36.68,0l-5.77,34.58A8,8,0,0,1,96,200a8.4,8.4,0,0,1-1.32-.11,8,8,0,0,1-6.58-9.21L94,155.46a123.23,123.23,0,0,1-36.06-16.69L39,172A8,8,0,1,1,25.06,164l20-35a153.47,153.47,0,0,1-19.3-20A8,8,0,1,1,38.22,99c16.6,20.54,45.64,45,89.78,45s73.18-24.49,89.78-45A8,8,0,1,1,230.22,109a153.47,153.47,0,0,1-19.3,20l20,35A8,8,0,0,1,228,175Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M230.94,164A8,8,0,1,1,217.05,172l-19-33.2A123.23,123.23,0,0,1,162,155.46l5.87,35.22a8,8,0,0,1-6.58,9.21A8.4,8.4,0,0,1,160,200a8,8,0,0,1-7.88-6.69l-5.77-34.58a133.06,133.06,0,0,1-36.68,0l-5.77,34.58A8,8,0,0,1,96,200a8.4,8.4,0,0,1-1.32-.11,8,8,0,0,1-6.58-9.21L94,155.46a123.23,123.23,0,0,1-36.06-16.69L39,172A8,8,0,1,1,25.06,164l20-35a152.8,152.8,0,0,1-19.3-20,8,8,0,0,1,0-10.06C44.56,75.72,77.55,48,128,48s83.44,27.72,102.22,51a8,8,0,0,1,0,10.06,152.8,152.8,0,0,1-19.3,20Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M229.21,165a6,6,0,0,1-10.42,6l-20-35.08a122,122,0,0,1-39,18.09l6.17,37a6,6,0,0,1-4.93,6.91,6.85,6.85,0,0,1-1,.08,6,6,0,0,1-5.91-5L148,156.44a128.86,128.86,0,0,1-40,0L101.92,193A6,6,0,0,1,96,198a6.85,6.85,0,0,1-1-.08A6,6,0,0,1,90.08,191l6.17-37a122,122,0,0,1-39-18.09L37.21,171a6,6,0,1,1-10.42-6l20.85-36.48a152,152,0,0,1-20.31-20.77,6,6,0,0,1,9.34-7.54C53.54,121.11,83.07,146,128,146s74.46-24.89,91.33-45.77a6,6,0,0,1,9.34,7.54,152,152,0,0,1-20.31,20.77Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M228,175a8,8,0,0,1-10.92-3l-19-33.2A123.23,123.23,0,0,1,162,155.46l5.87,35.22a8,8,0,0,1-6.58,9.21A8.4,8.4,0,0,1,160,200a8,8,0,0,1-7.88-6.69l-5.77-34.58a133.06,133.06,0,0,1-36.68,0l-5.77,34.58A8,8,0,0,1,96,200a8.4,8.4,0,0,1-1.32-.11,8,8,0,0,1-6.58-9.21L94,155.46a123.23,123.23,0,0,1-36.06-16.69L39,172A8,8,0,1,1,25.06,164l20-35a153.47,153.47,0,0,1-19.3-20A8,8,0,1,1,38.22,99c16.6,20.54,45.64,45,89.78,45s73.18-24.49,89.78-45A8,8,0,1,1,230.22,109a153.47,153.47,0,0,1-19.3,20l20,35A8,8,0,0,1,228,175Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M226,171.47a3.92,3.92,0,0,1-2,.53,4,4,0,0,1-3.47-2l-21.15-37a120,120,0,0,1-41.91,19.53L164,191.34a4,4,0,0,1-3.29,4.6,3.79,3.79,0,0,1-.67.06,4,4,0,0,1-3.94-3.34l-6.41-38.5a128.17,128.17,0,0,1-43.28,0L100,192.66A4,4,0,0,1,96,196a3.88,3.88,0,0,1-.67-.06,4,4,0,0,1-3.29-4.6l6.48-38.83A120,120,0,0,1,56.62,133L35.47,170A4,4,0,0,1,32,172a3.92,3.92,0,0,1-2-.53A4,4,0,0,1,28.53,166l21.68-37.94a148.24,148.24,0,0,1-21.32-21.56,4,4,0,1,1,6.22-5C52.25,122.71,82.29,148,128,148s75.75-25.29,92.89-46.51a4,4,0,1,1,6.22,5,148.24,148.24,0,0,1-21.32,21.56L227.47,166A4,4,0,0,1,226,171.47Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
