import { template } from "@ember/template-compiler";
import { cached } from '@glimmer/tracking';
import { service } from '@ember/service';
import { pageTitle } from 'ember-page-title';
import { Route } from 'ember-polaris-routing';
import CompatRoute from 'ember-polaris-routing/route/compat';
import { use } from 'ember-resources';
import Task from 'ember-tasks';
import { TinaMarkdown } from '../../../supporting/tina';
import { findSkill } from '../-resource';
import type FastbootService from 'ember-cli-fastboot/services/fastboot';
export class CourseDetailsRoute extends Route<{
    id: string;
}> {
    @service
    fastboot: FastbootService;
    @cached
    get load() {
        const promise1 = use(this, findSkill(this.params.id)).current;
        if (this.fastboot.isFastBoot) {
            this.fastboot.deferRendering(promise1);
        }
        return Task.promise(promise1);
    }
    static{
        template(`
    {{#let this.load as |r|}}
      {{#if r.resolved}}
        {{pageTitle r.value.title}}

        <section>
          <h1>{{r.value.title}}</h1>

          <TinaMarkdown @content={{r.value.body}} />
        </section>
      {{/if}}
    {{/let}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default CompatRoute(CourseDetailsRoute);
