/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M241,141.65,172.59,29.51l-.07-.11a19.85,19.85,0,0,0-17-9.4H100.44a19.85,19.85,0,0,0-17,9.4l-.07.11L15,141.65a20,20,0,0,0-.36,20.46L42,209.92A20,20,0,0,0,59.33,220H196.67A20,20,0,0,0,214,209.92l27.32-47.81A20,20,0,0,0,241,141.65ZM211.88,140H174.79L142,85.33l18.12-30.19ZM54.8,184,43.36,164H66.81Zm40-20h66.4l19.2,32H75.6Zm14.4-24L128,108.66,146.8,140Zm80,24h23.45L201.2,184ZM128,62,117.19,44h21.62ZM95.89,55.14,114,85.33,81.21,140H44.12Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M24,152H88L55.12,206.8A7.91,7.91,0,0,1,52.38,204L25.05,156.15A8,8,0,0,1,24,152Zm144,0,32.88,54.8a7.91,7.91,0,0,0,2.74-2.83l27.32-47.82A8,8,0,0,0,232,152ZM100.43,32a8.06,8.06,0,0,0-3.84,1L128,85.33,159.41,33a8.07,8.07,0,0,0-3.85-1Z" opacity="0.2"/><path d="M237.6,143.78,169.13,31.52A15.91,15.91,0,0,0,155.56,24H100.43a15.89,15.89,0,0,0-13.56,7.52l-.05.07L18.44,143.7a16,16,0,0,0-.33,16.42l27.32,47.82A16,16,0,0,0,59.32,216H196.67a16,16,0,0,0,13.89-8.06l27.32-47.82A15.91,15.91,0,0,0,237.6,143.78ZM219,144H172.52L137.33,85.33l22.75-37.92Zm-116.87,0L128,100.88,153.87,144Zm61.34,16,24,40H68.53l24-40ZM128,69.78,110.12,40l35.78-.05ZM95.91,47.41l22.76,37.92L83.47,144H37ZM36.54,160H73.87L54.72,191.92Zm164.74,31.93L182.12,160h37.41Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M237.6,143.78,169.13,31.52A15.91,15.91,0,0,0,155.56,24H100.43a15.89,15.89,0,0,0-13.56,7.52l-.05.07L18.44,143.7a16,16,0,0,0-.33,16.42l27.32,47.82A16,16,0,0,0,59.32,216H196.67a16,16,0,0,0,13.89-8.06l27.32-47.82A15.91,15.91,0,0,0,237.6,143.78ZM219,144H172.52L137.33,85.33l22.75-37.92ZM92.53,160h70.94l24,40H68.53Zm9.6-16L128,100.88,153.87,144ZM95.91,47.41l22.76,37.92L83.47,144H37Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M235.9,144.82,167.43,32.58A13.91,13.91,0,0,0,155.56,26H100.43a13.92,13.92,0,0,0-11.87,6.58l0,.05L20.13,144.76a14,14,0,0,0-.28,14.37L47.17,207A14,14,0,0,0,59.32,214H196.67A14,14,0,0,0,208.83,207l27.32-47.82A14,14,0,0,0,235.9,144.82ZM222.56,146H171.39L135,85.33l25.08-41.79Zm-124,0L128,97l29.4,49Zm66,12L191,202H65l26.4-44ZM128,73.67,106.6,38h42.8ZM95.93,43.54,121,85.33,84.6,146H33.43ZM33,158H77.4L54.67,195.89Zm168.3,37.89L178.59,158H223Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M237.6,143.78,169.13,31.52A15.91,15.91,0,0,0,155.56,24H100.43a15.89,15.89,0,0,0-13.56,7.52l-.05.07L18.44,143.7a16,16,0,0,0-.33,16.42l27.32,47.82A16,16,0,0,0,59.32,216H196.67a16,16,0,0,0,13.89-8.06l27.32-47.82A15.91,15.91,0,0,0,237.6,143.78ZM219,144H172.52L137.33,85.33l22.75-37.92Zm-116.87,0L128,100.88,153.87,144Zm61.34,16,24,40H68.53l24-40ZM128,69.78,110.12,40l35.78-.05ZM95.91,47.41l22.76,37.92L83.47,144H37ZM36.54,160H73.87L54.72,191.92Zm164.74,31.93L182.12,160h37.41Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M234.19,145.86,165.74,33.64A11.94,11.94,0,0,0,155.56,28H100.43a11.93,11.93,0,0,0-10.17,5.64l0,0L21.83,145.82a12,12,0,0,0-.25,12.32L48.91,206A12,12,0,0,0,59.32,212H196.67A12,12,0,0,0,207.09,206l27.32-47.81A12,12,0,0,0,234.19,145.86ZM226.13,148H170.26l-37.6-62.67,27.39-45.65ZM95.06,148,128,93.11,160.93,148Zm70.67,8,28.8,48H61.47l28.79-48ZM128,77.56,103.06,36h49.87ZM95.94,39.68l27.39,45.65L85.74,148H29.87ZM29.58,156H80.94L54.63,199.85Zm171.79,43.85L175.06,156h51.36Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
