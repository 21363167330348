/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M180,136a16,16,0,1,1-16-16A16,16,0,0,1,180,136ZM136,92a16,16,0,1,0-16,16A16,16,0,0,0,136,92Zm67.83-40.9A108,108,0,0,0,47.5,200a12,12,0,0,0,17.89-16,84,84,0,1,1,125.22,0,12,12,0,0,0,17.89,16,108,108,0,0,0-4.67-148.9ZM140,164h-4c-19.81,0-44-16.61-44-36a36.47,36.47,0,0,1,.5-6,12,12,0,0,0-23.67-4A60.67,60.67,0,0,0,68,128c0,16.1,8,31.46,22.49,43.25C103.58,181.9,120.17,188,136,188h4a8,8,0,0,1,0,16H100a32,32,0,0,0-32,32,12,12,0,0,0,24,0,8,8,0,0,1,8-8h40a32,32,0,0,0,0-64Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M224,128a95.63,95.63,0,0,1-24.44,64H56.44A95.67,95.67,0,0,1,32,126.06C33,74.58,75.15,32.73,126.63,32A96,96,0,0,1,224,128Z" opacity="0.2"/><path d="M176,140a12,12,0,1,1-12-12A12,12,0,0,1,176,140ZM128,92a12,12,0,1,0-12,12A12,12,0,0,0,128,92Zm73-38A103.24,103.24,0,0,0,128,24h-1.49a104,104,0,0,0-76,173.32A8,8,0,1,0,62.4,186.67a88,88,0,1,1,131.19,0,8,8,0,0,0,11.93,10.66A104,104,0,0,0,201,54ZM152,168H136c-21.74,0-48-17.84-48-40a41.33,41.33,0,0,1,.55-6.68,8,8,0,1,0-15.78-2.64A56.9,56.9,0,0,0,72,128c0,14.88,7.46,29.13,21,40.15C105.4,178.22,121.07,184,136,184h16a8,8,0,0,1,0,16H96a24,24,0,0,0,0,48,8,8,0,0,0,0-16,8,8,0,0,1,0-16h56a24,24,0,0,0,0-48Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M235.6,176H220.24a104,104,0,1,0-184.52,0H20.4A12.26,12.26,0,0,0,8,187.78,12,12,0,0,0,20,200H80a8,8,0,0,1,0,16H72.16a8.2,8.2,0,0,0-8,6.33A8,8,0,0,0,72,232H199.73a8.18,8.18,0,0,0,8.25-7.47,8,8,0,0,0-8-8.53H144a8,8,0,0,1,0-16h7.79a8.28,8.28,0,0,0,8.15-7.05A8,8,0,0,0,152,184H136c-14.93,0-30.59-5.78-43-15.85-13.55-11-21-25.27-21-40.15a57,57,0,0,1,.71-9,8.21,8.21,0,0,1,8.85-7,8,8,0,0,1,7,9.27A41.33,41.33,0,0,0,88,128c0,22.16,26.26,40,48,40h15.44c13.5,0,24.86,11.05,24.55,24.55a24,24,0,0,1-.23,2.83,4,4,0,0,0,4,4.62H236a12,12,0,0,0,12-12.22A12.26,12.26,0,0,0,235.6,176ZM127.9,93.56A12,12,0,1,1,114.44,80.1,12,12,0,0,1,127.9,93.56Zm48,48a12,12,0,1,1-13.46-13.46A12,12,0,0,1,175.9,141.56Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M174,140a10,10,0,1,1-10-10A10,10,0,0,1,174,140ZM126,92a10,10,0,1,0-10,10A10,10,0,0,0,126,92Zm73.62-36.63A102,102,0,0,0,52,196a6,6,0,1,0,8.94-8A90.09,90.09,0,0,1,126.72,38H128a90,90,0,0,1,67.07,150,6,6,0,0,0,8.95,8,102,102,0,0,0-4.41-140.63ZM152,170H136c-22.65,0-50-18.73-50-42a43.15,43.15,0,0,1,.58-7,6,6,0,1,0-11.83-2,54,54,0,0,0-.75,9c0,14.26,7.2,28,20.27,38.6,12,9.79,27.26,15.4,41.73,15.4h16a10,10,0,0,1,0,20H96a22,22,0,0,0,0,44,6,6,0,0,0,0-12,10,10,0,0,1,0-20h56a22,22,0,0,0,0-44Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M176,140a12,12,0,1,1-12-12A12,12,0,0,1,176,140ZM128,92a12,12,0,1,0-12,12A12,12,0,0,0,128,92Zm73-38A104,104,0,0,0,50.48,197.33,8,8,0,1,0,62.4,186.66a88,88,0,1,1,131.19,0,8,8,0,0,0,11.93,10.67A104,104,0,0,0,201,54ZM152,168H136c-21.74,0-48-17.84-48-40a41.33,41.33,0,0,1,.55-6.68,8,8,0,1,0-15.78-2.64A56.9,56.9,0,0,0,72,128c0,14.88,7.46,29.13,21,40.15C105.4,178.22,121.07,184,136,184h16a8,8,0,0,1,0,16H96a24,24,0,0,0,0,48,8,8,0,0,0,0-16,8,8,0,0,1,0-16h56a24,24,0,0,0,0-48Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M172,140a8,8,0,1,1-8-8A8,8,0,0,1,172,140ZM124,92a8,8,0,1,0-8,8A8,8,0,0,0,124,92Zm74.21-35.2A100,100,0,0,0,53.46,194.67a4,4,0,1,0,6-5.34,92,92,0,1,1,137.16,0,4,4,0,0,0,6,5.34A100,100,0,0,0,198.21,56.8ZM152,172H136c-12,0-25.28-4.92-35.42-13.16C89.89,150.15,84,139.19,84,128a43.89,43.89,0,0,1,.61-7.34,4,4,0,0,0-7.89-1.32A52.17,52.17,0,0,0,76,128c0,29.29,32.25,52,60,52h16a12,12,0,0,1,0,24H96a20,20,0,0,0,0,40,4,4,0,0,0,0-8,12,12,0,0,1,0-24h56a20,20,0,0,0,0-40Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
