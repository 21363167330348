import { template } from "@ember/template-compiler";
import { Icon } from '@hokulea/ember';
import styles from './spotify-player-warning.css';
export const SpotifyPlayerWarning = template(`
  <p>
    <Icon @icon="warning" @style="fill" class={{styles.warning}} />
    Bitte Spotify Player auswählen
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
