/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M92.49,187.5a12,12,0,1,1-17,17l-24-24a12,12,0,0,1,17-17ZM112,120a24,24,0,1,0,24,24A24,24,0,0,0,112,120ZM252.49,60.48a12,12,0,0,1-14.12,2.12L182,119a73.13,73.13,0,0,1,5.36,18.68c2.51,17.58-2.2,33.35-13.27,44.42a46.11,46.11,0,0,1-15.73,10.29,4.32,4.32,0,0,0-2.39,4c-.53,15.61-6.16,29.22-16.28,39.34C129,246.37,114.5,252,98.13,252a76.73,76.73,0,0,1-10.77-.78c-19.47-2.78-38.73-12.85-54.23-28.35S7.56,188.11,4.78,168.64c-3-20.75,2.56-39.34,15.55-52.33C30.45,106.19,44.06,100.56,59.67,100a4.32,4.32,0,0,0,4-2.39A46.11,46.11,0,0,1,73.93,81.91C85,70.84,100.77,66.13,118.35,68.64A73.13,73.13,0,0,1,137,74L193.4,17.63A12,12,0,0,1,212.49,3.51l40,40A12,12,0,0,1,252.49,60.48ZM221,46,210,35,191,54l11,11Zm-74.31,63.28C135.53,98.09,121.39,92,109.34,92c-7.29,0-13.81,2.21-18.44,6.85A22.16,22.16,0,0,0,86,106.47,28.23,28.23,0,0,1,60.48,124h0c-6.57.22-15.93,2-23.18,9.26-7.52,7.52-10.63,18.87-8.76,32,2.05,14.37,9.71,28.81,21.56,40.66s26.29,19.51,40.66,21.56c13.09,1.87,24.44-1.24,32-8.76,7.25-7.25,9-16.61,9.26-23.18A28.23,28.23,0,0,1,149.53,170a22.16,22.16,0,0,0,7.59-4.94C169.43,152.79,164.67,127.23,146.72,109.28ZM169,98.07,185,82,174,71,157.93,87c2,1.67,3.9,3.42,5.76,5.27S167.29,96.09,169,98.07Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M155.2,100.8c-23-23-55.57-27.63-72.8-10.4a34.21,34.21,0,0,0-7.61,11.66,16.23,16.23,0,0,1-14.72,10C48,112.44,37,116.61,28.8,124.8,7.6,146,13.33,186.12,41.6,214.4s68.39,34,89.6,12.8C139.39,219,143.56,208,144,195.93a16.23,16.23,0,0,1,10-14.72,34.21,34.21,0,0,0,11.66-7.61C182.83,156.37,178.17,123.78,155.2,100.8ZM112,168a24,24,0,1,1,24-24A24,24,0,0,1,112,168Z" opacity="0.2"/><path d="M249.66,46.34l-40-40a8,8,0,0,0-11.31,11.32L200.69,20,140.52,80.16C117.73,68.3,92.21,69.29,76.75,84.74a42.27,42.27,0,0,0-9.39,14.37A8.24,8.24,0,0,1,59.81,104c-14.59.49-27.26,5.72-36.65,15.11C11.08,131.22,6,148.6,8.74,168.07,11.4,186.7,21.07,205.15,36,220s33.34,24.56,52,27.22A71.13,71.13,0,0,0,98.1,248c15.32,0,28.83-5.23,38.76-15.16,9.39-9.39,14.62-22.06,15.11-36.65a8.24,8.24,0,0,1,4.92-7.55,42.12,42.12,0,0,0,14.37-9.39c15.45-15.46,16.44-41,4.58-63.77L236,55.31l2.34,2.34a8,8,0,1,0,11.32-11.31ZM160,167.93a26.12,26.12,0,0,1-8.95,5.83,24.24,24.24,0,0,0-15,21.89c-.36,10.46-4,19.41-10.43,25.88-8.44,8.43-21,11.95-35.36,9.89C75,229.25,59.73,221.19,47.27,208.73S26.75,181,24.58,165.81c-2-14.37,1.46-26.92,9.89-35.36C40.94,124,49.89,120.37,60.35,120h0a24.22,24.22,0,0,0,21.89-15,26.12,26.12,0,0,1,5.83-9c5.49-5.49,13-8.13,21.38-8.13a49.38,49.38,0,0,1,19.13,4.19L108.5,112.19a32,32,0,1,0,35.31,35.31l20.08-20.08C170.41,142.71,169.47,158.41,160,167.93Zm-10.4-61.48a72.9,72.9,0,0,1,5.93,6.75l-15.42,15.42a32.22,32.22,0,0,0-12.68-12.68l15.42-15.43A73,73,0,0,1,149.55,106.45ZM112,128a16,16,0,0,1,16,16h0a16,16,0,1,1-16-16Zm48.85-32.85a86.94,86.94,0,0,0-6.68-6L176,67.31,188.69,80l-21.83,21.82A86.94,86.94,0,0,0,160.86,95.14ZM200,68.68,187.32,56,212,31.31,224.69,44ZM93.66,194.33a8,8,0,0,1-11.31,11.32l-32-32a8,8,0,0,1,11.32-11.31Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M249.66,46.34l-40-40a8,8,0,0,0-11.32,11.32L200.69,20,140.52,80.16C117.73,68.3,92.21,69.29,76.75,84.74a42.27,42.27,0,0,0-9.39,14.37A8.24,8.24,0,0,1,59.81,104c-14.59.49-27.26,5.72-36.65,15.11C11.08,131.22,6,148.6,8.74,168.07,11.4,186.7,21.07,205.15,36,220s33.34,24.56,52,27.22A71.13,71.13,0,0,0,98.1,248c15.32,0,28.83-5.23,38.76-15.16,9.39-9.39,14.62-22.06,15.11-36.65a8.24,8.24,0,0,1,4.92-7.55,42.22,42.22,0,0,0,14.37-9.39c15.45-15.46,16.44-41,4.58-63.77L236,55.31l2.34,2.35a8,8,0,0,0,11.32-11.32Zm-156,159.31a8,8,0,0,1-11.31,0l-32-32a8,8,0,0,1,11.32-11.31l32,32A8,8,0,0,1,93.66,205.65Zm42.14-45.86a28,28,0,1,1,0-39.59A28,28,0,0,1,135.8,159.79Zm31.06-58a86.94,86.94,0,0,0-6-6.68,85.23,85.23,0,0,0-6.69-6L176,67.31,188.69,80ZM200,68.68,187.32,56,212,31.31,224.69,44Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M248.24,47.76l-40-40a6,6,0,0,0-8.48,8.48L203.52,20,140.9,82.62C118.54,70.42,93.29,71,78.17,86.16a39.94,39.94,0,0,0-9,13.69A10.24,10.24,0,0,1,59.87,106c-14.06.48-26.27,5.5-35.3,14.53C13,132.18,8,149,10.72,167.79,13.32,186,22.78,204,37.37,218.63S70,242.68,88.21,245.28a70,70,0,0,0,9.88.72c14.79,0,27.81-5,37.35-14.57,9-9,14.05-21.23,14.53-35.3a10.24,10.24,0,0,1,6.18-9.35,39.94,39.94,0,0,0,13.69-8.95c15.12-15.12,15.74-40.37,3.54-62.73L236,52.48l3.76,3.76a6,6,0,0,0,8.48-8.48ZM161.36,169.35a28.06,28.06,0,0,1-9.63,6.27A22.22,22.22,0,0,0,138,195.72c-.37,11-4.18,20.39-11,27.22-8.89,8.89-22,12.61-37,10.46-15.65-2.23-31.29-10.49-44.06-23.25s-21-28.41-23.25-44.06c-2.15-15,1.57-28.16,10.46-37.05,6.83-6.84,16.25-10.65,27.22-11h0a22.21,22.21,0,0,0,20.1-13.76,28.12,28.12,0,0,1,6.27-9.62C92.52,88.77,100.57,86,109.44,86A52.74,52.74,0,0,1,132,91.49L109.4,114.12a30,30,0,1,0,32.48,32.48L164.52,124C172.63,140.82,172,158.68,161.36,169.35ZM151,105a73,73,0,0,1,7.17,8.34L139.5,132A30.18,30.18,0,0,0,124,116.5l18.62-18.62A72.16,72.16,0,0,1,151,105ZM112,126a18,18,0,0,1,18,18h0a18,18,0,1,1-18-18Zm47.44-29.44a83.19,83.19,0,0,0-8.28-7.23L176,64.48,191.52,80l-24.84,24.83A84.5,84.5,0,0,0,159.45,96.55Zm40.55-25L184.49,56,212,28.48,227.52,44ZM92.25,195.75a6,6,0,1,1-8.48,8.48l-32-32a6,6,0,0,1,8.48-8.48Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M249.66,46.34l-40-40a8,8,0,0,0-11.31,11.32L200.69,20,140.52,80.16C117.73,68.3,92.21,69.29,76.75,84.74a42.27,42.27,0,0,0-9.39,14.37A8.24,8.24,0,0,1,59.81,104c-14.59.49-27.26,5.72-36.65,15.11C11.08,131.22,6,148.6,8.74,168.07,11.4,186.7,21.07,205.15,36,220s33.34,24.56,52,27.22A71.13,71.13,0,0,0,98.1,248c15.32,0,28.83-5.23,38.76-15.16,9.39-9.39,14.62-22.06,15.11-36.65a8.24,8.24,0,0,1,4.92-7.55,42.12,42.12,0,0,0,14.37-9.39c15.45-15.46,16.44-41,4.58-63.77L236,55.31l2.34,2.34a8,8,0,1,0,11.32-11.31ZM160,167.93a26.12,26.12,0,0,1-8.95,5.83,24.24,24.24,0,0,0-15,21.89c-.36,10.46-4,19.41-10.43,25.88-8.44,8.43-21,11.95-35.36,9.89C75,229.25,59.73,221.19,47.27,208.73S26.75,181,24.58,165.81c-2-14.37,1.46-26.92,9.89-35.36C40.94,124,49.89,120.37,60.35,120h0a24.22,24.22,0,0,0,21.89-15,26.12,26.12,0,0,1,5.83-9c5.49-5.49,13-8.13,21.38-8.13a49.38,49.38,0,0,1,19.13,4.19L108.5,112.19a32,32,0,1,0,35.31,35.31l20.08-20.08C170.41,142.71,169.47,158.41,160,167.93Zm-10.4-61.48a72.9,72.9,0,0,1,5.93,6.75l-15.42,15.42a32.22,32.22,0,0,0-12.68-12.68l15.42-15.43A73,73,0,0,1,149.55,106.45ZM112,128a16,16,0,0,1,16,16h0a16,16,0,1,1-16-16Zm48.85-32.85a86.94,86.94,0,0,0-6.68-6L176,67.31,188.69,80l-21.83,21.82A86.94,86.94,0,0,0,160.86,95.14ZM200,68.68,187.32,56,212,31.31,224.69,44ZM93.66,194.33a8,8,0,0,1-11.31,11.32l-32-32a8,8,0,0,1,11.32-11.31Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M246.83,49.17l-40-40a4,4,0,0,0-5.66,5.66L206.34,20,141.25,85.1c-21.91-12.54-46.89-12.31-61.67,2.47a38.11,38.11,0,0,0-8.5,13A12.24,12.24,0,0,1,59.94,108c-13.55.46-25.29,5.28-34,13.94C14.82,133.14,10.1,149.31,12.7,167.51c2.54,17.77,11.8,35.42,26.08,49.71s31.94,23.54,49.71,26.08a67.39,67.39,0,0,0,9.59.7c14.25,0,26.79-4.82,36-14,8.66-8.67,13.48-20.41,13.94-34a12.24,12.24,0,0,1,7.45-11.14,38.11,38.11,0,0,0,13-8.5c14.78-14.78,15-39.76,2.47-61.67L236,49.66l5.17,5.17a4,4,0,1,0,5.66-5.66ZM162.77,170.76a29.94,29.94,0,0,1-10.3,6.72A20.25,20.25,0,0,0,140,195.79c-.38,11.49-4.39,21.37-11.6,28.57-9.34,9.34-23.1,13.26-38.75,11-16.07-2.29-32.11-10.75-45.18-23.82s-21.53-29.11-23.82-45.18c-2.24-15.65,1.68-29.41,11-38.75,7.2-7.21,17.08-11.22,28.57-11.6h0a20.25,20.25,0,0,0,18.31-12.5,29.94,29.94,0,0,1,6.72-10.3c11.8-11.8,31.84-12,50.14-2.27l-25.09,25.09A28,28,0,1,0,140,145.71L165,120.62C174.81,138.92,174.57,159,162.77,170.76ZM112,124a20,20,0,0,1,20,20h0a20,20,0,1,1-20-20Zm26.71,11.63a28.06,28.06,0,0,0-18.34-18.34l22-22a74.51,74.51,0,0,1,18.35,18.35ZM158,98a81.07,81.07,0,0,0-9.9-8.44L176,61.65,194.34,80l-27.87,27.87A81.07,81.07,0,0,0,158,98Zm42-23.63L181.66,56,212,25.66,230.34,44ZM90.84,197.16a4,4,0,0,1-5.66,5.66l-32-32a4,4,0,0,1,5.66-5.66Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
