/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M160,28h-8a20,20,0,0,0-20,20v52H58.16A40.07,40.07,0,0,0,20,72a12,12,0,0,0,0,24,16,16,0,0,1,16,16,84.09,84.09,0,0,0,84,84h40a84,84,0,0,0,0-168Zm48.06,48.12A59.58,59.58,0,0,1,218.79,100H178.21ZM160,52a59.66,59.66,0,0,1,29.83,8L156,87V52Zm0,120H120a60.1,60.1,0,0,1-58.79-48H218.79A60.1,60.1,0,0,1,160,172Zm-52,52a20,20,0,1,1-20-20A20,20,0,0,1,108,224Zm104,0a20,20,0,1,1-20-20A20,20,0,0,1,212,224Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M232,112H144V48a8,8,0,0,1,8-8h8A72,72,0,0,1,232,112Z" opacity="0.2"/><path d="M160,32h-8a16,16,0,0,0-16,16v56H55.2A40.07,40.07,0,0,0,16,72a8,8,0,0,0,0,16,24,24,0,0,1,24,24,80.09,80.09,0,0,0,80,80h40a80,80,0,0,0,0-160Zm63.48,72H166.81l41.86-33.49A63.73,63.73,0,0,1,223.48,104ZM160,48a63.59,63.59,0,0,1,36.69,11.61L152,95.35V48Zm0,128H120a64.09,64.09,0,0,1-63.5-56h167A64.09,64.09,0,0,1,160,176Zm-56,48a16,16,0,1,1-16-16A16,16,0,0,1,104,224Zm104,0a16,16,0,1,1-16-16A16,16,0,0,1,208,224Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M215.58,54.55a7.53,7.53,0,0,0-1.32-1.27A79.68,79.68,0,0,0,160,32h-8a16,16,0,0,0-16,16v56H55.2A40.07,40.07,0,0,0,16,72a8,8,0,0,0,0,16,24,24,0,0,1,24,24,80.09,80.09,0,0,0,80,80h40A79.94,79.94,0,0,0,215.58,54.55Zm-6.91,16A63.73,63.73,0,0,1,223.48,104H166.81ZM160,48a63.59,63.59,0,0,1,36.69,11.61L152,95.35V48ZM104,224a16,16,0,1,1-16-16A16,16,0,0,1,104,224Zm104,0a16,16,0,1,1-16-16A16,16,0,0,1,208,224Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M160,34h-8a14,14,0,0,0-14,14v58H53.52A38.05,38.05,0,0,0,16,74a6,6,0,0,0,0,12,26,26,0,0,1,26,26,78.09,78.09,0,0,0,78,78h40a78,78,0,0,0,0-156Zm65.71,72H161.1l47.82-38.25A65.66,65.66,0,0,1,225.71,106ZM152,46h8a65.67,65.67,0,0,1,40,13.53l-50,40V48A2,2,0,0,1,152,46Zm8,132H120a66.09,66.09,0,0,1-65.73-60H225.73A66.09,66.09,0,0,1,160,178Zm-58,46a14,14,0,1,1-14-14A14,14,0,0,1,102,224Zm104,0a14,14,0,1,1-14-14A14,14,0,0,1,206,224Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M160,32h-8a16,16,0,0,0-16,16v56H55.2A40.07,40.07,0,0,0,16,72a8,8,0,0,0,0,16,24,24,0,0,1,24,24,80.09,80.09,0,0,0,80,80h40a80,80,0,0,0,0-160Zm63.48,72H166.81l41.86-33.49A63.73,63.73,0,0,1,223.48,104ZM160,48a63.59,63.59,0,0,1,36.69,11.61L152,95.35V48Zm0,128H120a64.09,64.09,0,0,1-63.5-56h167A64.09,64.09,0,0,1,160,176Zm-56,48a16,16,0,1,1-16-16A16,16,0,0,1,104,224Zm104,0a16,16,0,1,1-16-16A16,16,0,0,1,208,224Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M160,36h-8a12,12,0,0,0-12,12v60H51.77A36,36,0,0,0,16,76a4,4,0,0,0,0,8,28,28,0,0,1,28,28,76.08,76.08,0,0,0,76,76h40a76,76,0,0,0,0-152Zm67.87,72H155.4l53.71-43A67.76,67.76,0,0,1,227.87,108ZM152,44h8a67.72,67.72,0,0,1,43.2,15.52L148,103.68V48A4,4,0,0,1,152,44Zm8,136H120a68.08,68.08,0,0,1-67.88-64H227.88A68.08,68.08,0,0,1,160,180Zm-60,44a12,12,0,1,1-12-12A12,12,0,0,1,100,224Zm104,0a12,12,0,1,1-12-12A12,12,0,0,1,204,224Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
