/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M94,88H76a12,12,0,0,0-12,12v52a12,12,0,0,0,24,0v-4h6a30,30,0,0,0,0-60Zm0,36H88V112h6a6,6,0,0,1,0,12ZM136,20A108.16,108.16,0,0,0,59.27,52H40A20,20,0,0,0,20,72V180a20,20,0,0,0,20,20H55.5A108,108,0,1,0,136,20Zm0,24a84.12,84.12,0,0,1,83.13,72H164V72a20,20,0,0,0-20-20H100.27A83.88,83.88,0,0,1,136,44ZM44,76h96V176H44Zm92,136a83.67,83.67,0,0,1-43.32-12H144a20,20,0,0,0,20-20V140h55.13A84.12,84.12,0,0,1,136,212Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M152,80v96a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V80a8,8,0,0,1,8-8H144A8,8,0,0,1,152,80Z" opacity="0.2"/><path d="M96,96H80a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0v-8h8a24,24,0,0,0,0-48Zm0,32H88V112h8a8,8,0,0,1,0,16ZM136,24A104.33,104.33,0,0,0,54,64H40A16,16,0,0,0,24,80v96a16,16,0,0,0,16,16H54A104,104,0,1,0,136,24Zm87.63,96H160V80a16,16,0,0,0-16-16V40.37A88.13,88.13,0,0,1,223.63,120ZM128,40.37V64H75.63A88.36,88.36,0,0,1,128,40.37ZM40,80H144v47.9a.51.51,0,0,0,0,.2V176H40Zm88,112v23.63A88.36,88.36,0,0,1,75.63,192Zm16,23.63V192a16,16,0,0,0,16-16V136h63.63A88.13,88.13,0,0,1,144,215.63Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M136,24A104.33,104.33,0,0,0,54,64H40A16,16,0,0,0,24,80v96a16,16,0,0,0,16,16H54A104,104,0,1,0,136,24ZM72,152V104a8,8,0,0,1,8-8H96a24,24,0,0,1,0,48H88v8a8,8,0,0,1-16,0Zm56,63.63A88.36,88.36,0,0,1,75.63,192H128ZM128,64H75.63A88.36,88.36,0,0,1,128,40.37Zm16-23.63A88.13,88.13,0,0,1,223.63,120H160V80a16,16,0,0,0-16-16Zm0,175.26V192a16,16,0,0,0,16-16V136h63.63A88.13,88.13,0,0,1,144,215.63ZM96,128H88V112h8a8,8,0,0,1,0,16Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M96,98H80a6,6,0,0,0-6,6v48a6,6,0,0,0,12,0V142H96a22,22,0,0,0,0-44Zm0,32H86V110H96a10,10,0,0,1,0,20ZM136,26A102.35,102.35,0,0,0,55,66H40A14,14,0,0,0,26,80v96a14,14,0,0,0,14,14H55A102,102,0,1,0,136,26Zm89.8,96H158V80a14,14,0,0,0-14-14h-2V38.2A90.15,90.15,0,0,1,225.8,122ZM130,38.21V66H70.78A90.39,90.39,0,0,1,130,38.21ZM38,176V80a2,2,0,0,1,2-2H144a2,2,0,0,1,2,2v96a2,2,0,0,1-2,2H40A2,2,0,0,1,38,176Zm32.78,14H130v27.79A90.39,90.39,0,0,1,70.78,190ZM142,217.8V190h2a14,14,0,0,0,14-14V134h67.8A90.14,90.14,0,0,1,142,217.8Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M96,96H80a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0v-8h8a24,24,0,0,0,0-48Zm0,32H88V112h8a8,8,0,0,1,0,16ZM136,24A104.33,104.33,0,0,0,54,64H40A16,16,0,0,0,24,80v96a16,16,0,0,0,16,16H54A104,104,0,1,0,136,24Zm87.63,96H160V80a16,16,0,0,0-16-16V40.37A88.13,88.13,0,0,1,223.63,120ZM128,40.37V64H75.63A88.36,88.36,0,0,1,128,40.37ZM40,80H144v47.9a.51.51,0,0,0,0,.2V176H40Zm88,112v23.63A88.36,88.36,0,0,1,75.63,192Zm16,23.63V192a16,16,0,0,0,16-16V136h63.63A88.13,88.13,0,0,1,144,215.63Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M96,100H80a4,4,0,0,0-4,4v48a4,4,0,0,0,8,0V140H96a20,20,0,0,0,0-40Zm0,32H84V108H96a12,12,0,0,1,0,24ZM136,28A100.3,100.3,0,0,0,56,68H40A12,12,0,0,0,28,80v96a12,12,0,0,0,12,12H56A100,100,0,1,0,136,28Zm91.91,96H156V80a12,12,0,0,0-12-12h-4V36.09A92.13,92.13,0,0,1,227.91,124ZM132,36.1V68H66.26A92.36,92.36,0,0,1,132,36.1ZM36,176V80a4,4,0,0,1,4-4H144a4,4,0,0,1,4,4v96a4,4,0,0,1-4,4H40A4,4,0,0,1,36,176Zm30.26,12H132v31.9A92.36,92.36,0,0,1,66.26,188ZM140,219.91V188h4a12,12,0,0,0,12-12V132h71.91A92.13,92.13,0,0,1,140,219.91Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
