/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M236,104a36,36,0,0,0-48-33.94V56a36,36,0,0,0-60-26.8A36,36,0,0,0,70.06,68H56a36,36,0,0,0-26.8,60A36,36,0,0,0,68,185.94V200a36,36,0,0,0,60,26.8A36,36,0,0,0,185.94,188H200a36,36,0,0,0,26.8-60A35.82,35.82,0,0,0,236,104ZM200,92a12,12,0,0,1,0,24H188V104A12,12,0,0,1,200,92ZM152,44a12,12,0,0,1,12,12v48a12,12,0,0,1-12,12H140V56A12,12,0,0,1,152,44ZM92,56a12,12,0,0,1,24,0V68H104A12,12,0,0,1,92,56ZM56,92h48a12,12,0,0,1,12,12v12H56a12,12,0,0,1,0-24Zm0,72a12,12,0,0,1,0-24H68v12A12,12,0,0,1,56,164Zm48,48a12,12,0,0,1-12-12V152a12,12,0,0,1,12-12h12v60A12,12,0,0,1,104,212Zm60-12a12,12,0,0,1-24,0V188h12A12,12,0,0,1,164,200Zm36-36H152a12,12,0,0,1-12-12V140h60a12,12,0,0,1,0,24Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M224,152h0a24,24,0,0,1-24,24H152a24,24,0,0,1,24,24h0a24,24,0,0,1-24,24h0a24,24,0,0,1-24-24V176h24a24,24,0,0,1-24-24V128h72A24,24,0,0,1,224,152ZM104,80h24V56a24,24,0,0,0-24-24h0A24,24,0,0,0,80,56h0a24,24,0,0,0,24,24H56a24,24,0,0,0-24,24h0a24,24,0,0,0,24,24h72V104A24,24,0,0,0,104,80Z" opacity="0.2"/><path d="M221.13,128A32,32,0,0,0,184,76.31V56a32,32,0,0,0-56-21.13A32,32,0,0,0,76.31,72H56a32,32,0,0,0-21.13,56A32,32,0,0,0,72,179.69V200a32,32,0,0,0,56,21.13A32,32,0,0,0,179.69,184H200a32,32,0,0,0,21.13-56ZM200,88a16,16,0,0,1,0,32H184V104A16,16,0,0,1,200,88ZM152,40a16,16,0,0,1,16,16v48a16,16,0,0,1-16,16H136V56A16,16,0,0,1,152,40ZM88,56a16,16,0,0,1,32,0V72H104A16,16,0,0,1,88,56ZM40,104A16,16,0,0,1,56,88h48a16,16,0,0,1,16,16v16H56A16,16,0,0,1,40,104Zm16,64a16,16,0,0,1,0-32H72v16A16,16,0,0,1,56,168Zm48,48a16,16,0,0,1-16-16V152a16,16,0,0,1,16-16h16v64A16,16,0,0,1,104,216Zm64-16a16,16,0,0,1-32,0V184h16A16,16,0,0,1,168,200Zm32-32H152a16,16,0,0,1-16-16V136h64a16,16,0,0,1,0,32Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M221.13,128A32,32,0,0,0,184,76.31V56a32,32,0,0,0-56-21.13A32,32,0,0,0,76.31,72H56a32,32,0,0,0-21.13,56A32,32,0,0,0,72,179.69V200a32,32,0,0,0,56,21.13A32,32,0,0,0,179.69,184H200a32,32,0,0,0,21.13-56ZM88,56a16,16,0,0,1,32,0V72H104A16,16,0,0,1,88,56ZM40,104A16,16,0,0,1,56,88h48a16,16,0,0,1,16,16v16H56A16,16,0,0,1,40,104Zm128,96a16,16,0,0,1-32,0V184h16A16,16,0,0,1,168,200Zm32-32H152a16,16,0,0,1-16-16V136h64a16,16,0,0,1,0,32Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M218,128a30,30,0,1,0-36-48V56a30,30,0,0,0-54-18A30,30,0,1,0,80,74H56a30,30,0,0,0-18,54,30,30,0,1,0,36,48v24a30,30,0,0,0,54,18,30,30,0,1,0,48-36h24a30,30,0,0,0,18-54ZM200,86a18,18,0,0,1,0,36H182V104A18,18,0,0,1,200,86ZM152,38a18,18,0,0,1,18,18v48a18,18,0,0,1-18,18H134V56A18,18,0,0,1,152,38ZM86,56a18,18,0,0,1,36,0V74H104A18,18,0,0,1,86,56ZM38,104A18,18,0,0,1,56,86h48a18,18,0,0,1,18,18v18H56A18,18,0,0,1,38,104Zm18,66a18,18,0,0,1,0-36H74v18A18,18,0,0,1,56,170Zm48,48a18,18,0,0,1-18-18V152a18,18,0,0,1,18-18h18v66A18,18,0,0,1,104,218Zm66-18a18,18,0,0,1-36,0V182h18A18,18,0,0,1,170,200Zm30-30H152a18,18,0,0,1-18-18V134h66a18,18,0,0,1,0,36Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M221.13,128A32,32,0,0,0,184,76.31V56a32,32,0,0,0-56-21.13A32,32,0,0,0,76.31,72H56a32,32,0,0,0-21.13,56A32,32,0,0,0,72,179.69V200a32,32,0,0,0,56,21.13A32,32,0,0,0,179.69,184H200a32,32,0,0,0,21.13-56ZM72,152a16,16,0,1,1-16-16H72Zm48,48a16,16,0,0,1-32,0V152a16,16,0,0,1,16-16h16Zm0-80H56a16,16,0,0,1,0-32h48a16,16,0,0,1,16,16Zm0-48H104a16,16,0,1,1,16-16Zm16-16a16,16,0,0,1,32,0v48a16,16,0,0,1-16,16H136Zm16,160a16,16,0,0,1-16-16V184h16a16,16,0,0,1,0,32Zm48-48H152a16,16,0,0,1-16-16V136h64a16,16,0,0,1,0,32Zm0-48H184V104a16,16,0,1,1,16,16Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M214.4,128A28,28,0,1,0,180,84.43V56a28,28,0,0,0-52-14.4A28,28,0,1,0,84.43,76H56a28,28,0,0,0-14.4,52A28,28,0,1,0,76,171.57V200a28,28,0,0,0,52,14.41A28,28,0,1,0,171.57,180H200a28,28,0,0,0,14.4-52ZM76,152a20,20,0,1,1-20-20H76Zm48,48a20,20,0,0,1-40,0V152a20,20,0,0,1,20-20h20Zm0-76H56a20,20,0,0,1,0-40h48a20,20,0,0,1,20,20Zm0-48H104a20,20,0,1,1,20-20Zm56,28a20,20,0,1,1,20,20H180ZM132,56a20,20,0,0,1,40,0v48a20,20,0,0,1-20,20H132Zm40,144a20,20,0,0,1-40,0V180h20A20,20,0,0,1,172,200Zm28-28H152a20,20,0,0,1-20-20V132h68a20,20,0,0,1,0,40Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
