/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M237.24,213.21C216.12,203,204,180.64,204,152V134.73a19.94,19.94,0,0,0-12.62-18.59l-24.86-9.81a4,4,0,0,1-2.26-5.14l21.33-53A32,32,0,0,0,167.17,6,32.13,32.13,0,0,0,126.25,24.2l-.07.18-21,53.09a3.94,3.94,0,0,1-2.14,2.2,3.89,3.89,0,0,1-3,.06L74.6,69.43A19.89,19.89,0,0,0,52.87,74C31.06,96.43,20,122.68,20,152a115.46,115.46,0,0,0,32.29,80.3A12,12,0,0,0,61,236H232a12,12,0,0,0,5.24-22.79ZM68.19,92.73,91.06,102A28,28,0,0,0,127.5,86.31l20.95-53a8.32,8.32,0,0,1,10.33-4.81,8,8,0,0,1,4.61,10.57,1.17,1.17,0,0,0,0,.11L142,92.29a28.05,28.05,0,0,0,15.68,36.33L180,137.45V152c0,1,0,2.07.05,3.1l-122.44-49A101.91,101.91,0,0,1,68.19,92.73ZM116.74,212a83.73,83.73,0,0,1-22.09-39,12,12,0,0,0-23.25,6,110.27,110.27,0,0,0,14.49,33H66.25A91.53,91.53,0,0,1,44,152a84,84,0,0,1,3.41-24.11l136.67,54.66A86.58,86.58,0,0,0,198.66,212Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M192.8,165.12,43.93,105.57A110.88,110.88,0,0,1,61.47,82.38a8,8,0,0,1,8.67-1.81L95.52,90.85a16,16,0,0,0,20.82-9l21-53.1c4.15-10,15.47-15.33,25.63-11.53a20,20,0,0,1,11.51,26.39L153.13,96.71a16,16,0,0,0,8.93,20.75L187,127.3a8,8,0,0,1,5,7.43V152A104.58,104.58,0,0,0,192.8,165.12Z" opacity="0.2"/><path d="M235.5,216.81c-22.56-11-35.5-34.58-35.5-64.8V134.73a15.94,15.94,0,0,0-10.09-14.87L165,110a8,8,0,0,1-4.48-10.34l21.32-53a28,28,0,0,0-16.1-37,28.14,28.14,0,0,0-35.82,16,.61.61,0,0,0,0,.12L108.9,79a8,8,0,0,1-10.37,4.49L73.11,73.14A15.89,15.89,0,0,0,55.74,76.8C34.68,98.45,24,123.75,24,152a111.45,111.45,0,0,0,31.18,77.53A8,8,0,0,0,61,232H232a8,8,0,0,0,3.5-15.19ZM67.14,88l25.41,10.3a24,24,0,0,0,31.23-13.45l21-53c2.56-6.11,9.47-9.27,15.43-7a12,12,0,0,1,6.88,15.92L145.69,93.76a24,24,0,0,0,13.43,31.14L184,134.73V152c0,.33,0,.66,0,1L55.77,101.71A108.84,108.84,0,0,1,67.14,88Zm48,128a87.53,87.53,0,0,1-24.34-42,8,8,0,0,0-15.49,4,105.16,105.16,0,0,0,18.36,38H64.44A95.54,95.54,0,0,1,40,152a85.9,85.9,0,0,1,7.73-36.29l137.8,55.12c3,18,10.56,33.48,21.89,45.16Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M235.29,216.7C212.86,205.69,200,182.12,200,152V134.69a15.94,15.94,0,0,0-10.09-14.87l-28.65-11.46A8,8,0,0,1,156.79,98l22.32-56.67C184,28.79,178,14.21,165.34,9.51a24,24,0,0,0-30.7,13.71L112.25,80.08a8,8,0,0,1-10.41,4.5L73.11,73.08a15.91,15.91,0,0,0-17.38,3.66C34.68,98.4,24,123.71,24,152a111.53,111.53,0,0,0,31.15,77.53A8.06,8.06,0,0,0,61,232H232a8,8,0,0,0,8-7.51A8.21,8.21,0,0,0,235.29,216.7ZM115.11,216a87.52,87.52,0,0,1-24.26-41.71,8.21,8.21,0,0,0-9.25-6.18A8,8,0,0,0,75.28,178a105.33,105.33,0,0,0,18.36,38H64.44A95.62,95.62,0,0,1,40,152a85.92,85.92,0,0,1,7.73-36.3l137.8,55.13c3,18.06,10.55,33.5,21.89,45.19Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M234.62,218.6C211.35,207.29,198,183,198,152V134.7a14,14,0,0,0-8.82-13l-24.89-9.83a10,10,0,0,1-5.59-13L180,45.9a26,26,0,0,0-15-34.33c-12.95-4.83-27.88,1.84-33.31,15l-21,53.11a10,10,0,0,1-13,5.61L72.37,75a13.9,13.9,0,0,0-15.2,3.19C36.49,99.42,26,124.26,26,152a109.53,109.53,0,0,0,30.62,76.16A6,6,0,0,0,61,230H232a6,6,0,0,0,2.62-11.4ZM65.77,86.52a2,2,0,0,1,2.12-.43l25.4,10.29a22,22,0,0,0,28.63-12.32l21-53c3-7.13,11-10.81,18-8.21a14,14,0,0,1,8,18.54l-21.36,53.1A22.05,22.05,0,0,0,159.86,123l24.88,9.83A2,2,0,0,1,186,134.7V152c0,1.34,0,2.65.08,4L52.74,102.61A110.07,110.07,0,0,1,65.77,86.52ZM114.33,218a89.6,89.6,0,0,1-25.5-43.5,6,6,0,1,0-11.62,3A102.87,102.87,0,0,0,97.81,218H63.56A97.56,97.56,0,0,1,38,152a87.42,87.42,0,0,1,8.71-38.86L187.35,169.4c3.15,19.92,11.77,36.66,25,48.6Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M235.5,216.81c-22.56-11-35.5-34.58-35.5-64.8V134.73a15.94,15.94,0,0,0-10.09-14.87L165,110a8,8,0,0,1-4.48-10.34l21.32-53a28,28,0,0,0-16.1-37,28.14,28.14,0,0,0-35.82,16,.61.61,0,0,0,0,.12L108.9,79a8,8,0,0,1-10.37,4.49L73.11,73.14A15.89,15.89,0,0,0,55.74,76.8C34.68,98.45,24,123.75,24,152a111.45,111.45,0,0,0,31.18,77.53A8,8,0,0,0,61,232H232a8,8,0,0,0,3.5-15.19ZM67.14,88l25.41,10.3a24,24,0,0,0,31.23-13.45l21-53c2.56-6.11,9.47-9.27,15.43-7a12,12,0,0,1,6.88,15.92L145.69,93.76a24,24,0,0,0,13.43,31.14L184,134.73V152c0,.33,0,.66,0,1L55.77,101.71A108.84,108.84,0,0,1,67.14,88Zm48,128a87.53,87.53,0,0,1-24.34-42,8,8,0,0,0-15.49,4,105.16,105.16,0,0,0,18.36,38H64.44A95.54,95.54,0,0,1,40,152a85.9,85.9,0,0,1,7.73-36.29l137.8,55.12c3,18,10.56,33.48,21.89,45.16Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M233.75,220.4C209.76,208.75,196,183.82,196,152V134.72a12,12,0,0,0-7.56-11.15l-24.89-9.83a12,12,0,0,1-6.71-15.55l21.33-53a23.88,23.88,0,0,0-31.93-31A24.72,24.72,0,0,0,133.62,27.3l-21,53.1A12,12,0,0,1,97,87.13L71.63,76.84a12,12,0,0,0-13,2.73C38.3,100.45,28,124.82,28,152a107.5,107.5,0,0,0,30.07,74.77A4,4,0,0,0,61,228H232a4,4,0,0,0,1.75-7.6ZM64.34,85.15a3.94,3.94,0,0,1,4.3-.89L94,94.55a20,20,0,0,0,26-11.2l21-53C144.39,22.19,153.61,18,161.58,21a16,16,0,0,1,9.19,21.16L149.41,95.22a20,20,0,0,0,11.18,26l24.9,9.83a4,4,0,0,1,2.51,3.72V152c0,2.36.08,4.69.22,7l-138.5-55.4A110.84,110.84,0,0,1,64.34,85.15ZM113.56,220A91.35,91.35,0,0,1,86.9,175a4,4,0,0,0-7.75,2,100.21,100.21,0,0,0,23.09,43H62.68A99.5,99.5,0,0,1,36,152a89.37,89.37,0,0,1,9.73-41.4L189.13,168c3.22,22,13.23,40.09,28.8,52Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
