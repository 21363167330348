/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M244,112a60.07,60.07,0,0,0-60-60c-1,0-1.95,0-2.92.08a60,60,0,0,0-106.16,0C74,52,73,52,72,52A60,60,0,0,0,44,165v43a20,20,0,0,0,20,20H192a20,20,0,0,0,20-20V165A60,60,0,0,0,244,112Zm-56,92H68V171.85c1.32.09,2.65.15,4,.15H184c1.35,0,2.68-.06,4-.15Zm-4-56h-8.63l4.27-17.09a12,12,0,0,0-23.28-5.82L150.63,148H140V128a12,12,0,0,0-24,0v20H105.37l-5.73-22.91a12,12,0,1,0-23.28,5.82L80.63,148H72a36,36,0,0,1-3.87-71.79C68.05,77.47,68,78.73,68,80a12,12,0,0,0,24,0,36,36,0,0,1,72,0,12,12,0,0,0,24,0c0-1.27-.05-2.53-.13-3.79A36,36,0,0,1,184,148Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M232,112a48,48,0,0,1-48,48H72A48,48,0,1,1,82.35,65.12h0a48,48,0,0,1,91.28,0h0A48,48,0,0,1,232,112Z" opacity="0.2"/><path d="M240,112a56.06,56.06,0,0,0-56-56c-1.77,0-3.54.1-5.29.26a56,56,0,0,0-101.42,0C75.54,56.1,73.77,56,72,56A56,56,0,0,0,48,162.59V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V162.59A56.09,56.09,0,0,0,240,112Zm-48,96H64V167.42a55.49,55.49,0,0,0,8,.58H184a55.49,55.49,0,0,0,8-.58Zm-8-56H170.25l5.51-22.06a8,8,0,0,0-15.52-3.88L153.75,152H136V128a8,8,0,0,0-16,0v24H102.25l-6.49-25.94a8,8,0,1,0-15.52,3.88L85.75,152H72a40,40,0,0,1,0-80l.58,0A55.21,55.21,0,0,0,72,80a8,8,0,0,0,16,0,40,40,0,0,1,80,0,8,8,0,0,0,16,0,55.21,55.21,0,0,0-.58-8l.58,0a40,40,0,0,1,0,80Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M240,112a56.06,56.06,0,0,0-56-56c-1.77,0-3.54.1-5.29.26a56,56,0,0,0-101.42,0C75.54,56.1,73.77,56,72,56A56,56,0,0,0,48,162.59V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V162.59A56.09,56.09,0,0,0,240,112Zm-87.76,30.06,8-32a8,8,0,0,1,15.52,3.88l-8,32A8,8,0,0,1,160,152a8.13,8.13,0,0,1-1.95-.24A8,8,0,0,1,152.24,142.06ZM120,112a8,8,0,0,1,16,0v32a8,8,0,0,1-16,0Zm-33.94-7.76a8,8,0,0,1,9.7,5.82l8,32a8,8,0,0,1-5.82,9.7,8.13,8.13,0,0,1-2,.24,8,8,0,0,1-7.75-6.06l-8-32A8,8,0,0,1,86.06,104.24ZM192,208H64V167.42a55.49,55.49,0,0,0,8,.58H184a55.49,55.49,0,0,0,8-.58Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M238,112a54.06,54.06,0,0,0-54-54,54.6,54.6,0,0,0-6.52.4,54,54,0,0,0-99,0A54.6,54.6,0,0,0,72,58,54,54,0,0,0,50,161.3V208a14,14,0,0,0,14,14H192a14,14,0,0,0,14-14V161.3A54.07,54.07,0,0,0,238,112Zm-44,96a2,2,0,0,1-2,2H64a2,2,0,0,1-2-2V165.06A54,54,0,0,0,72,166H184a54,54,0,0,0,10-.94Zm-10-54H167.69l6.13-24.54a6,6,0,1,0-11.64-2.92L155.31,154H134V128a6,6,0,0,0-12,0v26H100.69l-6.87-27.46a6,6,0,0,0-11.64,2.92L88.31,154H72a42,42,0,0,1,0-84c1,0,2,0,2.92.11A54.63,54.63,0,0,0,74,80a6,6,0,0,0,12,0,42,42,0,0,1,84,0,6,6,0,0,0,12,0,54.63,54.63,0,0,0-.92-9.89c1-.06,1.94-.11,2.92-.11a42,42,0,0,1,0,84Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M240,112a56.06,56.06,0,0,0-56-56c-1.77,0-3.54.1-5.29.26a56,56,0,0,0-101.42,0C75.54,56.1,73.77,56,72,56A56,56,0,0,0,48,162.59V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V162.59A56.09,56.09,0,0,0,240,112Zm-48,96H64V167.42a55.49,55.49,0,0,0,8,.58H184a55.49,55.49,0,0,0,8-.58Zm-8-56H170.25l5.51-22.06a8,8,0,0,0-15.52-3.88L153.75,152H136V128a8,8,0,0,0-16,0v24H102.25l-6.49-25.94a8,8,0,1,0-15.52,3.88L85.75,152H72a40,40,0,0,1,0-80l.58,0A55.21,55.21,0,0,0,72,80a8,8,0,0,0,16,0,40,40,0,0,1,80,0,8,8,0,0,0,16,0,55.21,55.21,0,0,0-.58-8l.58,0a40,40,0,0,1,0,80Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M236,112a52.06,52.06,0,0,0-52-52,53,53,0,0,0-7.77.59,52,52,0,0,0-96.46,0A53,53,0,0,0,72,60,52,52,0,0,0,52,160v48a12,12,0,0,0,12,12H192a12,12,0,0,0,12-12V160A52.07,52.07,0,0,0,236,112Zm-40,96a4,4,0,0,1-4,4H64a4,4,0,0,1-4-4V162.59A52.13,52.13,0,0,0,72,164H184a52.13,52.13,0,0,0,12-1.41Zm-12-52H165.12l6.76-27a4,4,0,0,0-7.76-1.94l-7.24,29H132V128a4,4,0,0,0-8,0v28H99.12l-7.24-29A4,4,0,1,0,84.12,129l6.76,27H72a44,44,0,0,1,0-88,45.3,45.3,0,0,1,5.34.33A51.68,51.68,0,0,0,76,80a4,4,0,0,0,8,0,44,44,0,0,1,88,0,4,4,0,0,0,8,0,51.68,51.68,0,0,0-1.34-11.67A45.3,45.3,0,0,1,184,68a44,44,0,0,1,0,88Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
