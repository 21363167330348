/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M252,104a36,36,0,0,0-57.4-28.93,145.56,145.56,0,0,0-52.54-14.45l3.8-22.81,15.67,2.61a24,24,0,1,0,3.95-23.67L138,12.16A12,12,0,0,0,124.16,22l-6.38,38.31C97.48,61.62,78,66.7,61.4,75.07a36,36,0,0,0-47.52,53.68A62.87,62.87,0,0,0,12,144c0,23.29,12.65,44.87,35.63,60.78C69.26,219.75,97.8,228,128,228s58.74-8.25,80.37-23.22C231.35,188.87,244,167.29,244,144a62.87,62.87,0,0,0-1.88-15.25A36.16,36.16,0,0,0,252,104Zm-29.91,10.34a12,12,0,0,0-5,15A39.61,39.61,0,0,1,220,144c0,15.18-9,29.75-25.29,41.05C177.05,197.27,153.36,204,128,204s-49-6.73-66.71-18.95C45,173.75,36,159.18,36,144a39.74,39.74,0,0,1,2.84-14.64,12,12,0,0,0-4.93-15A12,12,0,1,1,49.71,97a12,12,0,0,0,15.94,3.18l.22-.13C82.94,89.68,105,84,128,84H128c23,0,45,5.69,62.09,16l.22.13A12,12,0,0,0,206.29,97,11.88,11.88,0,0,1,216,92a12,12,0,0,1,6.09,22.34ZM88,148a20,20,0,1,1,20-20A20,20,0,0,1,88,148Zm100-20a20,20,0,1,1-20-20A20,20,0,0,1,188,128Zm-20.52,40.52a12,12,0,0,1-8,15,111.88,111.88,0,0,1-63,0,12,12,0,0,1,7-23,88.12,88.12,0,0,0,49,0A12,12,0,0,1,167.48,168.52Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M228.21,124.67h0A51.6,51.6,0,0,1,232,144c0,39.76-46.56,72-104,72S24,183.76,24,144a51.6,51.6,0,0,1,3.79-19.33h0A24,24,0,1,1,59.41,89.88h0C77.72,78.75,101.72,72,128,72s50.28,6.75,68.59,17.88h0a24,24,0,1,1,31.62,34.79Z" opacity="0.2"/><path d="M248,104a32,32,0,0,0-52.94-24.19c-16.75-8.9-36.76-14.28-57.66-15.53l5.19-31.17,17.72,2.72a24,24,0,1,0,2.87-15.74l-26-4a8,8,0,0,0-9.11,6.59L121.2,64.16c-21.84.94-42.82,6.38-60.26,15.65a32,32,0,0,0-42.59,47.74A59,59,0,0,0,16,144c0,21.93,12,42.35,33.91,57.49C70.88,216,98.61,224,128,224s57.12-8,78.09-22.51C228,186.35,240,165.93,240,144a59,59,0,0,0-2.35-16.45A32.16,32.16,0,0,0,248,104ZM184,24a8,8,0,1,1-8,8A8,8,0,0,1,184,24Zm40.13,93.78a8,8,0,0,0-3.29,10A43.62,43.62,0,0,1,224,144c0,16.53-9.59,32.27-27,44.33C178.67,201,154.17,208,128,208s-50.67-7-69-19.67C41.59,176.27,32,160.53,32,144a43.75,43.75,0,0,1,3.14-16.17,8,8,0,0,0-3.27-10A16,16,0,1,1,52.94,94.59a8,8,0,0,0,10.63,2.12l.18-.11C81.45,85.9,104.25,80,128,80h0c23.73,0,46.53,5.9,64.23,16.6l.18.11a8,8,0,0,0,10.63-2.12,16,16,0,1,1,21.07,23.19ZM88,144a16,16,0,1,1,16-16A16,16,0,0,1,88,144Zm96-16a16,16,0,1,1-16-16A16,16,0,0,1,184,128Zm-16.93,44.25a8,8,0,0,1-3.32,10.82,76.18,76.18,0,0,1-71.5,0,8,8,0,1,1,7.5-14.14,60.18,60.18,0,0,0,56.5,0A8,8,0,0,1,167.07,172.25Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M248,104a32,32,0,0,0-52.94-24.19c-16.75-8.9-36.76-14.28-57.66-15.53l5.19-31.17,17.72,2.72a24,24,0,1,0,2.87-15.74l-26-4a8,8,0,0,0-9.11,6.59L121.2,64.16c-21.84.94-42.82,6.38-60.26,15.65a32,32,0,0,0-42.59,47.74A59,59,0,0,0,16,144c0,21.93,12,42.35,33.91,57.49C70.88,216,98.61,224,128,224s57.12-8,78.09-22.51C228,186.35,240,165.93,240,144a59,59,0,0,0-2.35-16.45A32.16,32.16,0,0,0,248,104ZM72,128a16,16,0,1,1,16,16A16,16,0,0,1,72,128Zm91.75,55.07a76.18,76.18,0,0,1-71.5,0,8,8,0,1,1,7.5-14.14,60.18,60.18,0,0,0,56.5,0,8,8,0,1,1,7.5,14.14ZM168,144a16,16,0,1,1,16-16A16,16,0,0,1,168,144Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M246,104a30,30,0,0,0-50.64-21.76C178,72.75,157,67.17,135.05,66.18l5.89-35.35,21.16,3.26a22,22,0,1,0,2.17-11.81l-27.36-4.21A6,6,0,0,0,130.08,23L122.9,66.09c-22.64.74-44.41,6.38-62.26,16.15a30,30,0,1,0-40,44.66A57.07,57.07,0,0,0,18,144c0,21.26,11.74,41.09,33.05,55.84C71.68,214.13,99,222,128,222s56.32-7.87,76.95-22.16C226.26,185.09,238,165.26,238,144a57.07,57.07,0,0,0-2.63-17.1A30.16,30.16,0,0,0,246,104ZM184,22a10,10,0,1,1-10,10A10,10,0,0,1,184,22Zm41.15,97.51a6,6,0,0,0-2.46,7.51A45.65,45.65,0,0,1,226,144c0,36.39-44,66-98,66s-98-29.61-98-66a45.63,45.63,0,0,1,3.3-16.94,6,6,0,0,0-2.45-7.55,18,18,0,1,1,23.71-26.1,6,6,0,0,0,7.79,1.7,3.79,3.79,0,0,0,.34-.2C80.7,84,103.89,78,128,78h0c24.1,0,47.28,6,65.29,16.9l.16.11a6,6,0,0,0,8-1.6,18,18,0,1,1,23.71,26.1ZM165.3,173.19a6,6,0,0,1-2.49,8.11,74.22,74.22,0,0,1-69.62,0,6,6,0,0,1,5.62-10.6,62.2,62.2,0,0,0,58.38,0A6,6,0,0,1,165.3,173.19ZM88,142a14,14,0,1,1,14-14A14,14,0,0,1,88,142Zm94-14a14,14,0,1,1-14-14A14,14,0,0,1,182,128Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M248,104a32,32,0,0,0-52.94-24.19c-16.75-8.9-36.76-14.28-57.66-15.53l5.19-31.17,17.72,2.72a24,24,0,1,0,2.87-15.74l-26-4a8,8,0,0,0-9.11,6.59L121.2,64.16c-21.84.94-42.82,6.38-60.26,15.65a32,32,0,0,0-42.59,47.74A59,59,0,0,0,16,144c0,21.93,12,42.35,33.91,57.49C70.88,216,98.61,224,128,224s57.12-8,78.09-22.51C228,186.35,240,165.93,240,144a59,59,0,0,0-2.35-16.45A32.16,32.16,0,0,0,248,104ZM184,24a8,8,0,1,1-8,8A8,8,0,0,1,184,24Zm40.13,93.78a8,8,0,0,0-3.29,10A43.58,43.58,0,0,1,224,144c0,16.53-9.59,32.27-27,44.33C178.67,201,154.17,208,128,208s-50.67-7-69-19.67C41.59,176.27,32,160.53,32,144a43.75,43.75,0,0,1,3.14-16.17,8,8,0,0,0-3.27-10A16,16,0,1,1,52.94,94.59a8,8,0,0,0,10.45,2.23l.36-.22C81.45,85.9,104.25,80,128,80h0c23.73,0,46.53,5.9,64.23,16.6l.42.25a8,8,0,0,0,10.39-2.26,16,16,0,1,1,21.07,23.19ZM88,144a16,16,0,1,1,16-16A16,16,0,0,1,88,144Zm96-16a16,16,0,1,1-16-16A16,16,0,0,1,184,128Zm-16.93,44.25a8,8,0,0,1-3.32,10.82,76.18,76.18,0,0,1-71.5,0,8,8,0,1,1,7.5-14.14,60.18,60.18,0,0,0,56.5,0A8,8,0,0,1,167.07,172.25Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M244,104a28,28,0,0,0-48.3-19.28c-17.9-10.11-40-15.94-63-16.65l6.59-39.52L164,32.36a20,20,0,1,0,1.46-7.87l-28.87-4.44a4,4,0,0,0-4.56,3.29L124.6,68.05c-23.48.52-46.06,6.36-64.3,16.67A28,28,0,1,0,23,126.21,55.14,55.14,0,0,0,20,144c0,20.58,11.43,39.83,32.18,54.2C72.49,212.26,99.42,220,128,220s55.51-7.74,75.82-21.8C224.57,183.83,236,164.58,236,144a55.14,55.14,0,0,0-3-17.79A28.14,28.14,0,0,0,244,104ZM184,20a12,12,0,1,1-12,12A12,12,0,0,1,184,20Zm40.53,106.25A47.69,47.69,0,0,1,228,144c0,37.5-44.86,68-100,68S28,181.5,28,144a47.71,47.71,0,0,1,3.45-17.72,4,4,0,0,0-1.62-5,20,20,0,1,1,26.35-29,4,4,0,0,0,5.19,1.13l.25-.16C80,82.11,103.51,76,128,76h0c24.48,0,48,6.11,66.37,17.21l.25.16a4,4,0,0,0,5.19-1.13,20,20,0,1,1,26.35,29A4,4,0,0,0,224.53,126.25Zm-61,47.88a4,4,0,0,1-1.65,5.4,72.22,72.22,0,0,1-67.76,0,4,4,0,0,1,3.76-7.06,64.23,64.23,0,0,0,60.24,0A4,4,0,0,1,163.53,174.13ZM88,140a12,12,0,1,1,12-12A12,12,0,0,1,88,140Zm92-12a12,12,0,1,1-12-12A12,12,0,0,1,180,128Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
