import { template } from "@ember/template-compiler";
import { cached } from '@glimmer/tracking';
import { LinkTo } from '@ember/routing';
import { service } from '@ember/service';
import { pageTitle } from 'ember-page-title';
import { Route } from 'ember-polaris-routing';
import CompatRoute from 'ember-polaris-routing/route/compat';
import { use } from 'ember-resources';
import Task from 'ember-tasks';
import { findSkills } from '../-resource';
import type FastbootService from 'ember-cli-fastboot/services/fastboot';
export class CourseIndexRoute extends Route<object> {
    @service
    fastboot: FastbootService;
    @cached
    get load() {
        const promise1 = use(this, findSkills()).current;
        if (this.fastboot.isFastBoot) {
            this.fastboot.deferRendering(promise1);
        }
        return Task.promise(promise1);
    }
    static{
        template(`
    {{pageTitle "Fertigkeiten"}}

    <h1>Fertigkeiten</h1>

    {{#let this.load as |r|}}
      {{#if r.resolved}}
        <ul>
          {{#each r.value as |skill|}}
            <li>
              <LinkTo
                @route="skills.details"
                @model={{skill._sys.filename}}
              >{{skill.title}}</LinkTo>
            </li>
          {{/each}}
        </ul>
      {{/if}}
    {{/let}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
// @ts-expect-error some broken upstream types here
export default CompatRoute(CourseIndexRoute);
