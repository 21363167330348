/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M56.9,31.93A12,12,0,1,0,39.15,48.07L63,74.36a147.07,147.07,0,0,0-41.43,29c-21.45,21.46-23.52,53.1-5,77A20,20,0,0,0,32.38,188a19.81,19.81,0,0,0,7.12-1.32l48.9-17.35.45-.17A19.94,19.94,0,0,0,101,154.5l5.44-27.22c1.22-.38,2.47-.72,3.72-1l88.91,97.83a12,12,0,1,0,17.75-16.14Zm26.5,88.36L78,147.57,33.84,163.22c-9.16-13.84-7.41-30.76,4.73-42.9A121.58,121.58,0,0,1,80.07,93.1L92.74,107A20.06,20.06,0,0,0,83.4,120.29Zm156,60a19.89,19.89,0,0,1-11.24,7.19,12,12,0,0,1-6.95-22.92c10.21-14.07,8.75-31.71-3.79-44.25C194.77,97.65,164,84.76,130.91,84a12,12,0,0,1,.27-24h.27c39.33.87,75.89,16.25,102.94,43.31C255.84,124.81,257.91,156.45,239.41,180.3Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M230,173a8,8,0,0,1-9.26,2.47L171.58,158a8,8,0,0,1-4.86-5.8l-6.21-29.74a7.94,7.94,0,0,0-5.14-5.9,84.39,84.39,0,0,0-55.1.13,7.93,7.93,0,0,0-5.12,6l-5.9,29.51A8,8,0,0,1,84.38,158L35.29,175.42A8,8,0,0,1,26,173c-14.6-18.83-13.26-43.83,4-61.12,53.11-53.11,142.77-53.11,195.88,0C243.23,129.12,244.57,154.12,230,173Z" opacity="0.2"/><path d="M53.93,34.62A8,8,0,1,0,42.09,45.38L69.68,75.74a141.26,141.26,0,0,0-45.27,30.44c-20,20-21.92,49.46-4.69,71.67a16,16,0,0,0,18.38,5.07l49-17.37.29-.11a16,16,0,0,0,9.75-11.72l5.9-29.51a75.89,75.89,0,0,1,8.56-2.4l90.51,99.57a8,8,0,1,0,11.84-10.76Zm43.7,74.52a16,16,0,0,0-10.32,11.94l-5.9,29.5-48.78,17.3c-.1,0-.17.13-.27.17-12.33-15.9-11-36.22,3.36-50.56a125.79,125.79,0,0,1,45.47-29.1l18.3,20.14C98.87,108.73,98.25,108.92,97.63,109.14Zm138.65,68.71a16,16,0,0,1-18.38,5.07l-9.25-3.28A8,8,0,0,1,214,164.56l9.37,3.32.3.12c12.3-15.85,11-36.17-3.39-50.51-25.66-25.66-61.88-39.27-99.35-37.31a8,8,0,1,1-.83-16c42-2.19,82.63,13.1,111.49,42C251.58,126.17,253.51,155.64,236.28,177.85Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M236.28,177.85a16,16,0,0,1-18.38,5.07l-24.76-19a3.43,3.43,0,0,1-.53-.48L109.18,71.62a4,4,0,0,1,2.55-6.68c43-4.62,87.74,9.12,119.86,41.24h0C251.58,126.17,253.51,155.64,236.28,177.85ZM53.93,34.62A8,8,0,1,0,42.09,45.38L69.71,75.77a142,142,0,0,0-45.3,30.41c-20,20-21.92,49.46-4.69,71.67a16,16,0,0,0,18.38,5.07l49-17.37.29-.11a16,16,0,0,0,9.75-11.72l5.9-29.51a73.64,73.64,0,0,1,8.57-2.39l90.5,99.56a8,8,0,1,0,11.84-10.76Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M52.44,36A6,6,0,0,0,43.56,44L73.08,76.51a139.32,139.32,0,0,0-47.27,31.08c-19.25,19.26-21.11,47.65-4.52,69A14,14,0,0,0,37.39,181l49-17.37.22-.09a13.93,13.93,0,0,0,8.53-10.25l5.9-29.51a2,2,0,0,1,1.21-1.47,80.07,80.07,0,0,1,10-2.75L203.56,220a6,6,0,0,0,8.88-8.08ZM98.29,111a14,14,0,0,0-9,10.45L83.37,151a2,2,0,0,1-1.15,1.43L33.28,169.77l-.22.08a2,2,0,0,1-2.29-.58c-13-16.73-11.56-38.11,3.53-53.19A127.91,127.91,0,0,1,81.75,86l21.34,23.48C101.47,110,99.87,110.47,98.29,111Zm136.42,65.61a14,14,0,0,1-16.1,4.41l-9.28-3.29a6,6,0,1,1,4-11.31l9.38,3.33.22.08a2,2,0,0,0,2.29-.58c13-16.73,11.56-38.11-3.53-53.19C195.64,90,158.86,76.2,120.83,78.19a6,6,0,1,1-.63-12c41.44-2.15,81.52,12.93,110,41.39C249.44,126.85,251.3,155.24,234.71,176.63Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M53.93,34.62A8,8,0,1,0,42.09,45.38L69.68,75.74a141.26,141.26,0,0,0-45.27,30.44c-20,20-21.92,49.46-4.69,71.67a16,16,0,0,0,18.38,5.07l49-17.37.29-.11a16,16,0,0,0,9.75-11.72l5.9-29.51a75.89,75.89,0,0,1,8.56-2.4l90.51,99.57a8,8,0,1,0,11.84-10.76Zm43.7,74.52a16,16,0,0,0-10.32,11.94l-5.9,29.5-48.78,17.3c-.1,0-.17.13-.27.17-12.33-15.9-11-36.22,3.36-50.56a125.79,125.79,0,0,1,45.47-29.1l18.3,20.14C98.87,108.73,98.25,108.92,97.63,109.14Zm138.65,68.71a16,16,0,0,1-18.38,5.07l-9.25-3.28A8,8,0,0,1,214,164.56l9.37,3.32.3.12c12.3-15.85,11-36.17-3.39-50.51-25.66-25.66-61.88-39.27-99.35-37.31a8,8,0,1,1-.83-16c42-2.19,82.63,13.1,111.49,42C251.58,126.17,253.51,155.64,236.28,177.85Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M51,37.31A4,4,0,1,0,45,42.69L76.52,77.32A137.72,137.72,0,0,0,27.21,109C8.69,127.53,6.9,154.83,22.85,175.4a12,12,0,0,0,13.83,3.77l49-17.39.15-.05a12,12,0,0,0,7.31-8.79l5.9-29.51a3.92,3.92,0,0,1,2.51-3,79.44,79.44,0,0,1,11.4-3l92.07,101.26a4,4,0,1,0,5.92-5.38Zm48,75.6a12,12,0,0,0-7.72,9l-5.9,29.51a4,4,0,0,1-2.37,2.9l-49,17.38-.15.06a4,4,0,0,1-4.61-1.21c-13.42-17.29-11.9-40.25,3.69-55.84A130.06,130.06,0,0,1,82.33,83.72l24.44,26.88Q102.8,111.58,98.94,112.91ZM233.15,175.4a12,12,0,0,1-13.83,3.77l-9.31-3.3a4,4,0,1,1,2.68-7.54l9.37,3.32.15.06a4,4,0,0,0,4.61-1.21c13.42-17.29,11.9-40.25-3.69-55.84C196.66,88.2,159.34,74.18,120.72,76.19a4,4,0,1,1-.41-8c40.86-2.12,80.41,12.74,108.48,40.8C247.31,127.53,249.1,154.83,233.15,175.4Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
