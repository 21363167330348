import { template } from "@ember/template-compiler";
import { Icon } from '@hokulea/ember';
import { getLocomotionIcon, getLocomotionText, getPersonalIcon, getPersonalText } from '../-helpers';
import type { Locomotion, Personal } from '../domain-objects';
import type { TOC } from '@ember/component/template-only';
export const FormatPersonalIcon: TOC<{
    Args: {
        personal: Personal;
    };
}> = template(`
  <Icon @icon={{getPersonalIcon @personal}} />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export const FormatPersonal: TOC<{
    Args: {
        personal: Personal;
    };
}> = template(`
  <FormatPersonalIcon @personal={{@personal}} />
  {{getPersonalText @personal}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export const FormatLocomotionIcon: TOC<{
    Args: {
        locomotion: Locomotion;
    };
}> = template(`
  <Icon @icon={{getLocomotionIcon @locomotion}} />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export const FormatLocomotion: TOC<{
    Args: {
        locomotion: Locomotion;
    };
}> = template(`
  <FormatLocomotionIcon @locomotion={{@locomotion}} />
  {{getLocomotionText @locomotion}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
