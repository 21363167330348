/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M120,160a16,16,0,1,1-16-16A16,16,0,0,1,120,160Zm32-16a16,16,0,1,0,16,16A16,16,0,0,0,152,144Zm68,44a56,56,0,0,1-92,42.89,56,56,0,0,1-75-83,75.47,75.47,0,0,1,5.59-18.74,241.48,241.48,0,0,1-19.8-58.23C35.55,54.21,33.54,31.35,42.21,17A25.89,25.89,0,0,1,65.06,4c22.63,0,40.6,27.11,53.48,80.6a74.88,74.88,0,0,1,18.92,0C150.34,31.11,168.31,4,190.94,4a25.89,25.89,0,0,1,22.85,13c8.67,14.34,6.66,37.2,3.45,53.86a241.48,241.48,0,0,1-19.8,58.23A75.6,75.6,0,0,1,203,147.84,55.76,55.76,0,0,1,220,188ZM160.53,91.32a75.84,75.84,0,0,1,18.72,12.56c.78.71,1.53,1.43,2.28,2.17a211,211,0,0,0,12.14-39.73c3.9-20.23,2.34-32.33-.42-36.9C192.43,28.07,192,28,190.94,28,184.77,28,171.51,45.4,160.53,91.32ZM74.47,106.05c.74-.73,1.5-1.46,2.28-2.17A75.84,75.84,0,0,1,95.47,91.32C84.49,45.4,71.23,28,65.06,28c-1.08,0-1.49.07-2.31,1.42C56.54,39.7,60.73,72.84,74.47,106.05ZM196,188a31.9,31.9,0,0,0-11.86-24.87,12,12,0,0,1-4.38-8.17,52,52,0,0,0-103.52,0,12,12,0,0,1-4.38,8.17,32,32,0,0,0,38.47,51.08l-6-5a12,12,0,0,1,15.34-18.46L128,197.7l8.33-6.93a12,12,0,1,1,15.34,18.46l-6,5A32,32,0,0,0,196,188Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M208,188a44,44,0,0,1-80,25.3,44,44,0,1,1-63.71-59.48,64,64,0,0,1,127.42,0A43.9,43.9,0,0,1,208,188Z" opacity="0.2"/><path d="M112,164a12,12,0,1,1-12-12A12,12,0,0,1,112,164Zm44-12a12,12,0,1,0,12,12A12,12,0,0,0,156,152Zm60,36a52,52,0,0,1-88,37.52A52,52,0,1,1,56.72,149.8,71.58,71.58,0,0,1,63,129C44,91.1,32.53,40.76,45.64,19.08A22,22,0,0,1,65.06,8c14.12,0,26,11.89,36.44,36.36,6.22,14.62,10.85,31.32,14,44.74a71.8,71.8,0,0,1,25,0c3.13-13.42,7.76-30.12,14-44.74C164.9,19.89,176.82,8,190.94,8a22,22,0,0,1,19.42,11.08C223.47,40.76,212,91.1,193,129a71.58,71.58,0,0,1,6.26,20.76A51.77,51.77,0,0,1,216,188ZM155.89,93.63a71.72,71.72,0,0,1,26.88,19.64A218.45,218.45,0,0,0,197.6,67.08c3.49-18.13,3.15-33-.93-39.72A6,6,0,0,0,190.94,24c-6.61,0-14.52,9.7-21.72,26.62C163.29,64.56,158.87,80.74,155.89,93.63ZM73.23,113.27a71.72,71.72,0,0,1,26.88-19.64c-3-12.89-7.4-29.07-13.33-43C79.58,33.7,71.67,24,65.06,24a6,6,0,0,0-5.73,3.36C55.25,34.1,54.91,49,58.4,67.08A218.45,218.45,0,0,0,73.23,113.27ZM200,188a35.87,35.87,0,0,0-13.34-28,8,8,0,0,1-2.92-5.45,56,56,0,0,0-111.48,0A8,8,0,0,1,69.34,160a36,36,0,1,0,47.28,54.21l-9.74-8.09a8,8,0,1,1,10.24-12.3L128,202.9l10.88-9.05a8,8,0,0,1,10.24,12.3l-9.74,8.09A36,36,0,0,0,200,188Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M199.28,149.8A71.58,71.58,0,0,0,193,129c19-37.94,30.45-88.28,17.34-110A22,22,0,0,0,190.94,8c-14.12,0-26,11.89-36.44,36.36-6.22,14.62-10.85,31.32-14,44.74a71.8,71.8,0,0,0-25,0c-3.13-13.42-7.76-30.12-14-44.74C91.1,19.89,79.18,8,65.06,8A22,22,0,0,0,45.64,19.08C32.53,40.76,44,91.1,63,129a71.58,71.58,0,0,0-6.26,20.76A52,52,0,1,0,128,225.52l-21.12-19.37a8,8,0,1,1,10.24-12.3L128,202.9l10.88-9.05a8,8,0,0,1,10.24,12.3L128,225.52a52,52,0,1,0,71.28-75.72Zm-126-36.53A218.45,218.45,0,0,1,58.4,67.08c-3.49-18.13-3.15-33,.93-39.72A6,6,0,0,1,65.06,24c6.61,0,14.52,9.7,21.72,26.62,5.93,13.94,10.35,30.12,13.33,43a71.72,71.72,0,0,0-26.88,19.64ZM100,176a12,12,0,1,1,12-12A12,12,0,0,1,100,176Zm56,0a12,12,0,1,1,12-12A12,12,0,0,1,156,176Zm20.55-69.17a71.89,71.89,0,0,0-20.66-13.2c3-12.89,7.4-29.07,13.33-43C176.42,33.7,184.33,24,190.94,24a6,6,0,0,1,5.73,3.36c4.08,6.74,4.42,21.59.93,39.72a218.45,218.45,0,0,1-14.83,46.19A72.6,72.6,0,0,0,176.55,106.83Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M110,164a10,10,0,1,1-10-10A10,10,0,0,1,110,164Zm46-10a10,10,0,1,0,10,10A10,10,0,0,0,156,154Zm58,34a50,50,0,0,1-86,34.7,50,50,0,1,1-69.39-71.91A70,70,0,0,1,65.23,129a235.12,235.12,0,0,1-20.56-59.3c-3-15.62-5-36.91,2.69-49.62A19.84,19.84,0,0,1,65.07,10C89.91,10,105.23,52.92,114,91.43a69.44,69.44,0,0,1,28,0C150.77,52.92,166.09,10,190.93,10a19.84,19.84,0,0,1,17.71,10.11c7.68,12.71,5.7,34,2.69,49.62A235.12,235.12,0,0,1,190.77,129a70,70,0,0,1,6.62,21.76A49.79,49.79,0,0,1,214,188ZM153.56,94.83a70.05,70.05,0,0,1,21.64,13.48,71.17,71.17,0,0,1,8.05,8.72C199.87,81.2,207,40.54,198.37,26.32A8,8,0,0,0,190.93,22C178.1,22,163.61,50.4,153.56,94.83ZM72.75,117a71.17,71.17,0,0,1,8-8.72,70.05,70.05,0,0,1,21.64-13.48C92.39,50.4,77.9,22,65.07,22a8,8,0,0,0-7.44,4.32C49,40.54,56.13,81.2,72.75,117ZM202,188a37.87,37.87,0,0,0-14.07-29.52,6.05,6.05,0,0,1-2.2-4.09,58,58,0,0,0-115.44,0,6,6,0,0,1-2.19,4.08,38,38,0,1,0,51.5,55.64l-11.42-9.5a6,6,0,1,1,7.67-9.22L128,205.5l12.16-10.11a6,6,0,1,1,7.67,9.22l-11.42,9.5A38,38,0,0,0,202,188Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M112,164a12,12,0,1,1-12-12A12,12,0,0,1,112,164Zm44-12a12,12,0,1,0,12,12A12,12,0,0,0,156,152Zm60,36a52,52,0,0,1-88,37.52A52,52,0,1,1,56.72,149.8,71.58,71.58,0,0,1,63,129C44,91.1,32.53,40.76,45.64,19.08A22,22,0,0,1,65.06,8c14.12,0,26,11.89,36.44,36.36,6.22,14.62,10.85,31.32,14,44.74a71.8,71.8,0,0,1,25,0c3.13-13.42,7.76-30.12,14-44.74C164.9,19.89,176.82,8,190.94,8a22,22,0,0,1,19.42,11.08C223.47,40.76,212,91.1,193,129a71.58,71.58,0,0,1,6.26,20.76A51.77,51.77,0,0,1,216,188ZM155.89,93.63a71.72,71.72,0,0,1,26.88,19.64A218.45,218.45,0,0,0,197.6,67.08c3.49-18.13,3.15-33-.93-39.72A6,6,0,0,0,190.94,24c-6.61,0-14.52,9.7-21.72,26.62C163.29,64.56,158.87,80.74,155.89,93.63ZM73.23,113.27a71.72,71.72,0,0,1,26.88-19.64c-3-12.89-7.4-29.07-13.33-43C79.58,33.7,71.67,24,65.06,24a6,6,0,0,0-5.73,3.36C55.25,34.1,54.91,49,58.4,67.08A218.45,218.45,0,0,0,73.23,113.27ZM200,188a35.87,35.87,0,0,0-13.34-28,8,8,0,0,1-2.92-5.45,56,56,0,0,0-111.48,0A8,8,0,0,1,69.34,160a36,36,0,1,0,47.28,54.21l-9.74-8.09a8,8,0,1,1,10.24-12.3L128,202.9l10.88-9.05a8,8,0,0,1,10.24,12.3l-9.74,8.09A36,36,0,0,0,200,188Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M108,164a8,8,0,1,1-8-8A8,8,0,0,1,108,164Zm48-8a8,8,0,1,0,8,8A8,8,0,0,0,156,156Zm56,32a48,48,0,0,1-84,31.75,48,48,0,1,1-67.51-68,67.64,67.64,0,0,1,7-22.78C48.85,92.61,36.63,41.7,49.06,21.15a17.92,17.92,0,0,1,16-9.15c12.17,0,23.19,11.41,32.76,33.92C104.53,61.7,109.37,80,112.45,93.79a68.44,68.44,0,0,1,31.1,0c3.08-13.8,7.92-32.09,14.63-47.87C167.75,23.41,178.77,12,190.94,12a17.92,17.92,0,0,1,16,9.15c12.43,20.55.21,71.46-18.4,107.86a67.64,67.64,0,0,1,7,22.78A47.81,47.81,0,0,1,212,188ZM151.23,96.1a67.9,67.9,0,0,1,22.63,13.69,69,69,0,0,1,9.78,11.11c17.88-37.07,25.59-80.5,16.45-95.61A9.91,9.91,0,0,0,190.94,20C176.54,20,161.78,48.37,151.23,96.1ZM72.36,120.9a69,69,0,0,1,9.78-11.11A67.9,67.9,0,0,1,104.77,96.1C94.22,48.37,79.46,20,65.06,20a9.91,9.91,0,0,0-9.15,5.29C46.77,40.4,54.48,83.83,72.36,120.9ZM204,188a39.86,39.86,0,0,0-14.82-31.08,4,4,0,0,1-1.46-2.72,60,60,0,0,0-119.44,0,4,4,0,0,1-1.46,2.72,40,40,0,1,0,55.64,57l-13-10.82a4,4,0,1,1,5.12-6.16L128,208.1l13.44-11.18a4,4,0,0,1,5.12,6.16l-13,10.82A40,40,0,0,0,204,188Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
