/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M228.88,113.61A60,60,0,0,0,166,33.42,60,60,0,0,0,65.1,47.83a60,60,0,0,0-38,94.56A60.08,60.08,0,0,0,83.6,223a57.83,57.83,0,0,0,6.45-.36A60,60,0,0,0,190.9,208.17a60,60,0,0,0,38-94.56ZM181.81,58.3a36,36,0,0,1,26.58,37.6L170,73.73a12,12,0,0,0-12,0L120,95.67V81.81L154.5,61.9A35.74,35.74,0,0,1,181.81,58.3ZM136,123.38v9.24l-8,4.62-8-4.62v-9.24l8-4.62ZM84,72a36,36,0,0,1,56.39-29.67L102,64.49a12,12,0,0,0-6,10.39v43.88l-12-6.93ZM44.33,88.93A35.81,35.81,0,0,1,60,74.43v44.33a12,12,0,0,0,6,10.4l38,21.93L92,158,57.5,138.1A36,36,0,0,1,44.33,88.93ZM74.19,197.7a36,36,0,0,1-26.58-37.6L86,182.27a12,12,0,0,0,12,0l38-21.94v13.86L101.5,194.1A35.74,35.74,0,0,1,74.19,197.7ZM172,184a36,36,0,0,1-56.39,29.67L154,191.51a12,12,0,0,0,6-10.39V137.24l12,6.93Zm39.67-16.93A35.81,35.81,0,0,1,196,181.57V137.24a12,12,0,0,0-6-10.4l-38-21.93L164,98l34.5,19.92A36,36,0,0,1,211.67,167.07Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M96,174.19l56-32.33v36.95L107.5,204.5a48,48,0,0,1-66.4-64.08h0A48,48,0,0,0,51.5,148.5ZM204.5,107.5,160,81.81l-32,18.48,56,32.33V184a48,48,0,0,1-1.81,13h0A48,48,0,0,0,204.5,107.5ZM104,77.19,148.5,51.5a47.77,47.77,0,0,1,12.19-5v0A48,48,0,0,0,72,72v51.38l32,18.48Z" opacity="0.2"/><path d="M224.32,114.24a56,56,0,0,0-60.07-76.57A56,56,0,0,0,67.93,51.44a56,56,0,0,0-36.25,90.32A56,56,0,0,0,69,217,56.39,56.39,0,0,0,83.59,219a55.75,55.75,0,0,0,8.17-.61,56,56,0,0,0,96.31-13.78,56,56,0,0,0,36.25-90.32ZM182.85,54.43a40,40,0,0,1,28.56,48c-.95-.63-1.91-1.24-2.91-1.81L164,74.88a8,8,0,0,0-8,0l-44,25.41V81.81l40.5-23.38A39.76,39.76,0,0,1,182.85,54.43ZM144,137.24l-16,9.24-16-9.24V118.76l16-9.24,16,9.24ZM80,72a40,40,0,0,1,67.53-29c-1,.51-2,1-3,1.62L100,70.27a8,8,0,0,0-4,6.92V128l-16-9.24ZM40.86,86.93A39.75,39.75,0,0,1,64.12,68.57C64.05,69.71,64,70.85,64,72v51.38a8,8,0,0,0,4,6.93l44,25.4L96,165,55.5,141.57A40,40,0,0,1,40.86,86.93ZM73.15,201.57a40,40,0,0,1-28.56-48c.95.63,1.91,1.24,2.91,1.81L92,181.12a8,8,0,0,0,8,0l44-25.41v18.48l-40.5,23.38A39.76,39.76,0,0,1,73.15,201.57ZM176,184a40,40,0,0,1-67.52,29.05c1-.51,2-1.05,3-1.63L156,185.73a8,8,0,0,0,4-6.92V128l16,9.24Zm39.14-14.93a39.75,39.75,0,0,1-23.26,18.36c.07-1.14.12-2.28.12-3.43V132.62a8,8,0,0,0-4-6.93l-44-25.4,16-9.24,40.5,23.38A40,40,0,0,1,215.14,169.07Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M224.32,114.24a56,56,0,0,0-60.07-76.57A56,56,0,0,0,67.93,51.44a56,56,0,0,0-36.25,90.32A56,56,0,0,0,69,217,56.39,56.39,0,0,0,83.59,219a55.75,55.75,0,0,0,8.17-.61,56,56,0,0,0,96.31-13.78,56,56,0,0,0,36.25-90.32Zm-80.32,23-16,9.24-16-9.24V118.76l16-9.24,16,9.24Zm38.85-82.81a40,40,0,0,1,28.56,48c-.95-.63-1.91-1.24-2.91-1.81L164,74.88a8,8,0,0,0-8,0l-44,25.41V81.81l40.5-23.38A39.76,39.76,0,0,1,182.85,54.43Zm-142,32.5A39.75,39.75,0,0,1,64.12,68.57C64.05,69.71,64,70.85,64,72v51.38a8,8,0,0,0,4,6.93l44,25.4L96,165,55.5,141.57A40,40,0,0,1,40.86,86.93ZM136,224a39.79,39.79,0,0,1-27.52-10.95c1-.51,2-1.05,3-1.63L156,185.73a8,8,0,0,0,4-6.92V128l16,9.24V184A40,40,0,0,1,136,224Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M222,114.56a54,54,0,0,0-58.67-74.73,54,54,0,0,0-94,13.46A54,54,0,0,0,34,141.44a54,54,0,0,0,35.56,73.65A54.54,54.54,0,0,0,83.59,217a52.86,52.86,0,0,0,9.06-.78,54,54,0,0,0,94-13.46A54,54,0,0,0,222,114.56ZM183.37,52.5a42,42,0,0,1,29.21,53.14,54.84,54.84,0,0,0-5.08-3.33L163,76.62a6,6,0,0,0-6,0l-47,27.13V80.66l41.5-24A41.73,41.73,0,0,1,183.37,52.5ZM146,138.39l-18,10.39-18-10.39V117.61l18-10.39,18,10.39ZM78,72a42,42,0,0,1,72.92-28.43,56.18,56.18,0,0,0-5.42,2.74L101,72a6,6,0,0,0-3,5.19v54.27L78,119.92ZM39.13,85.93a41.75,41.75,0,0,1,27.22-20A55.09,55.09,0,0,0,66,72v51.38a6,6,0,0,0,3,5.2l47,27.13L96,167.26l-41.5-24A42,42,0,0,1,39.13,85.93ZM72.63,203.5a42,42,0,0,1-29.21-53.14,54.84,54.84,0,0,0,5.08,3.33L93,179.38a6,6,0,0,0,6,0l47-27.13v23.09l-41.5,24A41.73,41.73,0,0,1,72.63,203.5ZM178,184a42,42,0,0,1-72.92,28.43,56.18,56.18,0,0,0,5.42-2.74L155,184a6,6,0,0,0,3-5.19V124.54l20,11.54Zm38.87-13.93a41.75,41.75,0,0,1-27.22,20A55.09,55.09,0,0,0,190,184V132.62a6,6,0,0,0-3-5.2l-47-27.13,20-11.55,41.5,24A42,42,0,0,1,216.87,170.07Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M224.32,114.24a56,56,0,0,0-60.07-76.57A56,56,0,0,0,67.93,51.44a56,56,0,0,0-36.25,90.32A56,56,0,0,0,69,217,56.39,56.39,0,0,0,83.59,219a55.75,55.75,0,0,0,8.17-.61,56,56,0,0,0,96.31-13.78,56,56,0,0,0,36.25-90.32ZM182.85,54.43a40,40,0,0,1,28.56,48c-.95-.63-1.91-1.24-2.91-1.81L164,74.88a8,8,0,0,0-8,0l-44,25.41V81.81l40.5-23.38A39.76,39.76,0,0,1,182.85,54.43ZM144,137.24l-16,9.24-16-9.24V118.76l16-9.24,16,9.24ZM80,72a40,40,0,0,1,67.53-29c-1,.51-2,1-3,1.62L100,70.27a8,8,0,0,0-4,6.92V128l-16-9.24ZM40.86,86.93A39.75,39.75,0,0,1,64.12,68.57C64.05,69.71,64,70.85,64,72v51.38a8,8,0,0,0,4,6.93l44,25.4L96,165,55.5,141.57A40,40,0,0,1,40.86,86.93ZM73.15,201.57a40,40,0,0,1-28.56-48c.95.63,1.91,1.24,2.91,1.81L92,181.12a8,8,0,0,0,8,0l44-25.41v18.48l-40.5,23.38A39.76,39.76,0,0,1,73.15,201.57ZM176,184a40,40,0,0,1-67.52,29.05c1-.51,2-1.05,3-1.63L156,185.73a8,8,0,0,0,4-6.92V128l16,9.24Zm39.14-14.93a39.75,39.75,0,0,1-23.26,18.36c.07-1.14.12-2.28.12-3.43V132.62a8,8,0,0,0-4-6.93l-44-25.4,16-9.24,40.5,23.38A40,40,0,0,1,215.14,169.07Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M219.68,114.91A52,52,0,0,0,162.49,42,52,52,0,0,0,70.82,55.15a52,52,0,0,0-34.5,85.94A52,52,0,0,0,70,213.16,52.33,52.33,0,0,0,83.59,215a51.66,51.66,0,0,0,9.93-1,52,52,0,0,0,91.66-13.13,52,52,0,0,0,34.5-85.94Zm-35.8-64.34a44,44,0,0,1,29.66,58.36,51.84,51.84,0,0,0-7-4.89L162,78.35a4,4,0,0,0-4,0l-50,28.87V79.5L150.5,55A43.69,43.69,0,0,1,183.88,50.57Zm-35.88,89-20,11.54-20-11.54v-23.1l20-11.54,20,11.54ZM76,72a44,44,0,0,1,78.25-27.62A52.08,52.08,0,0,0,146.5,48L102,73.73a4,4,0,0,0-2,3.46v57.74L76,121.07ZM37.4,84.93A43.72,43.72,0,0,1,68.71,63.46,52.26,52.26,0,0,0,68,72v51.38a4,4,0,0,0,2,3.46l50,28.87L96,169.57,53.5,145A44,44,0,0,1,37.4,84.93Zm34.72,120.5a44,44,0,0,1-29.66-58.36,51.84,51.84,0,0,0,7,4.89L94,177.65a4,4,0,0,0,4,0l50-28.87V176.5L105.5,201A43.69,43.69,0,0,1,72.12,205.43ZM180,184a44,44,0,0,1-78.26,27.61A51.66,51.66,0,0,0,109.5,208L154,182.27a4,4,0,0,0,2-3.46V121.07l24,13.86Zm38.6-12.93a43.72,43.72,0,0,1-31.31,21.47A52.26,52.26,0,0,0,188,184V132.62a4,4,0,0,0-2-3.46l-50-28.87,24-13.86L202.5,111A44,44,0,0,1,218.6,171.07Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
