/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M181.36,114.73l-48,24A12,12,0,0,1,116,128V72a12,12,0,0,1,24,0v36.58l30.63-15.31a12,12,0,1,1,10.73,21.46Zm45.72,120.86a12,12,0,0,1-14.68-8.51C210,218.2,201.65,212,192,212s-18,6.2-20.4,15.08A12,12,0,0,1,160,236a11.77,11.77,0,0,1-3.09-.41,12,12,0,0,1-8.52-14.68,43.76,43.76,0,0,1,15.08-23,36,36,0,1,1,57,0,43.7,43.7,0,0,1,15.08,23A12,12,0,0,1,227.08,235.59ZM180,176a12,12,0,1,0,12-12A12,12,0,0,0,180,176Zm-62.52,35.35a84,84,0,1,1,93.87-93.87,12,12,0,1,0,23.81-3A108,108,0,1,0,114.51,235.16a11,11,0,0,0,1.5.1,12,12,0,0,0,1.47-23.91Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M224,128a95.6,95.6,0,0,1-9.09,40.82A24,24,0,1,0,191.5,200l0,0a33.15,33.15,0,0,0-29,17.6A96,96,0,1,1,224,128Z" opacity="0.2"/><path d="M136,72v43.05l36.42-18.21a8,8,0,0,1,7.16,14.31l-48,24A8,8,0,0,1,120,128V72a8,8,0,0,1,16,0Zm-8,144a88,88,0,1,1,88-88,8,8,0,0,0,16,0A104,104,0,1,0,128,232a8,8,0,0,0,0-16Zm103.73,5.94a8,8,0,1,1-15.46,4.11C213.44,215.42,203.46,208,192,208s-21.44,7.42-24.27,18.05A8,8,0,0,1,160,232a8.15,8.15,0,0,1-2.06-.27,8,8,0,0,1-5.67-9.79,40,40,0,0,1,17.11-23.32,32,32,0,1,1,45.23,0A40,40,0,0,1,231.73,221.94ZM176,176a16,16,0,1,0,16-16A16,16,0,0,0,176,176Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M136,72v43.06l36.42-18.22a8,8,0,1,1,7.16,14.32l-48,24A8,8,0,0,1,120,128V72a8,8,0,0,1,16,0Zm-8,144a88,88,0,1,1,88-88,8,8,0,0,0,16,0A104,104,0,1,0,128,232a8,8,0,0,0,0-16Zm86.62-17.38a32,32,0,1,0-45.24,0A40,40,0,0,0,152.27,222,8,8,0,0,0,160,232h64a8,8,0,0,0,7.73-10.06A40,40,0,0,0,214.62,198.62Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M134,72v46.29l39.32-19.66a6,6,0,0,1,5.36,10.74l-48,24A6,6,0,0,1,122,128V72a6,6,0,0,1,12,0Zm-6,146a90,90,0,1,1,90-90,6,6,0,0,0,12,0A102,102,0,1,0,128,230a6,6,0,0,0,0-12Zm101.8,4.46a6,6,0,0,1-11.6,3.08C215.14,214,204.37,206,192,206s-23.14,8-26.2,19.54A6,6,0,0,1,160,230a6.26,6.26,0,0,1-1.54-.2,6,6,0,0,1-4.26-7.34A38.09,38.09,0,0,1,172.72,199a30,30,0,1,1,38.56,0A38.09,38.09,0,0,1,229.8,222.46ZM174,176a18,18,0,1,0,18-18A18,18,0,0,0,174,176Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M136,72v43.05l36.42-18.21a8,8,0,0,1,7.16,14.31l-48,24A8,8,0,0,1,120,128V72a8,8,0,0,1,16,0Zm-8,144a88,88,0,1,1,88-88,8,8,0,0,0,16,0A104,104,0,1,0,128,232a8,8,0,0,0,0-16Zm103.73,5.94a8,8,0,1,1-15.46,4.11C213.44,215.42,203.46,208,192,208s-21.44,7.42-24.27,18.05A8,8,0,0,1,160,232a8.15,8.15,0,0,1-2.06-.27,8,8,0,0,1-5.67-9.79,40,40,0,0,1,17.11-23.32,32,32,0,1,1,45.23,0A40,40,0,0,1,231.73,221.94ZM176,176a16,16,0,1,0,16-16A16,16,0,0,0,176,176Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M132,72v49.53l42.21-21.11a4,4,0,1,1,3.58,7.16l-48,24A4.05,4.05,0,0,1,128,132a4,4,0,0,1-4-4V72a4,4,0,0,1,8,0Zm-4,148a92,92,0,1,1,92-92,4,4,0,0,0,8,0A100,100,0,1,0,128,228a4,4,0,0,0,0-8Zm99.86,3a4,4,0,1,1-7.73,2.05c-3.29-12.37-14.86-21-28.13-21s-24.84,8.65-28.14,21a4,4,0,0,1-3.86,3,3.7,3.7,0,0,1-1-.14,4,4,0,0,1-2.84-4.89A36.24,36.24,0,0,1,176.5,199.3a28,28,0,1,1,31,0A36.2,36.2,0,0,1,227.86,223ZM192,196a20,20,0,1,0-20-20A20,20,0,0,0,192,196Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
