import { template } from "@ember/template-compiler";
import styles from './tag.css';
import type { TOC } from '@ember/component/template-only';
interface TagSignature {
    Element: HTMLElement;
    Blocks: {
        default: [];
    };
}
const Tag: TOC<TagSignature> = template(`
  <span class={{styles.tag}} ...attributes>{{yield}}</span>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default Tag;
