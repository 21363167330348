/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M228,40V68a12,12,0,0,1-24,0V52H188a12,12,0,0,1,0-24h28A12,12,0,0,1,228,40ZM216,176a12,12,0,0,0-12,12v16H188a12,12,0,0,0,0,24h28a12,12,0,0,0,12-12V188A12,12,0,0,0,216,176ZM68,204H52V188a12,12,0,0,0-24,0v28a12,12,0,0,0,12,12H68a12,12,0,0,0,0-24ZM40,80A12,12,0,0,0,52,68V52H68a12,12,0,0,0,0-24H40A12,12,0,0,0,28,40V68A12,12,0,0,0,40,80Zm88,128a80,80,0,1,1,80-80A80.09,80.09,0,0,1,128,208Zm56-80a56,56,0,1,0-56,56A56.06,56.06,0,0,0,184,128Zm-80-24a16,16,0,1,0,16,16A16,16,0,0,0,104,104Zm48,0a16,16,0,1,0,16,16A16,16,0,0,0,152,104Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M192,128a64,64,0,1,1-64-64A64,64,0,0,1,192,128Z" opacity="0.2"/><path d="M224,40V76a8,8,0,0,1-16,0V48H180a8,8,0,0,1,0-16h36A8,8,0,0,1,224,40Zm-8,132a8,8,0,0,0-8,8v28H180a8,8,0,0,0,0,16h36a8,8,0,0,0,8-8V180A8,8,0,0,0,216,172ZM76,208H48V180a8,8,0,0,0-16,0v36a8,8,0,0,0,8,8H76a8,8,0,0,0,0-16ZM40,84a8,8,0,0,0,8-8V48H76a8,8,0,0,0,0-16H40a8,8,0,0,0-8,8V76A8,8,0,0,0,40,84Zm88,116a72,72,0,1,1,72-72A72.08,72.08,0,0,1,128,200Zm56-72a56,56,0,1,0-56,56A56.06,56.06,0,0,0,184,128Zm-68-12a12,12,0,1,0-12,12A12,12,0,0,0,116,116Zm36-12a12,12,0,1,0,12,12A12,12,0,0,0,152,104Zm-5.29,42c-3.81,3.37-12,6-18.71,6s-14.9-2.63-18.71-6a8,8,0,1,0-10.58,12c7.83,6.91,20.35,10,29.29,10s21.46-3.09,29.29-10a8,8,0,1,0-10.58-12Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M224,40V76a8,8,0,0,1-16,0V48H180a8,8,0,0,1,0-16h36A8,8,0,0,1,224,40Zm-8,132a8,8,0,0,0-8,8v28H180a8,8,0,0,0,0,16h36a8,8,0,0,0,8-8V180A8,8,0,0,0,216,172ZM76,208H48V180a8,8,0,0,0-16,0v36a8,8,0,0,0,8,8H76a8,8,0,0,0,0-16ZM40,84a8,8,0,0,0,8-8V48H76a8,8,0,0,0,0-16H40a8,8,0,0,0-8,8V76A8,8,0,0,0,40,84Zm88,116a72,72,0,1,1,72-72A72.08,72.08,0,0,1,128,200Zm-24-72a12,12,0,1,0-12-12A12,12,0,0,0,104,128Zm54,18.71a8,8,0,0,0-11.29-.71c-3.81,3.37-12,6-18.71,6s-14.9-2.63-18.71-6a8,8,0,1,0-10.58,12c7.83,6.91,20.35,10,29.29,10s21.46-3.09,29.29-10A8,8,0,0,0,158,146.71ZM164,116a12,12,0,1,0-12,12A12,12,0,0,0,164,116Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M222,40V76a6,6,0,0,1-12,0V46H180a6,6,0,0,1,0-12h36A6,6,0,0,1,222,40Zm-6,134a6,6,0,0,0-6,6v30H180a6,6,0,0,0,0,12h36a6,6,0,0,0,6-6V180A6,6,0,0,0,216,174ZM76,210H46V180a6,6,0,0,0-12,0v36a6,6,0,0,0,6,6H76a6,6,0,0,0,0-12ZM40,82a6,6,0,0,0,6-6V46H76a6,6,0,0,0,0-12H40a6,6,0,0,0-6,6V76A6,6,0,0,0,40,82Zm88,116a70,70,0,1,1,70-70A70.08,70.08,0,0,1,128,198Zm58-70a58,58,0,1,0-58,58A58.07,58.07,0,0,0,186,128Zm-72-12a10,10,0,1,0-10,10A10,10,0,0,0,114,116Zm38-10a10,10,0,1,0,10,10A10,10,0,0,0,152,106Zm-4,41.5c-4.2,3.71-12.81,6.5-20,6.5s-15.83-2.79-20-6.5a6,6,0,0,0-7.94,9c7.44,6.56,19.41,9.5,28,9.5s20.53-2.94,28-9.5a6,6,0,1,0-7.94-9Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M224,40V76a8,8,0,0,1-16,0V48H180a8,8,0,0,1,0-16h36A8,8,0,0,1,224,40Zm-8,132a8,8,0,0,0-8,8v28H180a8,8,0,0,0,0,16h36a8,8,0,0,0,8-8V180A8,8,0,0,0,216,172ZM76,208H48V180a8,8,0,0,0-16,0v36a8,8,0,0,0,8,8H76a8,8,0,0,0,0-16ZM40,84a8,8,0,0,0,8-8V48H76a8,8,0,0,0,0-16H40a8,8,0,0,0-8,8V76A8,8,0,0,0,40,84Zm88,116a72,72,0,1,1,72-72A72.08,72.08,0,0,1,128,200Zm56-72a56,56,0,1,0-56,56A56.06,56.06,0,0,0,184,128Zm-68-12a12,12,0,1,0-12,12A12,12,0,0,0,116,116Zm36-12a12,12,0,1,0,12,12A12,12,0,0,0,152,104Zm-5.29,42c-3.81,3.37-12,6-18.71,6s-14.9-2.63-18.71-6a8,8,0,1,0-10.58,12c7.83,6.91,20.35,10,29.29,10s21.46-3.09,29.29-10a8,8,0,1,0-10.58-12Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M220,40V76a4,4,0,0,1-8,0V44H180a4,4,0,0,1,0-8h36A4,4,0,0,1,220,40Zm-4,136a4,4,0,0,0-4,4v32H180a4,4,0,0,0,0,8h36a4,4,0,0,0,4-4V180A4,4,0,0,0,216,176ZM76,212H44V180a4,4,0,0,0-8,0v36a4,4,0,0,0,4,4H76a4,4,0,0,0,0-8ZM40,80a4,4,0,0,0,4-4V44H76a4,4,0,0,0,0-8H40a4,4,0,0,0-4,4V76A4,4,0,0,0,40,80Zm88,116a68,68,0,1,1,68-68A68.07,68.07,0,0,1,128,196Zm60-68a60,60,0,1,0-60,60A60.07,60.07,0,0,0,188,128Zm-76-12a8,8,0,1,0-8,8A8,8,0,0,0,112,116Zm40-8a8,8,0,1,0,8,8A8,8,0,0,0,152,108Zm-2.65,41c-4.52,4-13.7,7-21.35,7s-16.83-3-21.35-7a4,4,0,0,0-5.3,6c6,5.3,17,9,26.65,9s20.64-3.7,26.65-9a4,4,0,1,0-5.3-6Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
