/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M207.83,56.53C186.32,43.29,158,36,128,36S69.68,43.29,48.17,56.53C24.85,70.89,12,90.6,12,112s12.85,41.14,36.17,55.5c18.48,11.37,42,18.34,67.29,20.08-2,11.07-9.09,17.75-15.22,21.54-13.48,8.31-32.75,9.18-46.86,2.1A12,12,0,1,0,42.62,232.7a71.76,71.76,0,0,0,32,7.3,73.2,73.2,0,0,0,38.18-10.43c15.45-9.54,25-24.58,26.83-41.9,25.6-1.64,49.47-8.65,68.16-20.15C231.15,153.16,244,133.45,244,112S231.15,70.89,207.83,56.53Zm-134.44,97a21,21,0,0,1,20.16-9.35c10.36,1.39,16.54,9.43,19.72,19.13A135.3,135.3,0,0,1,73.39,153.56Zm64.87,10.14a61.84,61.84,0,0,0-10.76-24.82,46.08,46.08,0,0,0-30.75-18.46c-18-2.41-34.52,5.89-44.1,21C42.2,133,36,122.84,36,112c0-28.19,42.13-52,92-52s92,23.82,92,52C220,138.26,183.51,160.71,138.26,163.7Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M232,112c0,35.35-46.56,64-104,64S24,147.35,24,112,70.56,48,128,48,232,76.65,232,112Z" opacity="0.2"/><path d="M205.73,59.93C184.85,47.08,157.24,40,128,40S71.15,47.08,50.27,59.93C28.17,73.52,16,92,16,112S28.17,150.44,50.27,164c19,11.67,43.49,18.56,69.73,19.73v0a37.35,37.35,0,0,1-18.58,33c-14.64,8.86-34.62,9.52-49.72,1.64a8,8,0,1,0-7.4,14.18A66.4,66.4,0,0,0,75,240a67.31,67.31,0,0,0,34.74-9.5c17-10.27,26.29-26.86,26.29-46.7v0c26.24-1.17,50.76-8.06,69.73-19.73C227.83,150.44,240,132,240,112S227.83,73.52,205.73,59.93ZM67.41,155.18c5.24-9.55,15.45-12,23.53-11,10.9,1.42,21.86,9.13,26.61,23.42C99.11,166.45,81.85,162.16,67.41,155.18Zm129.94-4.77c-16.95,10.43-39.17,16.53-63.13,17.43a54.37,54.37,0,0,0-11.39-23.07A47.17,47.17,0,0,0,93,128.35c-17-2.2-31.72,5.11-39.38,18.7C39.64,137,32,124.73,32,112c0-14.21,9.47-27.86,26.65-38.43C77.05,62.23,101.68,56,128,56S179,62.23,197.35,73.55C214.53,84.12,224,97.77,224,112S214.53,139.84,197.35,150.41Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M93.43,136.11a17.14,17.14,0,0,0-15.35,6.1c9.31,6.56,22.51,11.33,37.43,13.07C110.41,143.33,101.72,137,93.43,136.11Z"/><path d="M224,48V208a16,16,0,0,1-16,16H127.65a4,4,0,0,1-3.23-6.34c7.5-10.23,11.58-23.24,11.58-37.84,0-2.79-.13-5.46-.35-8.05C176.79,169.33,208,147.47,208,120c0-29.15-35.14-52-80-52S48,90.84,48,120c0,27.31,30.82,49.07,71.58,51.73a77,77,0,0,1,.42,8.09c0,17.62-7.65,31.95-21,39.32A38.77,38.77,0,0,1,79.27,224H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32H208A16,16,0,0,1,224,48Zm-32,72c0-19.51-29.31-36-64-36s-64,16.48-64,36a21.29,21.29,0,0,0,3,10.63A33.65,33.65,0,0,1,95.16,120.2c15,1.63,30.84,13.4,37.54,35.68C165.3,154.47,192,138.62,192,120Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M204.68,61.63C184.11,49,156.88,42,128,42S71.89,49,51.32,61.63C29.83,74.85,18,92.73,18,112s11.83,37.13,33.32,50.35C70.45,174.1,95.35,181,122,181.85c0,.64,0,1.28,0,1.94,0,14.76-6.94,27.1-19.54,34.73-15.22,9.22-36,9.9-51.68,1.71a6,6,0,1,0-5.56,10.63A64.45,64.45,0,0,0,75,238a65.24,65.24,0,0,0,33.7-9.22c16.34-9.89,25.33-25.87,25.33-45,0-.65,0-1.29,0-1.94,26.65-.89,51.57-7.74,70.72-19.52C226.17,149.11,238,131.23,238,112S226.17,74.85,204.68,61.63Zm-140,94.46c5.32-12,17.2-15.07,26.48-13.87,12.28,1.6,24.64,10.67,29.08,27.55C99.73,168.81,80.48,164.08,64.72,156.09Zm133.67-4c-17.62,10.85-40.83,17.1-65.77,17.79a52.77,52.77,0,0,0-11.31-23.84,45.24,45.24,0,0,0-28.56-15.73c-16.89-2.2-31.49,5.54-38.41,19.68C38.61,139.37,30,126,30,112c0-14.93,9.8-29.18,27.61-40.13C76.32,60.34,101.32,54,128,54s51.68,6.34,70.39,17.85C216.2,82.8,226,97.05,226,112S216.2,141.16,198.39,152.11Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M205.73,59.93C184.85,47.08,157.24,40,128,40S71.15,47.08,50.27,59.93C28.17,73.52,16,92,16,112S28.17,150.44,50.27,164c19,11.67,43.49,18.56,69.73,19.73v0a37.35,37.35,0,0,1-18.58,33c-14.64,8.86-34.62,9.52-49.72,1.64a8,8,0,1,0-7.4,14.18A66.4,66.4,0,0,0,75,240a67.31,67.31,0,0,0,34.74-9.5c17-10.27,26.29-26.86,26.29-46.7v0c26.24-1.17,50.76-8.06,69.73-19.73C227.83,150.44,240,132,240,112S227.83,73.52,205.73,59.93ZM67.41,155.18c5.24-9.55,15.44-12,23.53-11,10.9,1.42,21.86,9.13,26.61,23.42C99.11,166.45,81.85,162.16,67.41,155.18Zm129.94-4.77c-16.95,10.43-39.17,16.53-63.13,17.43a54.37,54.37,0,0,0-11.39-23.07A47.17,47.17,0,0,0,93,128.35c-17-2.2-31.72,5.11-39.38,18.7C39.64,137,32,124.73,32,112c0-14.21,9.47-27.86,26.65-38.43C77.05,62.23,101.68,56,128,56S179,62.23,197.35,73.55C214.53,84.12,224,97.77,224,112S214.53,139.84,197.35,150.41Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M203.64,63.34C183.37,50.87,156.51,44,128,44S72.63,50.87,52.36,63.34C31.49,76.19,20,93.48,20,112s11.49,35.83,32.36,48.68c19.29,11.87,44.55,18.65,71.51,19.28.08,1.27.13,2.56.13,3.9,0,15.49-7.28,28.44-20.51,36.46-15.79,9.57-37.34,10.28-53.64,1.77a4,4,0,1,0-3.7,7.09A62.32,62.32,0,0,0,74.91,236a63.56,63.56,0,0,0,32.73-8.82c15.71-9.52,24.36-24.9,24.36-43.3,0-1.32-.05-2.61-.12-3.9,27.06-.59,52.41-7.38,71.76-19.28C224.51,147.85,236,130.56,236,112S224.51,76.19,203.64,63.34ZM62.16,157.15c5.3-14.49,18.83-18.22,29.3-16.85,13.64,1.78,27.38,12.19,31.38,31.66C99.66,171.25,78.55,165.78,62.16,157.15ZM131,172a51.35,51.35,0,0,0-11.18-24.59,43.28,43.28,0,0,0-27.3-15.06c-16.82-2.19-31.24,6-37.33,20.74C38.33,142.35,28,127.9,28,112c0-33.09,44.86-60,100-60s100,26.93,100,60C228,144.52,184.74,171.06,131,172Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
