/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M128,20A108,108,0,1,0,236,128,108.12,108.12,0,0,0,128,20Zm83.65,100.47C188.51,136,170.71,133.74,151.92,126c7.87-6,15.29-12.73,21.35-21.16A74.85,74.85,0,0,0,187,68.25,83.73,83.73,0,0,1,211.65,120.47ZM163.3,51.8c1.9,27.81-9,42.09-25.09,54.48-1.24-9.82-3.38-19.61-7.64-29.08A75,75,0,0,0,105.69,47,83.73,83.73,0,0,1,163.3,51.8ZM79.69,59.35c25,12.25,31.93,28.8,34.6,48.94-9.12-3.82-18.66-6.87-29-7.91a75,75,0,0,0-38.59,6.46A84.2,84.2,0,0,1,79.69,59.35ZM44.35,135.53C67.49,120,85.29,122.26,104.08,130c-7.87,6-15.29,12.73-21.35,21.16A74.85,74.85,0,0,0,69,187.75,83.73,83.73,0,0,1,44.35,135.53ZM92.7,204.2c-1.9-27.81,9-42.09,25.09-54.48,1.24,9.82,3.38,19.61,7.64,29.08A75,75,0,0,0,150.31,209,83.73,83.73,0,0,1,92.7,204.2Zm83.61-7.55c-25-12.25-31.93-28.8-34.6-48.94,9.12,3.82,18.66,6.87,29,7.91q3.75.38,7.47.38a76,76,0,0,0,31.12-6.85A84.19,84.19,0,0,1,176.31,196.65Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M128,128c-28.36-14.12-56.73-28.24-96,1.61,0-.53,0-1.07,0-1.61A96,96,0,0,1,78.6,45.67C124.09,64.75,126,96.38,128,128ZM81.4,212a96,96,0,0,0,94.6-.81c.47-.27.94-.53,1.4-.81C131.91,191.25,130,159.62,128,128,101.59,145.5,75.18,163,81.4,212ZM224,126.39a96,96,0,0,0-48-81.53l-1.4-.81C180.82,93,154.41,110.5,128,128,156.36,142.12,184.73,156.24,224,126.39Z" opacity="0.2"/><path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm87.82,98.46c-28.34,20-49.57,14.68-71.87,4.39,20.07-14.19,38.86-32.21,39.53-67.11A87.92,87.92,0,0,1,215.82,122.46ZM167.11,49.19C170.24,83.71,155,99.44,135,113.61c-2.25-24.48-8.44-49.8-38.37-67.82a87.89,87.89,0,0,1,70.5,3.4ZM79.32,54.73c31.45,14.55,37.47,35.58,39.71,60-22.33-10.29-47.35-17.59-77.93-.68A88.18,88.18,0,0,1,79.32,54.73ZM40.18,133.54c28.34-20,49.57-14.68,71.87-4.39C92,143.34,73.19,161.36,72.52,196.26A87.92,87.92,0,0,1,40.18,133.54Zm48.71,73.27C85.76,172.29,101,156.56,121,142.39c2.25,24.48,8.44,49.8,38.37,67.82a87.89,87.89,0,0,1-70.5-3.4Zm87.79-5.54c-31.45-14.55-37.47-35.58-39.71-60,12.72,5.86,26.31,10.75,41.3,10.75,11.33,0,23.46-2.8,36.63-10.08A88.2,88.2,0,0,1,176.68,201.27Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm39.11,25.19C170.24,83.71,155,99.44,135,113.61c-2.25-24.48-8.44-49.8-38.37-67.82a87.89,87.89,0,0,1,70.5,3.4ZM40.18,133.54c28.34-20,49.57-14.68,71.87-4.39C92,143.34,73.19,161.36,72.52,196.26A87.92,87.92,0,0,1,40.18,133.54Zm136.5,67.73c-31.45-14.55-37.47-35.58-39.71-60,12.72,5.86,26.31,10.75,41.3,10.75,11.33,0,23.46-2.8,36.63-10.08A88.2,88.2,0,0,1,176.68,201.27Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M128,26A102,102,0,1,0,230,128,102.12,102.12,0,0,0,128,26Zm89.89,97.44c-31,22.37-53.66,15.36-77.93,3.75,21.43-14.75,42.27-33.21,41.51-71.54A90,90,0,0,1,217.89,123.44ZM169,47.89c3.84,38-13.53,54.13-35.71,69.34C131.22,91.29,125.67,64,92.06,45.5A89.88,89.88,0,0,1,169,47.89ZM79.14,52.45C114,68.15,119.23,91.23,121.32,118c-23.5-11.18-49.92-20-82.75-.17A90.12,90.12,0,0,1,79.14,52.45Zm-41,80.11c31.05-22.37,53.66-15.36,77.93-3.75C94.61,143.56,73.77,162,74.53,200.35A90,90,0,0,1,38.11,132.56ZM87,208.11c-3.84-38,13.53-54.13,35.71-69.34,2.06,25.94,7.61,53.24,41.22,71.73A89.88,89.88,0,0,1,87,208.11Zm89.85-4.56C142,187.85,136.77,164.77,134.68,138c13.36,6.36,27.67,12,43.56,12,12.06,0,25-3.23,39.19-11.79A90.15,90.15,0,0,1,176.86,203.55Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm87.82,98.46c-28.34,20-49.57,14.68-71.87,4.39,20.06-14.19,38.86-32.21,39.53-67.11A87.92,87.92,0,0,1,215.82,122.46ZM167.11,49.19C170.24,83.71,155,99.44,135,113.61c-2.25-24.48-8.44-49.8-38.37-67.82a87.89,87.89,0,0,1,70.5,3.4ZM79.32,54.73c31.45,14.55,37.47,35.58,39.71,60-22.33-10.29-47.35-17.59-77.93-.68A88.18,88.18,0,0,1,79.32,54.73ZM40.18,133.54c28.34-20,49.57-14.68,71.87-4.39C92,143.34,73.19,161.36,72.52,196.26A87.92,87.92,0,0,1,40.18,133.54Zm48.71,73.27C85.76,172.29,101,156.56,121,142.39c2.25,24.48,8.44,49.8,38.37,67.82a87.89,87.89,0,0,1-70.5-3.4Zm87.79-5.54c-31.45-14.55-37.47-35.58-39.71-60,12.72,5.86,26.31,10.75,41.3,10.75,11.33,0,23.46-2.8,36.63-10.08A88.2,88.2,0,0,1,176.68,201.27Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm91.92,96.44c-33.69,24.71-58.55,15.45-83.94,3,23.54-15.86,45.85-33.95,43.34-75.81A92,92,0,0,1,219.92,124.44ZM170.86,46.61C175.4,88.13,155,105,131.55,120.83c-2-28.32-6.47-56.69-44-75.45a91.87,91.87,0,0,1,83.3,1.23ZM79,50.18c38.22,16.83,42.63,43,44.58,71.16-25.5-12.46-52.33-22.75-87.34.38A92.07,92.07,0,0,1,79,50.18Zm-42.9,81.38c33.69-24.71,58.55-15.45,83.94-3.05-23.54,15.86-45.85,34-43.34,75.81A92,92,0,0,1,36.08,131.56Zm49.06,77.83C80.6,167.87,101,151,124.45,135.17c2,28.32,6.47,56.69,44,75.45a91.87,91.87,0,0,1-83.3-1.23ZM177,205.82c-38.22-16.83-42.63-43-44.58-71.16,14.43,7.05,29.29,13.41,45.81,13.41,12.67,0,26.33-3.74,41.53-13.78A92.06,92.06,0,0,1,177,205.82Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
