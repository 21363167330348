import { template } from "@ember/template-compiler";
import { on } from '@ember/modifier';
import { buildArtLink } from '..';
import type { Art } from '..';
import type { TOC } from '@ember/component/template-only';
export interface ArtTreeSignature {
    Args: {
        arts: Art[];
    };
}
type ArtNode = {
    item: Art;
    children: ArtNode[];
};
function listToTree(items1: Art[], parent1?: Art): ArtNode[] {
    return items1.filter((art1)=>art1.parent?.id === parent1?.id).map((art1)=>({
            item: art1,
            children: listToTree(items1, art1)
        }));
}
const Tree: TOC<{
    Args: {
        nodes: ArtNode[];
    };
}> = template(`
  <ul>
    {{#each @nodes as |node|}}
      <li>
        {{#let (buildArtLink node.item._sys.filename) as |l|}}
          <a href={{l.url}} {{on 'click' l.transitionTo}}>
            {{node.item.title}}
          </a>
        {{/let}}

        {{#if node.children}}
          <Tree @nodes={{node.children}} />
        {{/if}}
      </li>
    {{/each}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const ArtTree: TOC<ArtTreeSignature> = template(`<Tree @nodes={{listToTree @arts}} />`, {
    eval () {
        return eval(arguments[0]);
    }
});
export { ArtTree };
