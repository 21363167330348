/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M224,108H32a12,12,0,0,0-12,12,108.38,108.38,0,0,0,56,94.68V216a20,20,0,0,0,20,20h64a20,20,0,0,0,20-20v-1.32A108.38,108.38,0,0,0,236,120,12,12,0,0,0,224,108Zm-61,88.39a12,12,0,0,0-7,10.9V212H100v-4.71a12,12,0,0,0-7-10.9A84.32,84.32,0,0,1,44.87,132H211.13A84.32,84.32,0,0,1,163,196.39ZM74.93,50.15a26.32,26.32,0,0,0,4.71-7,12,12,0,0,1,7.7-22.7c5.67,1.64,13.2,6.63,15.77,15.3,2.71,9.14-.67,19.26-10,30.08a26.32,26.32,0,0,0-4.71,7A12,12,0,0,1,84,96a11.81,11.81,0,0,1-3.33-.47C75,93.89,67.46,88.9,64.89,80.23,62.18,71.09,65.56,61,74.93,50.15Zm44,0a26.32,26.32,0,0,0,4.71-7,12,12,0,0,1,7.7-22.7c5.67,1.64,13.2,6.63,15.77,15.3,2.71,9.14-.67,19.26-10,30.08a26.32,26.32,0,0,0-4.71,7A12,12,0,0,1,128,96a11.81,11.81,0,0,1-3.33-.47c-5.67-1.64-13.21-6.63-15.78-15.3C106.18,71.09,109.56,61,118.93,50.15Zm44,0a26.32,26.32,0,0,0,4.71-7,12,12,0,0,1,7.7-22.7c5.67,1.64,13.2,6.63,15.77,15.3,2.71,9.14-.67,19.26-10,30.08a26.32,26.32,0,0,0-4.71,7A12,12,0,0,1,172,96a11.81,11.81,0,0,1-3.33-.47c-5.67-1.64-13.21-6.63-15.78-15.3C150.18,71.09,153.56,61,162.93,50.15Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M224,120a96,96,0,0,1-56,87.3V216a8,8,0,0,1-8,8H96a8,8,0,0,1-8-8v-8.7A96,96,0,0,1,32,120Z" opacity="0.2"/><path d="M81.77,55c5.35-6.66,6.67-11.16,6.12-13.14-.42-1.49-2.41-2.26-2.43-2.26A8,8,0,0,1,88,24a8.11,8.11,0,0,1,2.38.36c1,.31,9.91,3.33,12.79,12.76,2.46,8.07-.55,17.45-8.94,27.89-5.35,6.66-6.67,11.16-6.12,13.14.42,1.49,2.37,2.24,2.39,2.25A8,8,0,0,1,88,96a8.11,8.11,0,0,1-2.38-.36c-1-.31-9.91-3.33-12.79-12.76C70.37,74.81,73.38,65.43,81.77,55Zm31.06,27.89c2.88,9.43,11.79,12.45,12.79,12.76A8.11,8.11,0,0,0,128,96a8,8,0,0,0,2.5-15.6s-2-.76-2.39-2.25c-.55-2,.77-6.48,6.12-13.14,8.39-10.44,11.4-19.82,8.94-27.89-2.88-9.43-11.78-12.45-12.79-12.76A8.11,8.11,0,0,0,128,24a8,8,0,0,0-2.54,15.59s2,.77,2.43,2.26c.55,2-.77,6.48-6.12,13.14C113.38,65.43,110.37,74.81,112.83,82.88Zm40,0c2.88,9.43,11.79,12.45,12.79,12.76A8.11,8.11,0,0,0,168,96a8,8,0,0,0,2.5-15.6s-2-.76-2.39-2.25c-.55-2,.77-6.48,6.12-13.14,8.39-10.44,11.4-19.82,8.94-27.89-2.88-9.43-11.78-12.45-12.79-12.76A8.11,8.11,0,0,0,168,24a8,8,0,0,0-2.54,15.59s2,.77,2.43,2.26c.55,2-.77,6.48-6.12,13.14C153.38,65.43,150.37,74.81,152.83,82.88ZM232,120a104.35,104.35,0,0,1-56,92.28V216a16,16,0,0,1-16,16H96a16,16,0,0,1-16-16v-3.72A104.35,104.35,0,0,1,24,120a8,8,0,0,1,8-8H224A8,8,0,0,1,232,120Zm-16.37,8H40.37a88.29,88.29,0,0,0,51,72A8,8,0,0,1,96,207.29V216h64v-8.71a8,8,0,0,1,4.66-7.27A88.29,88.29,0,0,0,215.63,128Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M81.77,55c5.35-6.66,6.67-11.16,6.12-13.14-.42-1.49-2.41-2.26-2.43-2.26A8,8,0,0,1,88,24a8.11,8.11,0,0,1,2.38.36c1,.31,9.91,3.33,12.79,12.76,2.46,8.07-.55,17.45-8.94,27.89-5.35,6.66-6.67,11.16-6.12,13.14.42,1.49,2.37,2.24,2.39,2.25A8,8,0,0,1,88,96a8.11,8.11,0,0,1-2.38-.36c-1-.31-9.91-3.33-12.79-12.76C70.37,74.81,73.38,65.43,81.77,55Zm31.06,27.89c2.88,9.43,11.79,12.45,12.79,12.76A8.11,8.11,0,0,0,128,96a8,8,0,0,0,2.5-15.6s-2-.76-2.39-2.25c-.55-2,.77-6.48,6.12-13.14,8.39-10.44,11.4-19.82,8.94-27.89-2.88-9.43-11.78-12.45-12.79-12.76A8.11,8.11,0,0,0,128,24a8,8,0,0,0-2.54,15.59s2,.77,2.43,2.26c.55,2-.77,6.48-6.12,13.14C113.38,65.43,110.37,74.81,112.83,82.88Zm40,0c2.88,9.43,11.79,12.45,12.79,12.76A8.11,8.11,0,0,0,168,96a8,8,0,0,0,2.5-15.6s-2-.76-2.39-2.25c-.55-2,.77-6.48,6.12-13.14,8.39-10.44,11.4-19.82,8.94-27.89-2.88-9.43-11.78-12.45-12.79-12.76A8.11,8.11,0,0,0,168,24a8,8,0,0,0-2.54,15.59s2,.77,2.43,2.26c.55,2-.77,6.48-6.12,13.14C153.38,65.43,150.37,74.81,152.83,82.88ZM224,112H32a8,8,0,0,0-8,8,104.35,104.35,0,0,0,56,92.28V216a16,16,0,0,0,16,16h64a16,16,0,0,0,16-16v-3.72A104.35,104.35,0,0,0,232,120,8,8,0,0,0,224,112Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M224,114H32a6,6,0,0,0-6,6,102.35,102.35,0,0,0,56,91.06V216a14,14,0,0,0,14,14h64a14,14,0,0,0,14-14v-4.94A102.35,102.35,0,0,0,230,120,6,6,0,0,0,224,114Zm-58.5,87.84a6,6,0,0,0-3.5,5.45V216a2,2,0,0,1-2,2H96a2,2,0,0,1-2-2v-8.71a6,6,0,0,0-3.5-5.45A90.35,90.35,0,0,1,38.2,126H217.8A90.35,90.35,0,0,1,165.5,201.84ZM83.32,56.24c5.05-6.28,7.41-11.73,6.48-15-.72-2.53-3.64-3.57-3.67-3.58A6,6,0,0,1,88,26a5.81,5.81,0,0,1,1.78.27c.91.28,8.9,3,11.48,11.44,2.26,7.39-.63,16.15-8.58,26.05-5.05,6.28-7.41,11.73-6.48,15,.72,2.53,3.64,3.57,3.67,3.58A6,6,0,0,1,88,94a5.81,5.81,0,0,1-1.78-.27c-.91-.28-8.9-3-11.48-11.44C72.48,74.9,75.37,66.14,83.32,56.24Zm40,0c5.05-6.28,7.41-11.73,6.48-15-.72-2.53-3.64-3.57-3.67-3.58A6,6,0,0,1,128,26a5.81,5.81,0,0,1,1.78.27c.91.28,8.9,3,11.48,11.44,2.26,7.39-.63,16.15-8.58,26.05-5.05,6.28-7.41,11.73-6.48,15,.72,2.53,3.64,3.57,3.67,3.58A6,6,0,0,1,128,94a5.81,5.81,0,0,1-1.78-.27c-.91-.28-8.9-3-11.48-11.44C112.48,74.9,115.37,66.14,123.32,56.24Zm40,0c5.05-6.28,7.41-11.73,6.48-15-.72-2.53-3.64-3.57-3.67-3.58A6,6,0,0,1,168,26a5.81,5.81,0,0,1,1.78.27c.91.28,8.9,3,11.48,11.44,2.26,7.39-.63,16.15-8.58,26.05-5.05,6.28-7.41,11.73-6.48,15,.72,2.53,3.64,3.57,3.67,3.58A6,6,0,0,1,168,94a5.81,5.81,0,0,1-1.78-.27c-.91-.28-8.9-3-11.48-11.44C152.48,74.9,155.37,66.14,163.32,56.24Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M224,112H32a8,8,0,0,0-8,8,104.35,104.35,0,0,0,56,92.28V216a16,16,0,0,0,16,16h64a16,16,0,0,0,16-16v-3.72A104.35,104.35,0,0,0,232,120,8,8,0,0,0,224,112Zm-59.34,88a8,8,0,0,0-4.66,7.27V216H96v-8.71A8,8,0,0,0,91.34,200a88.29,88.29,0,0,1-51-72H215.63A88.29,88.29,0,0,1,164.66,200ZM81.77,55c5.35-6.66,6.67-11.16,6.12-13.14-.42-1.49-2.41-2.26-2.43-2.26A8,8,0,0,1,88,24a8.11,8.11,0,0,1,2.38.36c1,.31,9.91,3.33,12.79,12.76,2.46,8.07-.55,17.45-8.94,27.89-5.35,6.66-6.67,11.16-6.12,13.14.42,1.49,2.37,2.24,2.39,2.25A8,8,0,0,1,88,96a8.11,8.11,0,0,1-2.38-.36c-1-.31-9.91-3.33-12.79-12.76C70.37,74.81,73.38,65.43,81.77,55Zm40,0c5.35-6.66,6.67-11.16,6.12-13.14-.42-1.49-2.41-2.26-2.43-2.26A8,8,0,0,1,128,24a8.11,8.11,0,0,1,2.38.36c1,.31,9.91,3.33,12.79,12.76,2.46,8.07-.55,17.45-8.94,27.89-5.35,6.66-6.67,11.16-6.12,13.14.42,1.49,2.37,2.24,2.39,2.25A8,8,0,0,1,128,96a8.11,8.11,0,0,1-2.38-.36c-1-.31-9.91-3.33-12.79-12.76C110.37,74.81,113.38,65.43,121.77,55Zm40,0c5.35-6.66,6.67-11.16,6.12-13.14-.42-1.49-2.41-2.26-2.43-2.26A8,8,0,0,1,168,24a8.11,8.11,0,0,1,2.38.36c1,.31,9.91,3.33,12.79,12.76,2.46,8.07-.55,17.45-8.94,27.89-5.35,6.66-6.67,11.16-6.12,13.14.42,1.49,2.37,2.24,2.39,2.25A8,8,0,0,1,168,96a8.11,8.11,0,0,1-2.38-.36c-1-.31-9.91-3.33-12.79-12.76C150.37,74.81,153.38,65.43,161.77,55Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M224,116H32a4,4,0,0,0-4,4,100.32,100.32,0,0,0,56,89.82V216a12,12,0,0,0,12,12h64a12,12,0,0,0,12-12v-6.18A100.32,100.32,0,0,0,228,120,4,4,0,0,0,224,116Zm-57.67,87.66A4,4,0,0,0,164,207.3V216a4,4,0,0,1-4,4H96a4,4,0,0,1-4-4v-8.7a4,4,0,0,0-2.33-3.64A92.31,92.31,0,0,1,36.09,124H219.91A92.31,92.31,0,0,1,166.33,203.66ZM164.88,57.5c5.56-6.92,8-12.89,6.83-16.81-1-3.54-4.9-4.87-4.94-4.88a4,4,0,0,1,2.42-7.63c.32.1,7.84,2.52,10.16,10.11,2.05,6.72-.72,14.87-8.23,24.22-5.56,6.91-8,12.88-6.83,16.8,1,3.54,4.9,4.87,4.94,4.88A4,4,0,0,1,168,92a4.09,4.09,0,0,1-1.19-.18c-.32-.1-7.84-2.52-10.16-10.11C154.6,75,157.37,66.84,164.88,57.5Zm-40,0c5.56-6.92,8-12.89,6.83-16.81-1-3.54-4.9-4.87-4.94-4.88a4,4,0,0,1,2.42-7.63c.32.1,7.84,2.52,10.16,10.11,2.05,6.72-.72,14.87-8.23,24.22-5.56,6.91-8,12.88-6.83,16.8,1,3.54,4.9,4.87,4.94,4.88A4,4,0,0,1,128,92a4.09,4.09,0,0,1-1.19-.18c-.32-.1-7.84-2.52-10.16-10.11C114.6,75,117.37,66.84,124.88,57.5Zm-40,0c5.56-6.92,8-12.89,6.83-16.81-1-3.54-4.9-4.87-4.94-4.88a4,4,0,0,1,2.42-7.63c.32.1,7.84,2.52,10.16,10.11,2.05,6.72-.72,14.87-8.23,24.22-5.56,6.91-8,12.88-6.83,16.8,1,3.54,4.9,4.87,4.94,4.88A4,4,0,0,1,88,92a4.09,4.09,0,0,1-1.19-.18c-.32-.1-7.84-2.52-10.16-10.11C74.6,75,77.37,66.84,84.88,57.5Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
