import { template } from "@ember/template-compiler";
import styles from './video-player.css';
import type { TOC } from '@ember/component/template-only';
const parseUrl = (url1: string)=>{
    const embedUrl1 = new URL('https://www.youtube.com');
    const u1 = new URL(url1);
    const id1 = u1.searchParams.has('v') ? u1.searchParams.get('v') : u1.pathname;
    embedUrl1.pathname = `embed/${id1}`;
    if (u1.searchParams.has('t')) {
        embedUrl1.searchParams.set('start', u1.searchParams.get('t') as string);
    }
    return embedUrl1.toString();
};
const VideoPlayer: TOC<{
    Element: HTMLIFrameElement;
    Args: {
        url: string;
    };
}> = template(`
  <iframe
    class={{styles.player}}
    src={{parseUrl @url}}
    title='YouTube video player'
    frameborder='0'
    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
    allowfullscreen
    ...attributes
  ></iframe>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export { VideoPlayer };
