/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M200,144H129l21.24-21.25a60.11,60.11,0,0,0,24,5.22,51.69,51.69,0,0,0,27-7.54c24-14.57,37-47.79,34.54-88.87a12,12,0,0,0-11.27-11.27c-41.09-2.41-74.31,10.49-88.87,34.54-9.24,15.24-10,33.4-2.33,51L120,119l-11.14-11.13c4.94-12.89,4-26.12-2.76-37.36C95,52.25,70.12,42.4,39.49,44.21A12,12,0,0,0,28.21,55.48c-1.8,30.64,8,55.54,26.32,66.61a40.27,40.27,0,0,0,21,5.9,45.58,45.58,0,0,0,16.31-3.13L103,136l-8,8H56a12,12,0,0,0,0,24h7.27l11.63,52.34A19.89,19.89,0,0,0,94.42,236h67.17a19.88,19.88,0,0,0,19.52-15.66L192.74,168H200a12,12,0,0,0,0-24ZM156.1,67.26C164.8,52.89,185.29,44.5,212,44c-.48,26.69-8.87,47.18-23.25,55.89-9.43,5.71-20.45,5.48-32-.62C150.61,87.71,150.39,76.69,156.1,67.26ZM85.51,101.5c-5.23,2.53-12,4-18.54.07-8.47-5.13-13.81-17.48-14.8-33.4,15.93,1,28.27,6.32,33.4,14.79C89.51,89.48,88,96.27,85.51,101.5ZM158.38,212H97.62l-9.77-44h80.31Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M184,152l-14.61,65.74a8,8,0,0,1-7.81,6.26H94.42a8,8,0,0,1-7.81-6.26L72,152Z" opacity="0.2"/><path d="M200,144h-76.7l2.35-2.35h0l20.06-20.06a59.55,59.55,0,0,0,26.1,6.36,49.56,49.56,0,0,0,25.89-7.22c23.72-14.36,36.43-47.6,34-88.92a8,8,0,0,0-7.52-7.52c-41.32-2.42-74.56,10.28-88.93,34-9.35,15.45-9.59,34.11-.86,52L120,124.68l-12.21-12.21c6-13.25,5.57-27-1.39-38.48C95.53,56,70.6,46.4,39.73,48.22a8,8,0,0,0-7.51,7.51C30.4,86.6,40,111.52,58,122.4A38.22,38.22,0,0,0,78,128a45,45,0,0,0,18.52-4.19L108.69,136l-8,8H56a8,8,0,0,0,0,16h9.59L78.8,219.47A15.89,15.89,0,0,0,94.42,232h67.17a15.91,15.91,0,0,0,15.62-12.53L190.42,160H200a8,8,0,0,0,0-16ZM149,66.58c10.46-17.26,35.23-27,67-26.57.41,31.81-9.31,56.58-26.57,67-11.51,7-25.4,6.54-39.28-1.18C142.42,92,142,78.09,149,66.58ZM92.11,108.11c-9.2,4.92-18.31,5.15-25.83.6C54.78,101.74,48.15,85.31,48,64c21.31.15,37.75,6.78,44.71,18.28C97.27,89.8,97,98.91,92.11,108.11ZM161.59,216H94.42L82,160H174Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M200,144h-76.7l22.41-22.41a59.55,59.55,0,0,0,26.1,6.36,49.56,49.56,0,0,0,25.89-7.22c23.72-14.36,36.43-47.6,34-88.92a8,8,0,0,0-7.52-7.52c-41.32-2.43-74.56,10.28-88.93,34-9.35,15.45-9.59,34.11-.86,52L120,124.68l-12.21-12.21c6-13.25,5.57-27-1.39-38.48C95.53,56,70.61,46.41,39.73,48.22a8,8,0,0,0-7.51,7.51C30.4,86.6,40,111.52,58,122.4A38.22,38.22,0,0,0,78,128a45,45,0,0,0,18.52-4.19L108.69,136l-8,8H56a8,8,0,0,0,0,16h9.59L78.8,219.47A15.89,15.89,0,0,0,94.42,232h67.17a15.91,15.91,0,0,0,15.62-12.53L190.42,160H200a8,8,0,0,0,0-16Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M124.24,140.24h0l21.09-21.09a58,58,0,0,0,26.49,6.79A47.55,47.55,0,0,0,196.67,119c23.07-14,35.42-46.53,33-87.09a6,6,0,0,0-5.64-5.64c-40.56-2.38-73.12,10-87.09,33-9.22,15.22-9.2,33.71-.14,51.35L120,127.51l-14.64-14.63c6.32-13,6.15-26.6-.67-37.86C94.21,57.72,70,48.44,39.85,50.21a6,6,0,0,0-5.64,5.64C32.44,86,41.72,110.2,59,120.69A36.23,36.23,0,0,0,78,126a43.4,43.4,0,0,0,18.93-4.6L111.51,136l-10,10H56a6,6,0,0,0,0,12H67.18l13.57,61a13.91,13.91,0,0,0,13.67,11h67.17a13.91,13.91,0,0,0,13.66-11l13.57-61H200a6,6,0,0,0,0-12H118.48l5.76-5.76Zm23-74.7c11-18.22,37.24-28.33,70.72-27.5.83,33.47-9.28,59.68-27.5,70.72-12.3,7.44-27.09,6.92-41.79-1.43C140.31,92.63,139.79,77.84,147.24,65.54Zm-53.66,44c-10,5.56-20,5.87-28.34.84C52.78,102.87,45.75,85,46,62c23-.29,40.88,6.78,48.42,19.24C99.45,89.54,99.14,99.56,93.58,109.58ZM176.52,158l-13,58.43a2,2,0,0,1-1.95,1.57H94.42a2,2,0,0,1-2-1.57L79.48,158Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M200,144h-76.7l2.35-2.35h0l20.06-20.06a59.55,59.55,0,0,0,26.1,6.36,49.56,49.56,0,0,0,25.89-7.22c23.72-14.36,36.43-47.6,34-88.92a8,8,0,0,0-7.52-7.52c-41.32-2.42-74.56,10.28-88.92,34-9.36,15.45-9.6,34.11-.87,52L120,124.68l-12.21-12.21c6-13.25,5.57-27-1.39-38.48C95.53,56,70.61,46.41,39.73,48.22a8,8,0,0,0-7.51,7.51C30.4,86.6,40,111.52,58,122.4A38.22,38.22,0,0,0,78,128a45,45,0,0,0,18.52-4.19L108.68,136l-8,8H56a8,8,0,0,0,0,16h9.59l13.21,59.47A15.91,15.91,0,0,0,94.42,232h67.17a15.91,15.91,0,0,0,15.62-12.53L190.42,160H200a8,8,0,0,0,0-16ZM149,66.58c10.46-17.26,35.24-27,67-26.57.41,31.81-9.31,56.58-26.57,67-11.51,7-25.4,6.54-39.28-1.18C142.42,92,142,78.09,149,66.58ZM92.11,108.11c-9.2,4.93-18.31,5.16-25.83.6C54.78,101.74,48.15,85.31,48,64c21.31.15,37.75,6.78,44.71,18.28C97.27,89.8,97,98.91,92.11,108.11ZM161.59,216H94.42L82,160H174Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M122.83,138.83h0L145,116.7c9,4.89,18.06,7.35,26.81,7.35a45.57,45.57,0,0,0,23.87-6.74c22.42-13.58,34.41-45.45,32.07-85.26A4,4,0,0,0,224,28.29c-39.81-2.35-71.68,9.65-85.26,32.07-9.21,15.2-9,33.09.61,50.68L120,130.34l-17.06-17.06c6.66-12.78,6.71-26.2,0-37.22C92.89,59.4,69.33,50.49,40,52.21A4,4,0,0,0,36.21,56c-1.73,29.36,7.19,52.92,23.85,63A34.29,34.29,0,0,0,78,124a42,42,0,0,0,19.34-5l17,17.06-12,12H56a4,4,0,0,0,0,8H68.79L82.7,218.6A11.93,11.93,0,0,0,94.42,228h67.17a11.93,11.93,0,0,0,11.71-9.4L187.21,156H200a4,4,0,0,0,0-8H113.66l9.17-9.17Zm22.7-74.32C157.15,45.31,184.8,34.8,219.9,36.1c1.29,35.1-9.21,62.74-28.41,74.37-13.08,7.92-28.77,7.32-44.29-1.68C138.21,93.28,137.61,77.59,145.53,64.51ZM95.05,111.05c-10.84,6.19-21.76,6.59-30.84,1.08C50.78,104,43.35,84.68,44,60c24.64-.69,44,6.74,52.1,20.17C101.64,89.29,101.24,100.21,95.05,111.05Zm84,45-13.53,60.87a4,4,0,0,1-3.9,3.13H94.42a4,4,0,0,1-3.91-3.13L77,156Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
