/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M220.77,83.57,199.59,47a30.14,30.14,0,0,0-41.11-11,30.49,30.49,0,0,0-4.92,3.52L144,23a30.16,30.16,0,0,0-49.55-3.78,30.18,30.18,0,0,0-28.59.8A29.95,29.95,0,0,0,53.08,57.47,29.68,29.68,0,0,0,47.32,60a30,30,0,0,0-11,41l.3.52A30.77,30.77,0,0,0,31,104a30,30,0,0,0-11,41l35.33,61a84.48,84.48,0,0,0,115.12,30.75,83.51,83.51,0,0,0,39.27-51c.39-1.45.73-2.89,1-4.35A84.13,84.13,0,0,0,220.77,83.57ZM167.71,60.45a6,6,0,0,1,11-1.45l21.18,36.57a59.85,59.85,0,0,1,7.34,39A85.15,85.15,0,0,0,201.33,122l-33-57A5.94,5.94,0,0,1,167.71,60.45Zm-55.61-24a6,6,0,0,1,7.38-4.25,6,6,0,0,1,3.65,2.8l22.33,38.55A30.7,30.7,0,0,0,140,76a30.22,30.22,0,0,0-4.9,3.52L112.7,41A6,6,0,0,1,112.1,36.45Zm-37,8a6,6,0,0,1,7.37-4.25A6.05,6.05,0,0,1,86.09,43l3.77,6.58A29.92,29.92,0,0,0,84.34,52a30.39,30.39,0,0,0-4.88,3.5L75.68,49A5.93,5.93,0,0,1,75.08,44.45ZM158.44,216a60.37,60.37,0,0,1-82.23-22L40.88,133a6,6,0,0,1,2.2-8.2A6,6,0,0,1,51.3,127l20.29,35a12,12,0,0,0,20.85-12L57.15,89a6,6,0,0,1,10.41-6L99.42,138a12,12,0,0,0,20.85-12L94.2,81.05h0l0-.05a6,6,0,0,1,10.44-6l36.57,63A12,12,0,0,0,162,126l-12.55-21.61A6,6,0,0,1,160.21,99l20.27,35A60,60,0,0,1,158.44,216Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M199,187.76h0A71.67,71.67,0,0,0,190.34,140l-20.2-35a18,18,0,0,0-31.55,17.26L114.71,81A18,18,0,1,0,83.54,99L77.81,89,65.1,67A18,18,0,1,1,96.28,49L102,59a18,18,0,1,1,31.17-18l24.23,42a18,18,0,0,1,31.2-18l21.11,36.57A72,72,0,0,1,199,187.76Z" opacity="0.2"/><path d="M160.22,24V8a8,8,0,0,1,16,0V24a8,8,0,0,1-16,0ZM196.1,41a7.91,7.91,0,0,0,4.17,1.17,8,8,0,0,0,6.84-3.83l8-13.11a8,8,0,0,0-13.68-8.33l-8,13.1A8,8,0,0,0,196.1,41Zm47.51,12.59a8,8,0,0,0-10.08-5.16l-15.06,4.85a8,8,0,0,0,2.46,15.62,8.15,8.15,0,0,0,2.46-.39l15.05-4.85A8,8,0,0,0,243.61,53.55ZM217,97.58a80.22,80.22,0,0,1-10.22,94c-.34,1.73-.72,3.46-1.19,5.18A80.17,80.17,0,0,1,58.77,216L23.5,155a26,26,0,0,1,19.24-38.79l-3-5.2a26,26,0,0,1,19.2-38.78L58.24,71A26,26,0,0,1,95.47,36.53,26.06,26.06,0,0,1,140.3,37l12.26,21.2A26.07,26.07,0,0,1,195.81,61ZM109.07,55l0,0h0l25,43.17a26,26,0,0,1,17.33-10L126.42,45a10,10,0,1,0-17.35,10ZM72.12,63l6.46,11.17a26.05,26.05,0,0,1,17.32-10L89.45,53A10,10,0,1,0,72.12,63Zm111.54,81-20.22-35a10,10,0,0,0-17.74,9.25L158.3,140a8,8,0,0,1-13.87,8l-36.5-63A10,10,0,1,0,90.58,95l26.05,45a8,8,0,0,1-13.87,8L71,93h0l0,0a10,10,0,0,0-17.33,10l35.22,61A8,8,0,0,1,75,172L54.72,137a10,10,0,0,0-17.34,10l35.27,61a64.12,64.12,0,0,0,117.42-15.44A63.52,63.52,0,0,0,183.66,144Zm19.41-38.42L181.93,69A10,10,0,0,0,164.55,79l33,57.05A80.2,80.2,0,0,1,207,161.51,64.23,64.23,0,0,0,203.07,105.58Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M188.87,65A18,18,0,0,0,157.62,83L133.36,41a18,18,0,0,0-31.22,18L96.4,49A18,18,0,0,0,65.18,67l3.34,5.77A26,26,0,0,0,39.74,111l3,5.2A26,26,0,0,0,23.5,155l35.27,61a80.14,80.14,0,0,0,149.52-39.57A71.92,71.92,0,0,0,210,101.58Zm1.2,127.56A64.12,64.12,0,0,1,72.65,208L37.38,147a10,10,0,0,1,17.34-10L75,172a8,8,0,0,0,13.87-8L53.62,103A10,10,0,0,1,71,93l31.81,55a8,8,0,0,0,13.87-8l-26-45a10,10,0,0,1,17.35-10l36.5,63a8,8,0,0,0,13.87-8l-12.6-21.75A10,10,0,0,1,163.44,109l20.22,35A63.52,63.52,0,0,1,190.07,192.57ZM160.22,24V8a8,8,0,0,1,16,0V24a8,8,0,0,1-16,0Zm33.22,6,8-13.1a8,8,0,0,1,13.68,8.33l-8,13.11a8,8,0,0,1-6.84,3.83A8,8,0,0,1,193.44,30Zm45,33.66-15.05,4.85a8.15,8.15,0,0,1-2.46.39,8,8,0,0,1-2.46-15.62l15.06-4.85a8,8,0,1,1,4.91,15.23Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M162.21,24V8a6,6,0,0,1,12,0V24a6,6,0,0,1-12,0Zm34.93,15.25a6,6,0,0,0,8.26-2l8-13.11a6,6,0,0,0-10.26-6.25l-8,13.11A6,6,0,0,0,197.14,39.25Zm44.57,14.91a6,6,0,0,0-7.57-3.87l-15,4.85a6,6,0,0,0,1.84,11.72,5.91,5.91,0,0,0,1.84-.3l15.06-4.84A6,6,0,0,0,241.71,54.16ZM215.22,98.58a78.2,78.2,0,0,1-10.35,92c-.36,1.87-.76,3.74-1.26,5.6A78.18,78.18,0,0,1,60.49,215L25.22,154a24,24,0,0,1,20.85-36l-4.61-8A24,24,0,0,1,62.28,74L60,70A24,24,0,0,1,96.11,39.54,24.05,24.05,0,0,1,138.56,38l13.89,24a24,24,0,0,1,41.62,0ZM107.33,56v0h0L134,102a24,24,0,0,1,20.8-12L128.15,44a12,12,0,1,0-20.82,12Zm-37,8,8.08,14A24,24,0,0,1,99.23,66L91.17,52a12,12,0,0,0-22,2.91A11.9,11.9,0,0,0,70.37,64Zm115,79-20.23-35A12,12,0,0,0,144,119.27L156.56,141a6,6,0,0,1-10.4,6L109.65,84A12,12,0,0,0,88.83,96v0h0l26,45a6,6,0,0,1-10.4,6L72.68,92a12,12,0,0,0-20.81,12L87.1,165a6,6,0,0,1-10.41,6L56.44,136a12,12,0,1,0-20.81,12L70.9,209A66.13,66.13,0,0,0,192,193.09,65.53,65.53,0,0,0,185.4,143Zm19.41-38.42L183.66,68a12,12,0,0,0-16.42-4.39A12,12,0,0,0,162.82,80l33,57a77.7,77.7,0,0,1,10.14,31.54A66.25,66.25,0,0,0,204.81,104.58Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M160.22,24V8a8,8,0,0,1,16,0V24a8,8,0,0,1-16,0ZM196.1,41a7.91,7.91,0,0,0,4.17,1.17,8,8,0,0,0,6.84-3.83l8-13.11a8,8,0,0,0-13.68-8.33l-8,13.1A8,8,0,0,0,196.1,41Zm47.51,12.59a8,8,0,0,0-10.08-5.16l-15.06,4.85a8,8,0,0,0,2.46,15.62,8.15,8.15,0,0,0,2.46-.39l15.05-4.85A8,8,0,0,0,243.61,53.55ZM217,97.58a80.22,80.22,0,0,1-10.22,94c-.34,1.73-.72,3.46-1.19,5.18A80.17,80.17,0,0,1,58.77,216L23.5,155a26,26,0,0,1,19.24-38.79l-3-5.2a26,26,0,0,1,19.2-38.78L58.24,71A26,26,0,0,1,95.47,36.53,26.06,26.06,0,0,1,140.3,37l12.26,21.2A26.07,26.07,0,0,1,195.81,61ZM109.07,55l0,0h0l25,43.17a26,26,0,0,1,17.33-10L126.42,45a10,10,0,1,0-17.35,10ZM72.12,63l6.46,11.17a26.05,26.05,0,0,1,17.32-10L89.45,53A10,10,0,1,0,72.12,63Zm111.54,81-20.22-35a10,10,0,0,0-17.74,9.25L158.3,140a8,8,0,0,1-13.87,8l-36.5-63A10,10,0,1,0,90.58,95l26.05,45a8,8,0,0,1-13.87,8L71,93h0l0,0a10,10,0,0,0-17.33,10l35.22,61A8,8,0,0,1,75,172L54.72,137a10,10,0,0,0-17.34,10l35.27,61a64.12,64.12,0,0,0,117.42-15.44A63.52,63.52,0,0,0,183.66,144Zm19.41-38.42L181.93,69A10,10,0,0,0,164.55,79l33,57.05A80.2,80.2,0,0,1,207,161.51,64.23,64.23,0,0,0,203.07,105.58Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M164.22,24V8a4,4,0,0,1,8,0V24a4,4,0,0,1-8,0Zm34,13.55a4,4,0,0,0,5.51-1.33l8-13.11a4,4,0,0,0-6.84-4.17l-8,13.11A4,4,0,0,0,198.19,37.55Zm41.62,17.23a4,4,0,0,0-5.05-2.58L219.7,57.05a4,4,0,0,0,1.23,7.8,4,4,0,0,0,1.23-.19l15.06-4.85A4,4,0,0,0,239.81,54.78ZM203,189.66c-.37,2-.81,4-1.34,6A76.18,76.18,0,0,1,62.22,214L27,153a22,22,0,0,1,22.76-32.69L43.19,109A22,22,0,0,1,65.92,76.32L61.69,69A22,22,0,0,1,96.87,43,21.83,21.83,0,0,1,106.72,31,22.08,22.08,0,0,1,136.82,39l15.79,27.3A22.05,22.05,0,0,1,192.34,63l21.14,36.57A76.19,76.19,0,0,1,203,189.66ZM161.08,81l33,57.05a75.49,75.49,0,0,1,10.21,37.24,68.26,68.26,0,0,0,2.27-71.66L185.4,67A14,14,0,0,0,161.08,81ZM105.59,57l28.54,49.29a22,22,0,0,1,24.28-14L129.88,43a14,14,0,1,0-24.29,14Zm-37,8,10,17.27a22,22,0,0,1,24.27-14L92.91,51A14,14,0,1,0,68.63,65Zm118.5,77L166.9,107a14,14,0,0,0-24.67,13.26L154.83,142a4,4,0,1,1-6.93,4L111.39,83h0a14,14,0,0,0-19.15-5.11A14,14,0,0,0,87.09,97v0h0l26.05,45a4,4,0,0,1-1.46,5.47,4,4,0,0,1-5.48-1.46L74.42,91h0a14,14,0,0,0-24.28,14l35.23,61a4,4,0,0,1-6.94,4L58.18,135a14,14,0,0,0-24.29,14l35.27,61a68.09,68.09,0,0,0,118-68Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
