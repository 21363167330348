/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M214.58,41.42C196.86,23.71,171,16.63,141.81,21.5c-27.74,4.62-55.46,19.62-78.08,42.23S26.12,114.07,21.5,141.81c-4.87,29.21,2.21,55.05,19.92,72.77C55.48,228.64,74.67,236,96.6,236a107.43,107.43,0,0,0,17.59-1.5c27.74-4.62,55.46-19.62,78.08-42.23s37.61-50.34,42.23-78.08C239.37,85,232.29,59.14,214.58,41.42ZM45.17,145.75c3.8-22.84,16.42-45.94,35.53-65S122.91,49,145.75,45.17A82,82,0,0,1,159.27,44a62.77,62.77,0,0,1,18.24,2.57A110.12,110.12,0,0,0,154.62,60.4c-20,15.77-32.88,37.72-38.38,65.25-8.95,44.73-40.3,61.1-62.07,67.09C45.25,180.83,42.06,164.41,45.17,145.75Zm165.66-35.5c-3.8,22.84-16.42,45.94-35.53,65.05S133.09,207,110.25,210.83c-11.65,1.94-22.4,1.41-31.75-1.42a109.42,109.42,0,0,0,22.87-13.81c20-15.77,32.89-37.72,38.39-65.25,9-44.73,40.3-61.1,62.07-67.09C210.75,75.17,213.94,91.59,210.83,110.25Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M183.79,183.79c-43.14,43.14-103.09,53.14-133.9,22.32S29.07,115.35,72.21,72.21,175.3,19.07,206.11,49.89,226.93,140.65,183.79,183.79Z" opacity="0.2"/><path d="M211.75,44.25C195,27.47,170.37,20.79,142.46,25.44c-26.91,4.49-53.87,19.09-75.9,41.12s-36.63,49-41.12,75.9c-4.65,27.91,2,52.51,18.81,69.29C57.54,225.05,75.75,232,96.62,232a103.66,103.66,0,0,0,16.92-1.44c26.91-4.49,53.87-19.09,75.9-41.12s36.63-49,41.12-75.9C235.21,85.63,228.53,61,211.75,44.25ZM77.87,77.87C102.56,53.19,133,39.93,159.15,39.93a62.26,62.26,0,0,1,29,6.67A108.48,108.48,0,0,0,157.1,63.54c-19.2,15.16-31.63,36.32-36.94,62.89-9.72,48.58-44.7,65.18-67.48,70.84C28.71,168.76,39.4,116.35,77.87,77.87ZM178.13,178.13c-34.69,34.68-80.71,46.78-110.32,31.27A108.72,108.72,0,0,0,98.9,192.46c19.2-15.16,31.63-36.32,36.94-62.89,9.72-48.58,44.7-65.18,67.48-70.84C227.29,87.24,216.6,139.65,178.13,178.13Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M71.22,190.47a108.88,108.88,0,0,1-33.84,9.16,4,4,0,0,1-3.89-2c-8.67-15.28-11.52-34.29-8-55.15,4.49-26.92,19.09-53.87,41.12-75.9s49-36.63,75.9-41.12c22.79-3.79,43.37,0,59.29,10.6a4,4,0,0,1-1.25,7.23,121,121,0,0,0-21.82,7.46c-21.77,9.9-49.6,31.06-58.52,75.7C114.1,156.73,97.63,178.27,71.22,190.47ZM222.51,58.38a4,4,0,0,0-3.88-2,108.5,108.5,0,0,0-33.85,9.16c-26.41,12.2-42.88,33.74-48.94,64-8.93,44.64-36.75,65.8-58.52,75.7a121,121,0,0,1-21.82,7.46A4,4,0,0,0,54.27,220c11.87,7.92,26.32,12,42.35,12a103.66,103.66,0,0,0,16.92-1.44c26.91-4.49,53.87-19.09,75.9-41.12s36.63-49,41.12-75.9C234,92.68,231.18,73.66,222.51,58.38Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M210.34,45.66C194,29.35,170,22.87,142.79,27.42,116.28,31.83,89.71,46.24,68,68s-36.14,48.3-40.56,74.81C22.87,170,29.35,194,45.66,210.34,58.57,223.25,76.29,230,96.64,230a101.59,101.59,0,0,0,16.57-1.41c26.51-4.42,53.08-18.83,74.82-40.56s36.14-48.31,40.56-74.82C233.13,86,226.65,62,210.34,45.66ZM76.46,76.46C101.52,51.4,132.46,38,159.18,38c12.58,0,24.22,3,33.87,9.12a108,108,0,0,0-34.71,18c-18.82,14.86-31,35.62-36.22,61.71C112,177.35,75.18,194,52,199.5,26.3,170.42,36.83,116.09,76.46,76.46ZM179.54,179.54C142.68,216.4,93.1,228.1,63,208.91a108,108,0,0,0,34.71-18c18.82-14.86,31-35.62,36.22-61.71C144,78.65,180.82,62,204.05,56.5,229.7,85.59,219.17,139.91,179.54,179.54Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M211.75,44.25C195,27.47,170.37,20.79,142.46,25.44c-26.91,4.49-53.87,19.09-75.9,41.12s-36.63,49-41.12,75.9c-4.65,27.91,2,52.51,18.81,69.29C57.54,225.05,75.75,232,96.62,232a103.66,103.66,0,0,0,16.92-1.44c26.91-4.49,53.87-19.09,75.9-41.12s36.63-49,41.12-75.9C235.21,85.63,228.53,61,211.75,44.25ZM77.87,77.87C102.56,53.19,133,39.93,159.15,39.93a62.26,62.26,0,0,1,29,6.67A108.48,108.48,0,0,0,157.1,63.54c-19.2,15.16-31.63,36.32-36.94,62.89-9.72,48.58-44.7,65.18-67.48,70.84C28.71,168.76,39.4,116.35,77.87,77.87ZM178.13,178.13c-34.69,34.68-80.71,46.78-110.32,31.27A108.72,108.72,0,0,0,98.9,192.46c19.2-15.16,31.63-36.32,36.94-62.89,9.72-48.58,44.7-65.18,67.48-70.84C227.29,87.24,216.6,139.65,178.13,178.13Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M209,47c-32.36-32.35-95-22.33-139.7,22.34S14.63,176.66,47,209c12.82,12.81,30.38,19,49.71,19,29.46,0,63-14.34,90-41.32C231.35,142,241.37,79.34,209,47ZM75,75c25.47-25.46,57-39.09,84.26-39.09,14.51,0,27.83,3.87,38.43,11.84-25.76,7.05-63,26.4-73.6,79.49-10.5,52.52-49.25,69.26-72.91,74.58C23.77,172.12,34.16,115.81,75,75ZM181,181c-39,39-92.17,50.23-122.71,27.25,25.75-7,63-26.39,73.62-79.48,10.5-52.53,49.25-69.26,72.91-74.59C232.23,83.88,221.84,140.19,181,181Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
