import { template } from "@ember/template-compiler";
import { pageTitle } from 'ember-page-title';
import { Route } from 'ember-polaris-routing';
import CompatRoute from 'ember-polaris-routing/route/compat';
import { Page } from '@hokulea/ember';
export class TrainingControlRoute extends Route<object> {
    static{
        template(`
    {{pageTitle "Trainingssteuerung"}}

    <Page @title="Trainingssteuerung">
      tbd.
    </Page>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
// @ts-expect-error some broken upstream types here
export default CompatRoute(TrainingControlRoute);
