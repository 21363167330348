/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M251.75,181.48a11.88,11.88,0,0,0-7.66-8.84c-1.42-.54-25.86-10.18-39.35-43.23l15.68-6.27a12,12,0,1,0-8.91-22.28l-13.35,5.34A150.23,150.23,0,0,1,196,80,68,68,0,0,0,60,80a151.26,151.26,0,0,1-2.18,26.23l-13.36-5.34a12,12,0,1,0-8.91,22.28l15.68,6.27C37.74,162.46,13.31,172.09,12,172.6a12,12,0,0,0-4.17,20.05c8.09,7.6,19.85,8.76,30.23,9.79,5.62.55,12,1.18,14.85,2.75,2.59,1.42,5.94,6,8.9,10.07,5.51,7.56,12.38,17,23.47,19.8,10.23,2.61,20.11-.75,28.82-3.72,5-1.7,10.17-3.46,13.92-3.46s8.92,1.76,13.92,3.46c6.51,2.22,13.67,4.66,21.15,4.66a30.9,30.9,0,0,0,7.67-.94h0c11.09-2.84,18-12.24,23.47-19.8,3-4,6.31-8.65,8.9-10.07,2.85-1.57,9.23-2.2,14.85-2.75,10.38-1,22.14-2.19,30.23-9.79A12,12,0,0,0,251.75,181.48Zm-60.22,2.68c-7.27,4-12.29,10.88-16.72,17-3.25,4.45-7.3,10-10,10.7-3.3.85-9.32-1.2-15.14-3.18-6.53-2.23-13.93-4.75-21.65-4.75s-15.12,2.52-21.65,4.75c-5.82,2-11.84,4-15.14,3.18-2.74-.7-6.79-6.25-10-10.7-4.43-6.07-9.45-13-16.72-17-5.75-3.17-12.44-4.34-19.16-5.1a105.29,105.29,0,0,0,7.63-7.62c8.64-9.57,18.29-24,24.52-44.4a.14.14,0,0,0,0-.06,11.24,11.24,0,0,0,.63-2.13A162.57,162.57,0,0,0,84,80a44,44,0,0,1,88,0,162.57,162.57,0,0,0,5.92,44.88,12.64,12.64,0,0,0,.63,2.13.14.14,0,0,0,0,.06c6.23,20.44,15.88,34.83,24.52,44.4a105.29,105.29,0,0,0,7.63,7.62C204,179.82,197.29,181,191.53,184.16Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M240,183.9c-9.25,8.69-31.45,4.61-42.66,10.78-11,6.07-17.07,25.56-29.57,28.76-12.08,3.09-26.72-7.56-39.77-7.56s-27.69,10.65-39.77,7.56c-12.5-3.2-18.53-22.69-29.57-28.76C47.45,188.51,25.25,192.59,16,183.9c0,0,56-20,56-103.93a56,56,0,0,1,112,0C184,163.86,240,183.9,240,183.9Z" opacity="0.2"/><path d="M247.83,182.28a8,8,0,0,0-5.13-5.9c-.39-.14-28.95-10.88-43-49.23l19.3-7.72A8,8,0,1,0,213,104.57l-17.82,7.13A149,149,0,0,1,192,80,64,64,0,0,0,64,80a151.24,151.24,0,0,1-3.18,31.75L43,104.57A8,8,0,1,0,37,119.43l19.37,7.75a94,94,0,0,1-17.74,30.2c-12.52,14.14-25.27,19-25.36,19a8,8,0,0,0-2.77,13.36c7.1,6.67,17.67,7.71,27.89,8.72,6.3.62,12.82,1.27,16.38,3.23,3.37,1.86,6.85,6.62,10.21,11.22,5.4,7.41,11.53,15.8,21.24,18.28,9.07,2.33,18.35-.83,26.54-3.62,5.55-1.89,10.8-3.68,15.21-3.68s9.66,1.79,15.21,3.68c6.2,2.11,13,4.43,19.9,4.43a26.35,26.35,0,0,0,6.64-.81h0c9.7-2.48,15.83-10.87,21.23-18.28,3.36-4.6,6.84-9.36,10.21-11.22,3.56-2,10.08-2.61,16.39-3.23,10.21-1,20.78-2.05,27.88-8.72A8,8,0,0,0,247.83,182.28Zm-31.82.26c-7.91.78-16.08,1.59-22.53,5.13s-11,9.79-15.41,15.81c-4,5.48-8.15,11.16-12.28,12.21-4.46,1.15-10.76-1-17.42-3.27s-13.31-4.53-20.37-4.53-13.83,2.3-20.37,4.53-13,4.42-17.42,3.27c-4.13-1.05-8.27-6.73-12.28-12.21-4.39-6-8.93-12.24-15.41-15.81S47.9,183.32,40,182.54c-1.56-.15-3.15-.31-4.74-.49a97.34,97.34,0,0,0,14.69-13.29c8.37-9.27,17.72-23.23,23.74-43.13l.06-.13a8.63,8.63,0,0,0,.46-1.61A158.47,158.47,0,0,0,80,80a48,48,0,0,1,96,0,158.42,158.42,0,0,0,5.8,43.92,8.63,8.63,0,0,0,.46,1.61l.06.13c6,19.9,15.37,33.86,23.74,43.13a97.34,97.34,0,0,0,14.69,13.29C219.16,182.23,217.57,182.39,216,182.54Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M245.47,189.74c-7.1,6.67-17.67,7.71-27.88,8.72-6.31.62-12.83,1.27-16.39,3.23-3.37,1.86-6.85,6.62-10.21,11.22-5.4,7.41-11.53,15.8-21.23,18.28h0a26.35,26.35,0,0,1-6.64.81c-6.88,0-13.7-2.32-19.9-4.43-5.55-1.89-10.8-3.68-15.21-3.68s-9.66,1.79-15.21,3.68c-8.19,2.79-17.47,6-26.54,3.62-9.71-2.48-15.84-10.87-21.24-18.28-3.36-4.6-6.84-9.36-10.21-11.22-3.56-2-10.08-2.61-16.38-3.23-10.22-1-20.79-2.05-27.89-8.72a8,8,0,0,1,2.77-13.36c.09,0,12.84-4.86,25.36-19a94,94,0,0,0,17.74-30.2L37,119.43A8,8,0,1,1,43,104.57l17.85,7.15A151.24,151.24,0,0,0,64,80a64,64,0,0,1,128,0,149,149,0,0,0,3.21,31.73L213,104.57A8,8,0,1,1,219,119.43l-19.3,7.72c14.08,38.35,42.64,49.09,43,49.23a8,8,0,0,1,2.77,13.36Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M245.87,182.68a6,6,0,0,0-3.85-4.43c-.4-.14-30.71-11.53-44.87-52.25l21.08-8.43a6,6,0,1,0-4.46-11.14l-20,8A148.66,148.66,0,0,1,190,80,62,62,0,0,0,66,80a151.37,151.37,0,0,1-3.72,34.48l-20.05-8a6,6,0,0,0-4.46,11.14L58.93,126A96.13,96.13,0,0,1,40,158.87c-12.85,14.44-25.91,19.34-26,19.38a6,6,0,0,0-2.08,10c6.6,6.19,16.83,7.2,26.71,8.18,6.51.64,13.23,1.31,17.16,3.47,3.76,2.07,7.36,7,10.85,11.79,5.21,7.13,11.11,15.22,20.12,17.53,8.5,2.16,17.09-.76,25.4-3.59,5.72-1.94,11.11-3.78,15.86-3.78s10.14,1.84,15.86,3.78c6.29,2.14,12.74,4.34,19.19,4.34a25.36,25.36,0,0,0,6.21-.75h0c9-2.3,14.91-10.39,20.12-17.52,3.49-4.78,7.09-9.72,10.85-11.79,3.93-2.16,10.65-2.83,17.16-3.47,9.88-1,20.11-2,26.71-8.18A6,6,0,0,0,245.87,182.68Zm-29.66,1.84c-7.71.76-15.68,1.55-21.76,4.9s-10.5,9.39-14.77,15.22-8.56,11.74-13.39,13c-5,1.28-11.61-1-18.57-3.32-6.38-2.17-13-4.42-19.72-4.42s-13.34,2.25-19.72,4.42c-7,2.37-13.53,4.6-18.57,3.32-4.83-1.24-9.18-7.2-13.39-13s-8.67-11.88-14.77-15.23-14-4.14-21.76-4.9c-3.37-.33-6.79-.67-9.89-1.21a93.88,93.88,0,0,0,18.55-15.9c8.24-9.11,17.44-22.86,23.35-42.48a1.42,1.42,0,0,0,.08-.18,5.47,5.47,0,0,0,.35-1.27A156.21,156.21,0,0,0,78,80a50,50,0,0,1,100,0,156.21,156.21,0,0,0,5.77,43.51,5.34,5.34,0,0,0,.35,1.27.89.89,0,0,0,.08.17c5.91,19.63,15.11,33.38,23.35,42.49a93.88,93.88,0,0,0,18.55,15.9C223,183.85,219.58,184.19,216.21,184.52Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M247.83,182.28a8,8,0,0,0-5.13-5.9c-.39-.14-28.95-10.88-43-49.23l19.3-7.72A8,8,0,1,0,213,104.57l-17.82,7.13A149,149,0,0,1,192,80,64,64,0,0,0,64,80a151.24,151.24,0,0,1-3.18,31.75L43,104.57A8,8,0,1,0,37,119.43l19.37,7.75a94,94,0,0,1-17.74,30.2c-12.52,14.14-25.27,19-25.36,19a8,8,0,0,0-2.77,13.36c7.1,6.67,17.67,7.71,27.88,8.72,6.31.62,12.83,1.27,16.39,3.23,3.37,1.86,6.85,6.62,10.21,11.22,5.4,7.41,11.53,15.8,21.24,18.28,9.07,2.33,18.35-.83,26.54-3.62,5.55-1.89,10.8-3.68,15.21-3.68s9.66,1.79,15.21,3.68c6.2,2.11,13,4.43,19.9,4.43a26.35,26.35,0,0,0,6.64-.81h0c9.7-2.48,15.83-10.87,21.23-18.28,3.36-4.6,6.84-9.36,10.21-11.22,3.56-2,10.08-2.61,16.39-3.23,10.21-1,20.78-2.05,27.88-8.72A8,8,0,0,0,247.83,182.28Zm-31.82.26c-7.91.78-16.08,1.59-22.53,5.13s-11,9.79-15.41,15.81c-4,5.48-8.15,11.16-12.28,12.21-4.46,1.15-10.76-1-17.42-3.27s-13.31-4.53-20.37-4.53-13.83,2.3-20.37,4.53-13,4.42-17.42,3.27c-4.13-1.05-8.27-6.73-12.28-12.21-4.39-6-8.93-12.24-15.41-15.81S47.9,183.32,40,182.54c-1.55-.15-3.15-.31-4.74-.49a97.34,97.34,0,0,0,14.69-13.29c8.37-9.27,17.72-23.23,23.74-43.13l.06-.13a8.63,8.63,0,0,0,.46-1.61A158.47,158.47,0,0,0,80,80a48,48,0,0,1,96,0,158.42,158.42,0,0,0,5.8,43.92,8.63,8.63,0,0,0,.46,1.61l.06.13c6,19.9,15.37,33.86,23.74,43.13a97.34,97.34,0,0,0,14.69,13.29C219.16,182.23,217.57,182.39,216,182.54Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M243.92,183.1a4,4,0,0,0-2.56-3c-.13,0-13.52-5-26.69-19.76a99.18,99.18,0,0,1-20-35.54l22.83-9.13a4,4,0,1,0-3-7.42l-22.08,8.83A149.77,149.77,0,0,1,188,80,60,60,0,0,0,68,80a150.25,150.25,0,0,1-4.43,37.15l-22.08-8.83a4,4,0,1,0-3,7.42l22.82,9.13a99.16,99.16,0,0,1-20,35.54c-13.18,14.73-26.56,19.71-26.69,19.76a4,4,0,0,0-1.39,6.68c6.12,5.73,16,6.71,25.55,7.65,6.7.67,13.64,1.35,17.92,3.71s7.73,7.18,11.51,12.36c5.25,7.2,10.69,14.65,19,16.77,7.92,2,16.23-.8,24.26-3.54,5.88-2,11.43-3.89,16.5-3.89s10.63,1.89,16.5,3.89c6.13,2.09,12.42,4.23,18.57,4.23a22.92,22.92,0,0,0,5.7-.69h0c8.31-2.12,13.74-9.57,19-16.77,3.79-5.18,7.36-10.08,11.51-12.36s11.22-3,17.93-3.71c9.55-.94,19.43-1.92,25.54-7.65A4,4,0,0,0,243.92,183.1Zm-27.51,3.41c-7.51.75-15.27,1.51-21,4.66s-10,9-14.12,14.66c-4.62,6.33-9,12.32-14.51,13.73s-12.46-.89-19.71-3.36c-6.23-2.12-12.68-4.32-19.08-4.32s-12.84,2.2-19.08,4.32c-7.24,2.47-14.09,4.8-19.7,3.36s-9.89-7.4-14.52-13.73c-4.13-5.66-8.41-11.52-14.11-14.66s-13.49-3.91-21-4.66c-5.26-.52-10.63-1-14.91-2.37A90.17,90.17,0,0,0,47,166.08c8.1-9,17.14-22.5,23-41.85A1.51,1.51,0,0,0,70,124a3.8,3.8,0,0,0,.23-.91A154.12,154.12,0,0,0,76,80a52,52,0,0,1,104,0,154.12,154.12,0,0,0,5.74,43.13,3.41,3.41,0,0,0,.24.91c0,.08.07.14.1.22,5.81,19.35,14.86,32.88,23,41.85a90.16,90.16,0,0,0,22.27,18.06C227,185.46,221.67,186,216.41,186.51Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
