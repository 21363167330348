/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M144,204a16,16,0,1,1-16-16A16,16,0,0,1,144,204ZM217,80l15.52-15.51a12,12,0,0,0-17-17L200,63,184.49,47.51a12,12,0,0,0-17,17L183,80,167.51,95.51a12,12,0,0,0,17,17L200,97l15.51,15.52a12,12,0,0,0,17-17Zm-41.9,75.3a80,80,0,0,0-94.13,0,12,12,0,1,0,14.13,19.4,56,56,0,0,1,65.87,0,12,12,0,0,0,14.13-19.4ZM140,56a12,12,0,0,0-12-12A176.27,176.27,0,0,0,16.39,83.91a12,12,0,1,0,15.23,18.55A152.24,152.24,0,0,1,128,68,12,12,0,0,0,140,56Zm0,48a12,12,0,0,0-12-12,126.66,126.66,0,0,0-79.45,27.64,12,12,0,0,0,14.9,18.81A102.89,102.89,0,0,1,128,116,12,12,0,0,0,140,104Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M224.39,104.34,134.15,213.12a8,8,0,0,1-12.3,0L17.8,87.69a7.79,7.79,0,0,1,1.31-11.21A179.58,179.58,0,0,1,128,40a181.82,181.82,0,0,1,33.06,3,7.94,7.94,0,0,1,4.17,2.21L224,104Z" opacity="0.2"/><path d="M229.66,98.34a8,8,0,0,1-11.32,11.32L200,91.31l-18.34,18.35a8,8,0,0,1-11.32-11.32L188.69,80,170.34,61.66a8,8,0,0,1,11.32-11.32L200,68.69l18.34-18.35a8,8,0,0,1,11.32,11.32L211.31,80Zm-33.06,39.5a8,8,0,0,0-11.27,1L128,208,24.09,82.74A170.76,170.76,0,0,1,128,48c2.54,0,5.11.06,7.65.17a8,8,0,0,0,.7-16c-2.77-.12-5.58-.18-8.35-.18A186.67,186.67,0,0,0,14.28,70.1,15.93,15.93,0,0,0,8.11,80.91,15.65,15.65,0,0,0,11.65,92.8l104,125.43A15.93,15.93,0,0,0,128,224h0a15.93,15.93,0,0,0,12.31-5.77l57.34-69.12A8,8,0,0,0,196.6,137.84Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M229.66,98.34a8,8,0,0,1-11.32,11.32L200,91.31l-18.34,18.35a8,8,0,0,1-11.32-11.32L188.69,80,170.34,61.66a8,8,0,0,1,11.32-11.32L200,68.69l18.34-18.35a8,8,0,0,1,11.32,11.32L211.31,80ZM206.85,131a32.75,32.75,0,0,1-4.15-3.14,4,4,0,0,0-5.37,0,32,32,0,0,1-45.18-45.18,4,4,0,0,0,0-5.34A32,32,0,0,1,149,38.91a4,4,0,0,0-3.11-6.08Q137,32,128,32A186.67,186.67,0,0,0,14.28,70.1,15.93,15.93,0,0,0,8.11,80.91,15.65,15.65,0,0,0,11.65,92.8l104,125.43A15.93,15.93,0,0,0,128,224h0a15.93,15.93,0,0,0,12.31-5.77l67.45-81.31A4,4,0,0,0,206.85,131Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M138,204a10,10,0,1,1-10-10A10,10,0,0,1,138,204ZM208.48,80l19.76-19.76a6,6,0,0,0-8.48-8.48L200,71.52,180.24,51.76a6,6,0,0,0-8.48,8.48L191.52,80,171.76,99.76a6,6,0,1,0,8.48,8.48L200,88.48l19.76,19.76a6,6,0,0,0,8.48-8.48Zm-36.95,80.15a74,74,0,0,0-87.06,0,6,6,0,0,0,7.06,9.7,62,62,0,0,1,72.94,0,6,6,0,0,0,8.38-1.32A6,6,0,0,0,171.53,160.15Zm-35.82-98a6,6,0,0,0,.58-12c-2.75-.13-5.54-.2-8.29-.2A170.32,170.32,0,0,0,20.19,88.55a6,6,0,1,0,7.62,9.27A158.26,158.26,0,0,1,128,62C130.56,62,133.16,62.06,135.71,62.18Zm-.12,48.08a6,6,0,1,0,.82-12c-2.79-.19-5.62-.29-8.41-.29a120.75,120.75,0,0,0-75.73,26.34,6,6,0,0,0,7.46,9.41A108.78,108.78,0,0,1,128,110C130.52,110,133.08,110.09,135.59,110.26Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M229.66,98.34a8,8,0,0,1-11.32,11.32L200,91.31l-18.34,18.35a8,8,0,0,1-11.32-11.32L188.69,80,170.34,61.66a8,8,0,0,1,11.32-11.32L200,68.69l18.34-18.35a8,8,0,0,1,11.32,11.32L211.31,80ZM128,192a12,12,0,1,0,12,12A12,12,0,0,0,128,192Zm44.71-33.47a76.05,76.05,0,0,0-89.42,0,8,8,0,0,0,9.42,12.94,60,60,0,0,1,70.58,0,8,8,0,1,0,9.42-12.94ZM135.62,64.18a8,8,0,1,0,.76-16c-2.78-.13-5.6-.2-8.38-.2A172.35,172.35,0,0,0,18.92,87,8,8,0,1,0,29.08,99.37,156.25,156.25,0,0,1,128,64C130.53,64,133.09,64.06,135.62,64.18Zm-.16,48.07a8,8,0,1,0,1.08-16c-2.83-.19-5.7-.29-8.54-.29a122.74,122.74,0,0,0-77,26.77A8,8,0,0,0,56,137a7.93,7.93,0,0,0,5-1.73A106.87,106.87,0,0,1,128,112C130.48,112,133,112.08,135.46,112.25Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M136,204a8,8,0,1,1-8-8A8,8,0,0,1,136,204ZM205.66,80l21.17-21.17a4,4,0,1,0-5.66-5.66L200,74.34,178.83,53.17a4,4,0,0,0-5.66,5.66L194.34,80l-21.17,21.17a4,4,0,0,0,5.66,5.66L200,85.66l21.17,21.17a4,4,0,1,0,5.66-5.66Zm-35.31,81.77a72,72,0,0,0-84.71,0,4,4,0,0,0,4.71,6.46,64.05,64.05,0,0,1,75.29,0,4,4,0,1,0,4.71-6.46ZM135.81,60.19a4,4,0,1,0,.38-8c-2.71-.13-5.47-.2-8.19-.2A168.33,168.33,0,0,0,21.46,90.09a4,4,0,1,0,5.08,6.19A160.22,160.22,0,0,1,128,60C130.59,60,133.22,60.06,135.81,60.19Zm-.08,48.07a4,4,0,1,0,.54-8c-2.74-.19-5.52-.28-8.27-.28a118.72,118.72,0,0,0-74.48,25.91,4,4,0,0,0,5,6.27A110.84,110.84,0,0,1,128,108C130.57,108,133.17,108.09,135.73,108.26Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
