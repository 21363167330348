/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M216.07,31.12a12,12,0,0,0-16.95.81L195.42,36H128A91.95,91.95,0,0,0,57.8,187.39L39.12,207.93a12,12,0,1,0,17.76,16.14l18.67-20.53A91.48,91.48,0,0,0,128,220h72a12,12,0,0,0,0-24H128a67.51,67.51,0,0,1-36.12-10.43L133.31,140H200a12,12,0,0,0,0-24H155.13l61.75-67.93A12,12,0,0,0,216.07,31.12ZM74.13,169.42a67.75,67.75,0,0,1-13-29.42h39.79ZM61.08,116A68.1,68.1,0,0,1,128,60h45.6l-50.91,56Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M200,48V208H128a80,80,0,0,1-80-80h0a80,80,0,0,1,80-80Z" opacity="0.2"/><path d="M213.38,34.08a8,8,0,0,0-11.3.54L197.19,40H128A88,88,0,0,0,63.16,187.43L42.08,210.62a8,8,0,1,0,11.84,10.76L75,198.2A87.5,87.5,0,0,0,128,216h72a8,8,0,0,0,0-16H128a71.63,71.63,0,0,1-42.18-13.7L131.54,136H200a8,8,0,0,0,0-16H146.08l67.84-74.62A8,8,0,0,0,213.38,34.08ZM74,175.53A71.69,71.69,0,0,1,56.46,136h53.46ZM56.46,120A72.08,72.08,0,0,1,128,56h54.64l-58.18,64Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM190,69.27,145.63,120H176a8,8,0,0,1,0,16H131.63l-28.76,32.87A47.72,47.72,0,0,0,128,176h48a8,8,0,0,1,0,16H128a63.62,63.62,0,0,1-35.78-11L78,197.27a8,8,0,0,1-12-10.54l14.21-16.24A64,64,0,0,1,128,64h45.37L178,58.73a8,8,0,1,1,12,10.54ZM128,80h31.37l-35,40H80.68A48.07,48.07,0,0,1,128,80ZM80.68,136h29.69L90.84,158.32A47.78,47.78,0,0,1,80.68,136Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M212,35.56a6,6,0,0,0-8.48.4l-5.49,6H128A85.93,85.93,0,0,0,65.9,187.4L43.56,212A6,6,0,0,0,52.44,220l22.33-24.57A85.52,85.52,0,0,0,128,214h72a6,6,0,0,0,0-12H128a73.65,73.65,0,0,1-45.14-15.42L130.65,134H200a6,6,0,0,0,0-12H141.56l70.88-78A6,6,0,0,0,212,35.56ZM74,178.5A73.74,73.74,0,0,1,54.26,134h60.18ZM54.26,122A74.09,74.09,0,0,1,128,54h59.16l-61.81,68Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M213.38,34.08a8,8,0,0,0-11.3.54L197.19,40H128A88,88,0,0,0,63.16,187.43L42.08,210.62a8,8,0,1,0,11.84,10.76L75,198.2A87.5,87.5,0,0,0,128,216h72a8,8,0,0,0,0-16H128a71.63,71.63,0,0,1-42.18-13.7L131.54,136H200a8,8,0,0,0,0-16H146.08l67.84-74.62A8,8,0,0,0,213.38,34.08ZM74,175.53A71.69,71.69,0,0,1,56.46,136h53.46ZM56.46,120A72.08,72.08,0,0,1,128,56h54.64l-58.18,64Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M210.69,37a4,4,0,0,0-5.65.27L199,44H128A84,84,0,0,0,68.63,187.36L45,213.31A4,4,0,1,0,51,218.69l23.58-25.94A83.64,83.64,0,0,0,128,212h72a4,4,0,0,0,0-8H128a75.66,75.66,0,0,1-48.07-17.18L129.77,132H200a4,4,0,0,0,0-8H137L211,42.69A4,4,0,0,0,210.69,37ZM74,181.43A75.7,75.7,0,0,1,52.11,132H119ZM52.11,124A76.09,76.09,0,0,1,128,52h63.69l-65.46,72Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
