/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M234.7,29.3a31.83,31.83,0,0,0-45,0L15.52,203.56a12,12,0,0,0,5.78,20.19A164.85,164.85,0,0,0,58.42,228c33.71,0,67.41-10.42,99.1-30.87,32.32-20.86,51.16-44.7,51.94-45.7a12,12,0,0,0-1-15.89L191,118,234.7,74.29A31.86,31.86,0,0,0,234.7,29.3ZM143.87,177.36C113,197.12,81.28,206,49.28,203.74L146,107l19.5,19.51,0,0h0l18,18A216.69,216.69,0,0,1,143.87,177.36Zm73.86-120L174,101,163,90,206.7,46.28a7.8,7.8,0,0,1,11,11Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M226.2,65.8,174,118,146,90l52.2-52.2a19.8,19.8,0,0,1,28,0h0A19.8,19.8,0,0,1,226.2,65.8Z" opacity="0.2"/><path d="M231.87,32.13a27.84,27.84,0,0,0-39.32,0L18.34,206.4a8,8,0,0,0,3.86,13.45A160.67,160.67,0,0,0,58.4,224c32.95,0,65.92-10.2,96.95-30.23,31.76-20.5,50.19-43.82,51-44.81a8,8,0,0,0-.64-10.59L185.32,118l46.55-46.56A27.85,27.85,0,0,0,231.87,32.13ZM189.1,144.44a220.41,220.41,0,0,1-42.86,36.16c-34.43,22.1-69.94,30.92-105.77,26.3L146,101.33Zm31.46-84.3L174,106.7,157.32,90l46.55-46.56a11.8,11.8,0,0,1,16.69,16.69Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M236,49a25,25,0,0,0-42.63-17.66L143.16,81.54,18.34,206.4a8,8,0,0,0,3.86,13.45A160.67,160.67,0,0,0,58.4,224c32.95,0,65.91-10.2,96.94-30.23,31.76-20.5,50.19-43.82,51-44.8a8,8,0,0,0-.64-10.59L181.31,114l47.38-47.39A24.84,24.84,0,0,0,236,49ZM146.23,180.6c-34.43,22.1-69.94,30.92-105.76,26.3L146,101.34l43.09,43.1A220.09,220.09,0,0,1,146.23,180.6Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M230.46,33.55a25.81,25.81,0,0,0-36.49,0L19.76,207.82a6,6,0,0,0,2.89,10.09A158.88,158.88,0,0,0,58.39,222c32.57,0,65.17-10.1,95.87-29.91,31.49-20.32,49.72-43.39,50.48-44.36a6,6,0,0,0-.49-7.94L182.49,118l48-48A25.86,25.86,0,0,0,230.46,33.55Zm-38.6,110.82A221,221,0,0,1,147.75,182C111.5,205.4,74,214.23,36.23,208.32L146,98.5ZM222,61.56l-48,48L154.49,90l48-48A13.81,13.81,0,0,1,222,61.56Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M231.87,32.13a27.84,27.84,0,0,0-39.32,0L18.34,206.4a8,8,0,0,0,3.86,13.45A160.67,160.67,0,0,0,58.4,224c32.95,0,65.92-10.2,96.95-30.23,31.76-20.5,50.19-43.82,51-44.81a8,8,0,0,0-.64-10.59L185.32,118l46.55-46.56A27.85,27.85,0,0,0,231.87,32.13ZM189.1,144.44a220.41,220.41,0,0,1-42.86,36.16c-34.43,22.1-69.94,30.92-105.77,26.3L146,101.33Zm31.46-84.3L174,106.7,157.32,90l46.55-46.56a11.8,11.8,0,0,1,16.69,16.69Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M229,35a23.83,23.83,0,0,0-33.66,0L21.17,209.22a4,4,0,0,0,1.93,6.72A156.23,156.23,0,0,0,58.4,220c39.41,0,72.27-15.08,94.78-29.6,31.21-20.14,49.23-43,50-43.91a4,4,0,0,0-.32-5.29L179.67,118,229,68.63A23.84,23.84,0,0,0,229,35ZM194.59,144.27a222.43,222.43,0,0,1-46,39.55c-37.85,24.35-77,33-116.55,25.81L146,95.67ZM223.38,63,174,112.36,151.66,90,201,40.62A15.8,15.8,0,0,1,223.38,63Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
