/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M232,212H195.83A107.94,107.94,0,1,0,128,236l104,0a12,12,0,1,0,0-24ZM128,44a83.3,83.3,0,0,1,34.9,7.63Q153.21,56.45,144,62.14a227.58,227.58,0,0,0-32.13-16.58A84.49,84.49,0,0,1,128,44ZM79.33,59.6A201.6,201.6,0,0,1,122.5,77.31q-6.73,5.35-13,11.2A185.48,185.48,0,0,0,65,72.49,85,85,0,0,1,79.33,59.6ZM51.11,94.23a161.5,161.5,0,0,1,40.75,12.43q-5.44,6.25-10.44,12.9a145.86,145.86,0,0,0-35.37-9.92A83,83,0,0,1,51.11,94.23Zm2.76,73.23a83.49,83.49,0,0,1-9.66-33.83A122.15,122.15,0,0,1,67.79,140,228.76,228.76,0,0,0,53.87,167.46ZM71,189.67A205.38,205.38,0,0,1,186.1,67.42a85.36,85.36,0,0,1,11,12.86A187.87,187.87,0,0,0,84.55,199.86,84.08,84.08,0,0,1,71,189.67Zm51.67,22.15a84.08,84.08,0,0,1-16-2.55,163.87,163.87,0,0,1,101.07-107.4,83.43,83.43,0,0,1,3.52,15.78A148.76,148.76,0,0,0,122.71,211.82Zm26.4-2.51a124.78,124.78,0,0,1,61.31-65.15A84.25,84.25,0,0,1,149.11,209.31Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M224,128a96,96,0,1,1-96-96A96,96,0,0,1,224,128Z" opacity="0.2"/><path d="M232,216H183.39A103.95,103.95,0,1,0,128,232l104,0a8,8,0,1,0,0-16ZM128,40a87.51,87.51,0,0,1,43.93,11.77,222.06,222.06,0,0,0-27.88,15.09,222.23,222.23,0,0,0-45-22A87.52,87.52,0,0,1,128,40ZM78.56,55.24a206,206,0,0,1,51.11,21.57A225.76,225.76,0,0,0,110.1,93.36,181.54,181.54,0,0,0,57.73,75.09,88.67,88.67,0,0,1,78.56,55.24ZM48.72,89.82a165.82,165.82,0,0,1,49.67,15.51A228,228,0,0,0,82.76,124.5,142.65,142.65,0,0,0,41.28,113,87.5,87.5,0,0,1,48.72,89.82ZM40,129a126.07,126.07,0,0,1,33.63,9,222.36,222.36,0,0,0-19.07,38.45A87.51,87.51,0,0,1,40,129Zm26.42,61.81A209.36,209.36,0,0,1,187,62.74a89,89,0,0,1,16.22,19.57A183.89,183.89,0,0,0,87,205.82,88.56,88.56,0,0,1,66.43,190.81ZM125.66,216A87.66,87.66,0,0,1,101.83,212,167.84,167.84,0,0,1,210.28,96.79a87.35,87.35,0,0,1,5.38,23.55A144.59,144.59,0,0,0,125.66,216Zm89.82-78.44a88.19,88.19,0,0,1-72.67,77.22A128.64,128.64,0,0,1,215.48,137.53Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M69.4,144.89a220.94,220.94,0,0,0-18.77,42.6,4,4,0,0,1-7,1.19,103.44,103.44,0,0,1-18.83-48.11,4,4,0,0,1,4.13-4.47A119,119,0,0,1,69.4,144.89ZM44,77.14a180.06,180.06,0,0,1,63,19.12,226.9,226.9,0,0,1,22.6-19.49,206.42,206.42,0,0,0-69.28-25.6,4,4,0,0,0-3.42,1A105.3,105.3,0,0,0,41.08,71,4,4,0,0,0,44,77.14ZM239.58,221.35A7.91,7.91,0,0,0,232,216H183.36A104.25,104.25,0,0,0,230.25,147a4,4,0,0,0-5.27-4.52A120.6,120.6,0,0,0,150.88,216H134a136.55,136.55,0,0,1,94.78-91.37,4,4,0,0,0,2.92-4.15,102.59,102.59,0,0,0-3.58-20.56,4,4,0,0,0-4.89-2.8A164.53,164.53,0,0,0,103,225a4,4,0,0,0,3.08,4.69A103.9,103.9,0,0,0,128,232h0l104,0A8,8,0,0,0,239.58,221.35Zm-211-101.27a134.51,134.51,0,0,1,49.39,11A224.44,224.44,0,0,1,95.52,108.4,164.28,164.28,0,0,0,33.36,92.28a4,4,0,0,0-4,2.75,103,103,0,0,0-4.63,20.61A4,4,0,0,0,28.57,120.08ZM85.84,40.66A222.81,222.81,0,0,1,144,66.8a221.3,221.3,0,0,1,38.8-19.67,4,4,0,0,0,.7-7.08,103.86,103.86,0,0,0-98.2-6.85A4,4,0,0,0,85.84,40.66ZM216,82.51a4,4,0,0,0,2.4-5.87,105,105,0,0,0-12.82-17.81,4,4,0,0,0-4.21-1.19A208.81,208.81,0,0,0,62.21,205.51a4,4,0,0,0,1.44,4.13A104.25,104.25,0,0,0,82.2,221.36a4,4,0,0,0,5.71-2.75A180.61,180.61,0,0,1,216,82.51Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M232,218H176a102,102,0,1,0-48,12l104,0a6,6,0,1,0,0-12ZM128,38a89.53,89.53,0,0,1,48.24,14,220.06,220.06,0,0,0-32.19,17.19A220.38,220.38,0,0,0,93.2,45,89.65,89.65,0,0,1,128,38ZM78.2,53.07a208.61,208.61,0,0,1,55.09,23.57A223.79,223.79,0,0,0,110.45,95.8,180,180,0,0,0,54.23,76.5,90.85,90.85,0,0,1,78.2,53.07ZM47.58,87.62a168.13,168.13,0,0,1,54.07,17.11A223.44,223.44,0,0,0,83.42,127,140.43,140.43,0,0,0,39,114.79,89.53,89.53,0,0,1,47.58,87.62Zm7.53,93.11A89.49,89.49,0,0,1,38,128c0-.42,0-.83,0-1.25A128.5,128.5,0,0,1,76.6,137.08,220.45,220.45,0,0,0,55.11,180.73Zm9,10.64A211.27,211.27,0,0,1,187.39,60.44a90.62,90.62,0,0,1,18.76,23A181.86,181.86,0,0,0,88.22,208.71,90.4,90.4,0,0,1,64.15,191.37Zm63,26.62a89.71,89.71,0,0,1-27.75-4.65,169.86,169.86,0,0,1,112-119,89.12,89.12,0,0,1,6.34,27.41A142.57,142.57,0,0,0,127.15,218Zm90.62-83.53a90.15,90.15,0,0,1-77.88,82.75A130.62,130.62,0,0,1,217.77,134.46Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M232,216H183.39A103.95,103.95,0,1,0,128,232l104,0a8,8,0,1,0,0-16ZM128,40a87.51,87.51,0,0,1,43.93,11.77,222.06,222.06,0,0,0-27.88,15.09,222.23,222.23,0,0,0-45-22A87.52,87.52,0,0,1,128,40ZM78.56,55.24a206,206,0,0,1,51.11,21.57A225.76,225.76,0,0,0,110.1,93.36,181.54,181.54,0,0,0,57.73,75.09,88.67,88.67,0,0,1,78.56,55.24ZM48.72,89.82a165.82,165.82,0,0,1,49.67,15.51A228,228,0,0,0,82.76,124.5,142.65,142.65,0,0,0,41.28,113,87.5,87.5,0,0,1,48.72,89.82ZM40,129a126.07,126.07,0,0,1,33.63,9,222.36,222.36,0,0,0-19.07,38.45A87.51,87.51,0,0,1,40,129Zm26.42,61.81A209.36,209.36,0,0,1,187,62.74a89,89,0,0,1,16.22,19.57A183.89,183.89,0,0,0,87,205.82,88.56,88.56,0,0,1,66.43,190.81ZM125.66,216A87.66,87.66,0,0,1,101.83,212,167.84,167.84,0,0,1,210.28,96.79a87.35,87.35,0,0,1,5.38,23.55A144.59,144.59,0,0,0,125.66,216Zm89.82-78.44a88.19,88.19,0,0,1-72.67,77.22A128.64,128.64,0,0,1,215.48,137.53Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M232,220h0l-64.79,0A100,100,0,1,0,128,228l104,0a4,4,0,0,0,0-8ZM128,36a91.5,91.5,0,0,1,52.43,16.44,219.41,219.41,0,0,0-36.38,19.18A218.63,218.63,0,0,0,87.63,45.35,91.31,91.31,0,0,1,128,36ZM77.85,50.91a210.45,210.45,0,0,1,59,25.6,220.83,220.83,0,0,0-26.07,21.77A177.81,177.81,0,0,0,50.81,78,92.59,92.59,0,0,1,77.85,50.91ZM46.44,85.46a169.84,169.84,0,0,1,58.47,18.73,221.81,221.81,0,0,0-20.84,25.32,138.5,138.5,0,0,0-47.36-12.94A91.36,91.36,0,0,1,46.44,85.46Zm9.32,99.44A91.54,91.54,0,0,1,36,128c0-1.16,0-2.3.07-3.45a130.5,130.5,0,0,1,43.46,11.67A218.37,218.37,0,0,0,55.76,184.9Zm6.12,7A213.32,213.32,0,0,1,187.79,58.13a92.71,92.71,0,0,1,21.28,26.42,179.87,179.87,0,0,0-119.53,127A92.65,92.65,0,0,1,61.88,191.9ZM128,220a91.62,91.62,0,0,1-31-5.37A171.82,171.82,0,0,1,212.59,91.84a91.29,91.29,0,0,1,7.28,31.28A140.54,140.54,0,0,0,128.69,220Zm91.92-88.45a92.13,92.13,0,0,1-82.81,88A132.58,132.58,0,0,1,219.92,131.55Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
