import { template } from "@ember/template-compiler";
import { Button } from '@hokulea/ember';
import { buildAssistantLink } from '../../../assistants';
import type { ExerciseMediaDancemix } from '../../domain-objects';
import type { TOC } from '@ember/component/template-only';
const nonAssistantParamKeys = [
    '__typename',
    'name',
    'label'
];
const filterParams = (assistant1: ExerciseMediaDancemix)=>{
    if (!assistant1) return {};
    const params1: Record<string, unknown> = {};
    for (const [k1, v1] of Object.entries(assistant1)){
        if (!nonAssistantParamKeys.includes(k1)) {
            params1[k1] = v1;
        }
    }
    return params1;
};
interface DanceMixSignature {
    Args: {
        media: ExerciseMediaDancemix;
    };
}
export const DanceMix: TOC<DanceMixSignature> = template(`
  {{#let (buildAssistantLink "dance-mix" (filterParams @media)) as |link|}}
    <Button @push={{link}}>
      {{#if @media.label}}{{@media.label}}{{else}}Dance Mix{{/if}}
    </Button>
  {{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
