/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M178,78A84,84,0,1,0,78,178,84,84,0,1,0,178,78Zm42,82a60.75,60.75,0,0,1-.38,6.65l-44-44a83.31,83.31,0,0,0,4-19.34A60.09,60.09,0,0,1,220,160Zm-119.84-4.16a60.07,60.07,0,0,1,55.68-55.68A60.07,60.07,0,0,1,100.16,155.84Zm52.51-79.5a83.31,83.31,0,0,0-19.34,4l-44-44A60.75,60.75,0,0,1,96,36,60.09,60.09,0,0,1,152.67,76.34ZM64.19,45.16l46.73,46.73a85,85,0,0,0-19,19L45.16,64.19A60.45,60.45,0,0,1,64.19,45.16ZM36,96a60.75,60.75,0,0,1,.38-6.65l44,44a83.31,83.31,0,0,0-4,19.34A60.09,60.09,0,0,1,36,96Zm67.33,83.66a83.31,83.31,0,0,0,19.34-4l44,44A60.75,60.75,0,0,1,160,220,60.09,60.09,0,0,1,103.33,179.66Zm88.48,31.18-46.73-46.73a85,85,0,0,0,19-19l46.73,46.73A60.45,60.45,0,0,1,191.81,210.84Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M88,160a73.37,73.37,0,0,0,.4,7.6,72,72,0,1,1,79.2-79.2A73.37,73.37,0,0,0,160,88,72,72,0,0,0,88,160Zm79.6-71.6A73.37,73.37,0,0,1,168,96a72,72,0,0,1-72,72,73.37,73.37,0,0,1-7.6-.4,72,72,0,1,0,79.2-79.2Z" opacity="0.2"/><path d="M174.63,81.37a80,80,0,1,0-93.26,93.26,80,80,0,1,0,93.26-93.26ZM32,96a64,64,0,0,1,126-16A80.08,80.08,0,0,0,80.05,158,64.11,64.11,0,0,1,32,96Zm128,0a64.07,64.07,0,0,1-64,64A64.07,64.07,0,0,1,160,96Zm0,128A64.11,64.11,0,0,1,98,176,80.08,80.08,0,0,0,176,98,64,64,0,0,1,160,224Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M240,160A80,80,0,0,1,81.36,174.64a80,80,0,0,0,93.28-93.28A80,80,0,0,1,240,160ZM160,80a80.29,80.29,0,0,1,14.64,1.36,80,80,0,1,0-93.28,93.28A80,80,0,0,1,160,80Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M172.91,83.09a78,78,0,1,0-89.82,89.82,78,78,0,1,0,89.82-89.82ZM226,160a65.31,65.31,0,0,1-.62,8.9l-53.76-53.77A77.84,77.84,0,0,0,174,96c0-.17,0-.33,0-.49A66.1,66.1,0,0,1,226,160ZM45.31,53.79l55.5,55.5a77.86,77.86,0,0,0-12,19L34,73.48A66,66,0,0,1,45.31,53.79ZM73.48,34l54.8,54.81a77.86,77.86,0,0,0-19,12l-55.5-55.5A66,66,0,0,1,73.48,34ZM94,160a66.08,66.08,0,0,1,66-66c.65,0,1.3,0,1.95,0,0,.65.05,1.3.05,2a66.08,66.08,0,0,1-66,66c-.65,0-1.3,0-2-.05C94,161.3,94,160.65,94,160Zm52.71-4.81,55.5,55.5A66,66,0,0,1,182.52,222l-54.8-54.81A77.86,77.86,0,0,0,146.71,155.19Zm8.48-8.48a77.86,77.86,0,0,0,12-19L222,182.52a66,66,0,0,1-11.35,19.69Zm5.3-64.7H160a77.84,77.84,0,0,0-19.13,2.38L87.1,30.62A65.31,65.31,0,0,1,96,30,66.1,66.1,0,0,1,160.49,82ZM30,96a65.31,65.31,0,0,1,.62-8.9l53.76,53.77A77.84,77.84,0,0,0,82,160c0,.17,0,.33,0,.49A66.1,66.1,0,0,1,30,96Zm65.51,78H96a77.84,77.84,0,0,0,19.13-2.38l53.77,53.76a65.31,65.31,0,0,1-8.9.62A66.1,66.1,0,0,1,95.51,174Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M174.63,81.37a80,80,0,1,0-93.26,93.26,80,80,0,1,0,93.26-93.26ZM224,160c0,1.52-.07,3-.18,4.51l-50-50A80,80,0,0,0,176,98,64.11,64.11,0,0,1,224,160Zm-13.47,39.21L157.91,146.6a80.5,80.5,0,0,0,9.93-15.44L219.7,183A64,64,0,0,1,210.53,199.21ZM183,219.7l-51.86-51.86a80.5,80.5,0,0,0,15.44-9.93l52.61,52.62A64,64,0,0,1,183,219.7ZM45.47,56.79,98.09,109.4a80.5,80.5,0,0,0-9.93,15.44L36.3,73A64,64,0,0,1,45.47,56.79ZM73,36.3l51.86,51.86a80.5,80.5,0,0,0-15.44,9.93L56.79,45.47A64,64,0,0,1,73,36.3ZM160,96a64.07,64.07,0,0,1-64,64A64.07,64.07,0,0,1,160,96Zm-2-16a80,80,0,0,0-16.49,2.13l-50-50C93,32.07,94.48,32,96,32A64.11,64.11,0,0,1,158,80.05ZM32,96c0-1.52.07-3,.18-4.51l50,50A80,80,0,0,0,80.05,158,64.11,64.11,0,0,1,32,96ZM98,176a80,80,0,0,0,16.49-2.13l50,50c-1.49.11-3,.18-4.51.18A64.11,64.11,0,0,1,98,176Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M171.17,84.83a76,76,0,1,0-86.34,86.34,76,76,0,1,0,86.34-86.34ZM228,160a68.63,68.63,0,0,1-1.27,13.07l-57.34-57.34A76,76,0,0,0,172,96c0-1,0-2-.07-2.93A68.1,68.1,0,0,1,228,160Zm-17.2,45.14-58.34-58.33a76.35,76.35,0,0,0,14.09-22.6L224.33,182A68.14,68.14,0,0,1,210.8,205.14ZM182,224.33l-57.78-57.78a76.35,76.35,0,0,0,22.6-14.09l58.33,58.34A68.14,68.14,0,0,1,182,224.33ZM45.2,50.86l58.34,58.33a76.35,76.35,0,0,0-14.09,22.6L31.67,74A68.14,68.14,0,0,1,45.2,50.86ZM74,31.67l57.78,57.78a76.35,76.35,0,0,0-22.6,14.09L50.86,45.2A68.14,68.14,0,0,1,74,31.67ZM92,160a68.07,68.07,0,0,1,68-68c1.3,0,2.6,0,3.88.12C164,93.4,164,94.7,164,96a68.07,68.07,0,0,1-68,68c-1.3,0-2.6-.05-3.88-.12C92.05,162.6,92,161.3,92,160Zm70.93-75.93c-1,0-2-.07-2.93-.07a76,76,0,0,0-19.73,2.61L82.93,29.27a68,68,0,0,1,80,54.8ZM28,96a68.63,68.63,0,0,1,1.27-13.07l57.34,57.34A76,76,0,0,0,84,160c0,1,0,2,.07,2.93A68.1,68.1,0,0,1,28,96Zm65.07,75.93c1,0,2,.07,2.93.07a76,76,0,0,0,19.73-2.61l57.34,57.34a68,68,0,0,1-80-54.8Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
