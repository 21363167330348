/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M219.29,31.5a20,20,0,0,0-18.52-2.14C187,34.72,159.17,43.7,128,43.7s-59-9-72.77-14.34A20,20,0,0,0,28,48v55.77c0,36.64,9.9,71.25,27.88,97.45C74.81,228.81,100.42,244,128,244s53.19-15.19,72.12-42.76C218.1,175,228,140.43,228,103.79V48A20,20,0,0,0,219.29,31.5ZM204,103.79c0,31.84-8.41,61.63-23.67,83.87C166,208.51,147.43,220,128,220s-38-11.49-52.33-32.34C60.41,165.42,52,135.63,52,103.79v-50c16.59,5.95,44.67,13.94,76,13.94s59.41-8,76-13.94Zm-45.62,83.72a12,12,0,0,1-16.4,4.37,27.13,27.13,0,0,0-28,0,12,12,0,1,1-12-20.76,51.14,51.14,0,0,1,52,0A12,12,0,0,1,158.38,187.51Zm-20.49-74a12,12,0,0,1,16.59-3.61,19.15,19.15,0,0,0,9.52,2.78,19.64,19.64,0,0,0,7.27-1.59,12,12,0,1,1,9.46,22.06,41.58,41.58,0,0,1-39.23-3.06A12,12,0,0,1,137.89,113.51ZM114.5,130.09a41.54,41.54,0,0,1-39.23,3,12,12,0,1,1,9.46-22A19.64,19.64,0,0,0,92,112.68a19.22,19.22,0,0,0,9.54-2.78,12,12,0,1,1,13,20.19Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M216,48v55.77C216,174.6,176.6,232,128,232S40,174.6,40,103.79V48a8,8,0,0,1,10.89-7.47C66,46.41,95.11,55.71,128,55.71s62-9.3,77.11-15.16A8,8,0,0,1,216,48Z" opacity="0.2"/><path d="M158.66,188.43a8,8,0,0,1-11.09,2.23C141.07,186.34,136,184,128,184s-13.07,2.34-19.57,6.66a8,8,0,0,1-8.86-13.32C108,171.73,116.06,168,128,168s20,3.73,28.43,9.34A8,8,0,0,1,158.66,188.43ZM189.34,114a8,8,0,0,0-11.3.62c-2.68,3-8.85,5.34-14,5.34s-11.36-2.35-14-5.34A8,8,0,0,0,138,125.33c5.71,6.38,16.14,10.67,26,10.67s20.25-4.29,26-10.67A8,8,0,0,0,189.34,114ZM224,48v55.77c0,35.84-9.65,69.65-27.18,95.18-18.16,26.46-42.6,41-68.82,41s-50.66-14.57-68.82-41C41.65,173.44,32,139.63,32,103.79V48A16,16,0,0,1,53.79,33.09C67.84,38.55,96.18,47.71,128,47.71s60.15-9.16,74.21-14.62A16,16,0,0,1,224,48Zm-16,0v0c-15.1,5.89-45.57,15.73-80,15.73S63.1,53.87,48,48v55.79c0,32.64,8.66,63.23,24.37,86.13C87.46,211.9,107.21,224,128,224s40.54-12.1,55.63-34.08C199.34,167,208,136.43,208,103.79Zm-90,77.31A8,8,0,0,0,106,114.66c-2.68,3-8.85,5.34-14,5.34s-11.36-2.35-14-5.34A8,8,0,0,0,66,125.33C71.75,131.71,82.18,136,92,136S112.25,131.71,118,125.33Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M217,34.8a15.94,15.94,0,0,0-14.82-1.71C188.15,38.55,159.82,47.71,128,47.71S67.84,38.55,53.79,33.09A16,16,0,0,0,32,48v55.77c0,35.84,9.65,69.65,27.18,95.18,18.16,26.46,42.6,41,68.82,41s50.66-14.57,68.82-41C214.35,173.44,224,139.63,224,103.79V48A16,16,0,0,0,217,34.8ZM66,125.33A8,8,0,0,1,78,114.66c2.68,3,8.85,5.34,14,5.34s11.36-2.35,14-5.34A8,8,0,0,1,118,125.33C112.25,131.71,101.82,136,92,136S71.75,131.71,66,125.33Zm92.62,63.1A8,8,0,0,1,152,192a7.92,7.92,0,0,1-4.42-1.34C141.07,186.34,136,184,128,184s-13.07,2.34-19.57,6.66a8,8,0,0,1-8.86-13.32C108,171.73,116.06,168,128,168s20,3.73,28.43,9.34A8,8,0,0,1,158.66,188.43Zm31.3-63.1c-5.71,6.38-16.14,10.67-26,10.67s-20.25-4.29-26-10.67A8,8,0,0,1,150,114.66c2.68,3,8.85,5.34,14,5.34s11.36-2.35,14-5.34A8,8,0,0,1,190,125.33Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M157,187.32a6,6,0,0,1-8.32,1.67c-6.67-4.43-12.19-7-20.68-7s-14,2.56-20.68,7a6,6,0,1,1-6.64-10c8.13-5.4,15.88-9,27.32-9s19.19,3.6,27.32,9A6,6,0,0,1,157,187.32ZM179.53,116c-3.06,3.42-9.74,6-15.53,6s-12.47-2.58-15.53-6a6,6,0,1,0-8.94,8c5.35,6,15.19,10,24.47,10s19.12-4,24.47-10a6,6,0,1,0-8.94-8ZM222,48v55.77c0,35.45-9.53,68.86-26.83,94.06C177.4,223.74,153.54,238,128,238s-49.4-14.26-67.17-40.16C43.53,172.64,34,139.23,34,103.78V48A14,14,0,0,1,53.06,35C67.25,40.46,95.86,49.7,128,49.7S188.75,40.46,202.94,35A14,14,0,0,1,222,48Zm-12,0a2,2,0,0,0-2.72-1.87C192.31,52,162.1,61.7,128,61.7S63.69,52,48.72,46.14a2,2,0,0,0-1.85.21A2,2,0,0,0,46,48v55.76C46,171.17,82.79,226,128,226s82-54.83,82-122.22Zm-93.53,76a6,6,0,1,0-8.94-8c-3.06,3.42-9.74,6-15.53,6s-12.47-2.58-15.53-6a6,6,0,0,0-8.94,8c5.35,6,15.19,10,24.47,10S111.12,130,116.47,124Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M158.66,188.43a8,8,0,0,1-11.09,2.23C141.07,186.34,136,184,128,184s-13.07,2.34-19.57,6.66a8,8,0,0,1-8.86-13.32C108,171.73,116.06,168,128,168s20,3.73,28.43,9.34A8,8,0,0,1,158.66,188.43ZM224,48v55.77c0,35.84-9.65,69.65-27.18,95.18-18.16,26.46-42.6,41-68.82,41s-50.66-14.57-68.82-41C41.65,173.44,32,139.63,32,103.79V48A16,16,0,0,1,53.79,33.09C67.84,38.55,96.18,47.71,128,47.71s60.15-9.16,74.21-14.62A16,16,0,0,1,224,48Zm-16,0v0c-15.1,5.89-45.57,15.73-80,15.73S63.1,53.87,48,48v55.79c0,32.64,8.66,63.23,24.37,86.13C87.46,211.9,107.21,224,128,224s40.54-12.1,55.63-34.08C199.34,167,208,136.43,208,103.79Zm-18.66,66a8,8,0,0,0-11.3.62c-2.68,3-8.85,5.34-14,5.34s-11.36-2.35-14-5.34A8,8,0,0,0,138,125.33c5.71,6.38,16.14,10.67,26,10.67s20.25-4.29,26-10.67A8,8,0,0,0,189.34,114ZM118,125.33A8,8,0,0,0,106,114.66c-2.68,3-8.85,5.34-14,5.34s-11.36-2.35-14-5.34A8,8,0,0,0,66,125.33C71.75,131.71,82.18,136,92,136S112.25,131.71,118,125.33Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M155.33,186.21a4,4,0,0,1-5.54,1.12C142.8,182.69,137,180,128,180s-14.8,2.69-21.79,7.33a4,4,0,1,1-4.42-6.66c7.82-5.21,15.28-8.67,26.21-8.67s18.39,3.46,26.21,8.67A4,4,0,0,1,155.33,186.21ZM181,117.33c-3.4,3.8-10.72,6.67-17,6.67s-13.62-2.87-17-6.67a4,4,0,0,0-6,5.33c4.92,5.5,14.37,9.34,23,9.34s18.06-3.84,23-9.33a4,4,0,1,0-6-5.34ZM220,48v55.78c0,35-9.4,68-26.48,92.92C176.13,222.05,152.86,236,128,236s-48.13-13.95-65.52-39.29C45.4,171.83,36,138.83,36,103.79V48a12,12,0,0,1,16.34-11.2C66.66,42.38,95.53,51.7,128,51.7s61.34-9.32,75.66-14.88A12,12,0,0,1,220,48Zm-8,0a4,4,0,0,0-5.44-3.74C191.72,50,161.77,59.7,128,59.7S64.28,50,49.44,44.27A4.14,4.14,0,0,0,48,44a3.87,3.87,0,0,0-2.23.7A4,4,0,0,0,44,48v55.77C44,172.28,81.68,228,128,228s84-55.72,84-124.21Zm-97,74.66a4,4,0,1,0-6-5.34c-3.4,3.8-10.72,6.67-17,6.67s-13.62-2.87-17-6.67a4,4,0,0,0-6,5.33c4.92,5.5,14.37,9.34,23,9.34S110.06,128.16,115,122.67Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
