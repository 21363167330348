/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M232.63,70a19.82,19.82,0,0,0-23.55,4.71l-29.52,31.82L146.22,31.76l-.06-.14a20,20,0,0,0-36.32,0l-.06.14L76.44,106.52,46.92,74.7a20,20,0,0,0-34.6,16.81c0,.16.06.31.09.47L35.07,195.76A20,20,0,0,0,54.71,212H201.29a20,20,0,0,0,19.64-16.24L243.59,92c0-.16.07-.31.09-.47A19.82,19.82,0,0,0,232.63,70ZM198.06,188H57.94L39.06,101.51,71.2,136.16A12,12,0,0,0,91,132.89l37-83.07,37,83.07a12,12,0,0,0,19.76,3.27l32.14-34.65Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M231.87,89.42l-22.7,104a8,8,0,0,1-7.88,6.61H54.71a8,8,0,0,1-7.88-6.61l-22.7-104A8,8,0,0,1,38,82.76L80,128l40.74-91.35a8,8,0,0,1,14.52,0L176,128l42-45.24A8,8,0,0,1,231.87,89.42Z" opacity="0.2"/><path d="M230.9,73.6A15.85,15.85,0,0,0,212,77.39l-33.67,36.29-35.8-80.29a1,1,0,0,1,0-.1,16,16,0,0,0-29.06,0,1,1,0,0,1,0,.1l-35.8,80.29L44,77.39A16,16,0,0,0,16.25,90.81c0,.11,0,.21.07.32L39,195a16,16,0,0,0,15.72,13H201.29A16,16,0,0,0,217,195L239.68,91.13c0-.11,0-.21.07-.32A15.85,15.85,0,0,0,230.9,73.6ZM201.35,191.68l-.06.32H54.71l-.06-.32L32,88l.14.16,42,45.24a8,8,0,0,0,13.18-2.18L128,40l40.69,91.25a8,8,0,0,0,13.18,2.18l42-45.24L224,88Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M239.75,90.81c0,.11,0,.21-.07.32L217,195a16,16,0,0,1-15.72,13H54.71A16,16,0,0,1,39,195L16.32,91.13c0-.11-.05-.21-.07-.32A16,16,0,0,1,44,77.39l33.67,36.29,35.8-80.29a1,1,0,0,0,0-.1,16,16,0,0,1,29.06,0,1,1,0,0,0,0,.1l35.8,80.29L212,77.39a16,16,0,0,1,27.71,13.42Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M230,75.4a13.87,13.87,0,0,0-16.52,3.34l-35.74,38.52L140.71,34.13a14,14,0,0,0-25.45.07l-37,83.06L42.48,78.74A14,14,0,0,0,18.22,90.46c0,.08,0,.16.05.24L41,194.57A14,14,0,0,0,54.71,206H201.29a14,14,0,0,0,13.76-11.43L237.73,90.7c0-.08,0-.16.05-.24A13.89,13.89,0,0,0,230,75.4ZM226,88.29,203.31,192.11a2,2,0,0,0-.05.24,2,2,0,0,1-2,1.65H54.71a2,2,0,0,1-2-1.65,2,2,0,0,0-.05-.24L30,88.29a1.82,1.82,0,0,1,1.12-2.06,1.84,1.84,0,0,1,2.36.48l.12.13,42,45.24a6,6,0,0,0,9.88-1.64l40.7-91.28A1.92,1.92,0,0,1,128,38a1.87,1.87,0,0,1,1.78,1.09l40.74,91.35a6,6,0,0,0,9.88,1.64l42-45.24.12-.13a1.84,1.84,0,0,1,2.36-.48A1.82,1.82,0,0,1,226,88.29Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M230.9,73.6A15.85,15.85,0,0,0,212,77.39l-33.67,36.29-35.8-80.29a1,1,0,0,1,0-.1,16,16,0,0,0-29.06,0,1,1,0,0,1,0,.1l-35.8,80.29L44,77.39A16,16,0,0,0,16.25,90.81c0,.11,0,.21.07.32L39,195a16,16,0,0,0,15.72,13H201.29A16,16,0,0,0,217,195L239.68,91.13c0-.11,0-.21.07-.32A15.85,15.85,0,0,0,230.9,73.6ZM201.35,191.68l-.06.32H54.71l-.06-.32L32,88l.14.16,42,45.24a8,8,0,0,0,13.18-2.18L128,40l40.69,91.25a8,8,0,0,0,13.18,2.18l42-45.24L224,88Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M229.18,77.21A11.89,11.89,0,0,0,215,80.08l-37.81,40.76L138.9,35a12,12,0,0,0-21.82.05L78.81,120.84,41,80.08a12,12,0,0,0-20.81,10l0,.15,22.69,103.9A12,12,0,0,0,54.71,204H201.29a12,12,0,0,0,11.8-9.83l22.69-103.9,0-.15A11.89,11.89,0,0,0,229.18,77.21Zm-1.24,11.45L205.26,192.54l0,.16a4,4,0,0,1-3.94,3.3H54.71a4,4,0,0,1-3.94-3.3l0-.16L28.06,88.66a4,4,0,0,1,7-3.26.3.3,0,0,0,.08.08l42,45.24a4,4,0,0,0,6.59-1.09l40.72-91.31a4,4,0,0,1,7.24,0l40.74,91.35a4,4,0,0,0,6.59,1.09l42-45.24a.3.3,0,0,0,.08-.08,4,4,0,0,1,7,3.26Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
