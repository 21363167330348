/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M228,160a12,12,0,0,1-12,12H104.22L56.88,224.07a12,12,0,0,1-17.76-16.14L71.78,172H40a12,12,0,0,1,0-24H93.6L130,108H40a12,12,0,0,1,0-24H151.78l47.34-52.07a12,12,0,0,1,17.76,16.14L184.22,84H216a12,12,0,0,1,0,24H162.4L126,148h90A12,12,0,0,1,228,160Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M216,56V200a16,16,0,0,1-16,16H56a16,16,0,0,1-16-16V56A16,16,0,0,1,56,40H200A16,16,0,0,1,216,56Z" opacity="0.2"/><path d="M224,160a8,8,0,0,1-8,8H102.45L53.92,221.38a8,8,0,0,1-11.84-10.76L80.82,168H40a8,8,0,0,1,0-16H95.37L139,104H40a8,8,0,0,1,0-16H153.55l48.53-53.38a8,8,0,0,1,11.84,10.76L175.18,88H216a8,8,0,0,1,0,16H160.63L117,152h99A8,8,0,0,1,224,160Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM184,144a8,8,0,0,1,0,16H110.63L78,197.27a8,8,0,0,1-12-10.54L89.37,160H72a8,8,0,0,1,0-16h31.37l28-32H72a8,8,0,0,1,0-16h73.37L178,58.73a8,8,0,1,1,12,10.54L166.63,96H184a8,8,0,0,1,0,16H152.63l-28,32Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M222,160a6,6,0,0,1-6,6H101.56L52.44,220A6,6,0,1,1,43.56,212l41.79-46H40a6,6,0,0,1,0-12H96.25l47.28-52H40a6,6,0,0,1,0-12H154.44l49.12-54A6,6,0,1,1,212.44,44L170.65,90H216a6,6,0,0,1,0,12H159.75l-47.28,52H216A6,6,0,0,1,222,160Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M224,160a8,8,0,0,1-8,8H102.45L53.92,221.38a8,8,0,0,1-11.84-10.76L80.82,168H40a8,8,0,0,1,0-16H95.37L139,104H40a8,8,0,0,1,0-16H153.55l48.53-53.38a8,8,0,0,1,11.84,10.76L175.18,88H216a8,8,0,0,1,0,16H160.63L117,152h99A8,8,0,0,1,224,160Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M220,160a4,4,0,0,1-4,4H100.68L51,218.69A4,4,0,0,1,45,213.31L89.87,164H40a4,4,0,0,1,0-8H97.14l50.91-56H40a4,4,0,0,1,0-8H155.32L205,37.31A4,4,0,0,1,211,42.69L166.13,92H216a4,4,0,0,1,0,8H158.86L108,156H216A4,4,0,0,1,220,160Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
