/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M188,104a12,12,0,0,1-12,12H80a12,12,0,0,1,0-24h96A12,12,0,0,1,188,104Zm67.91,27.63A76.29,76.29,0,0,1,182.43,204c-.56,0-1.13,0-1.69,0-14.44,0-29.37-5.78-45.58-17.64a12.08,12.08,0,0,0-14.32,0C104,198.68,88.56,204.44,73.57,204A76.29,76.29,0,0,1,.09,131.63,76,76,0,0,1,76,52H180a76,76,0,0,1,75.91,79.63ZM217.62,92.1A51.56,51.56,0,0,0,180,76H76a52,52,0,0,0-51.94,54.51A52.19,52.19,0,0,0,74.32,180c9.42.29,20-4,32.35-13a36,36,0,0,1,42.66,0c12.36,9,22.91,13.29,32.35,13a52.19,52.19,0,0,0,50.26-49.48A51.63,51.63,0,0,0,217.62,92.1Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M248,127.15A64,64,0,0,1,184,192c-15.48,0-29.68-9.35-40.75-18.5a23.91,23.91,0,0,0-30.45-.05c-11.81,9.79-27.19,19.26-43.89,18.48A64,64,0,0,1,72,64H183.05C218.22,64,247.54,92,248,127.15Z" opacity="0.2"/><path d="M183.05,56H72A72,72,0,0,0,.08,131.4c1.69,36.69,31.76,66.79,68.45,68.52,15.85.74,32-5.9,49.38-20.3a15.88,15.88,0,0,1,20.24,0C148.72,188.39,165,200,184,200a72,72,0,0,0,72-72.95C255.49,87.87,222.76,56,183.05,56Zm40.81,111.34A55.63,55.63,0,0,1,184,184c-13.88,0-27-9.51-35.65-16.67a31.91,31.91,0,0,0-40.65,0C93.52,179,81,184.49,69.28,183.94a56.36,56.36,0,0,1-53.22-53.28A56,56,0,0,1,72,72H183.05c31,0,56.55,24.79,56.95,55.25A55.66,55.66,0,0,1,223.86,167.34ZM184,96a8,8,0,0,1-8,8H80a8,8,0,0,1,0-16h96A8,8,0,0,1,184,96Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M183.05,56H72A72,72,0,0,0,.08,131.4c1.69,36.69,31.76,66.79,68.45,68.52,15.84.72,32-5.9,49.38-20.3a15.87,15.87,0,0,1,20.24,0C148.72,188.39,165,200,184,200a72,72,0,0,0,72-72.95C255.49,87.87,222.76,56,183.05,56ZM176,104H80a8,8,0,0,1,0-16h96a8,8,0,0,1,0,16Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M183.05,58H72A70,70,0,0,0,2.08,131.3C3.72,167,33,196.24,68.62,197.92c15.31.71,31-5.77,48-19.84a17.89,17.89,0,0,1,22.8,0C149.75,186.66,165.6,198,184,198a70,70,0,0,0,70-70.93C253.5,89,221.67,58,183.05,58Zm42.23,110.74A57.57,57.57,0,0,1,184,186c-14.53,0-28-9.77-36.93-17.13a29.9,29.9,0,0,0-38.09,0h0c-14.58,12.09-27.6,17.67-39.78,17.1a58.36,58.36,0,0,1-55.12-55.18A58,58,0,0,1,72,70H183.05c32.09,0,58.54,25.67,58.95,57.23A57.62,57.62,0,0,1,225.28,168.74ZM182,96a6,6,0,0,1-6,6H80a6,6,0,0,1,0-12h96A6,6,0,0,1,182,96Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M183.05,56H72A72,72,0,0,0,.08,131.4c1.69,36.69,31.76,66.79,68.45,68.52,15.85.74,32-5.9,49.38-20.3a15.88,15.88,0,0,1,20.24,0C148.72,188.39,165,200,184,200a72,72,0,0,0,72-72.95C255.49,87.87,222.76,56,183.05,56Zm40.81,111.34A55.63,55.63,0,0,1,184,184c-13.88,0-27-9.51-35.65-16.67a31.91,31.91,0,0,0-40.65,0C93.52,179,80.94,184.49,69.28,183.94a56.36,56.36,0,0,1-53.22-53.28A56,56,0,0,1,72,72H183.05c31,0,56.55,24.79,56.95,55.25A55.66,55.66,0,0,1,223.86,167.34ZM184,96a8,8,0,0,1-8,8H80a8,8,0,0,1,0-16h96A8,8,0,0,1,184,96Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M183.05,60H72A68,68,0,0,0,4.07,131.21c1.6,34.66,30,63.08,64.65,64.71,14.77.72,30-5.64,46.63-19.38a19.9,19.9,0,0,1,25.35,0C150.79,184.92,166.25,196,184,196a68,68,0,0,0,68-68.9C251.51,90.1,220.58,60,183.05,60ZM226.7,170.15A59.57,59.57,0,0,1,184,188c-15.17,0-29.07-10-38.2-17.58a27.88,27.88,0,0,0-35.55,0c-15,12.41-28.45,18.14-41.15,17.55a60.37,60.37,0,0,1-57-57.09A60,60,0,0,1,72,68H183.05c33.18,0,60.52,26.56,60.94,59.2A59.59,59.59,0,0,1,226.7,170.15ZM180,96a4,4,0,0,1-4,4H80a4,4,0,0,1,0-8h96A4,4,0,0,1,180,96Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
