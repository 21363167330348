/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M236,100H225L190.83,65.86A19.86,19.86,0,0,0,176.69,60H144V44h20a12,12,0,0,0,0-24H100a12,12,0,0,0,0,24h20V60H64A20,20,0,0,0,44,80v48H28V108a12,12,0,0,0-24,0v64a12,12,0,0,0,24,0V152H44v16.69a19.86,19.86,0,0,0,5.86,14.14l39.31,39.31A19.86,19.86,0,0,0,103.31,228h73.38a19.86,19.86,0,0,0,14.14-5.86L225,188h11a20,20,0,0,0,20-20V120A20,20,0,0,0,236,100Zm-4,64H220a12,12,0,0,0-8.49,3.51L175,204H105L68,167V84H175l36.48,36.49A12,12,0,0,0,220,124h12Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M248,120v48a8,8,0,0,1-8,8H224l-37.66,37.66a8,8,0,0,1-5.65,2.34H103.31a8,8,0,0,1-5.65-2.34L58.34,174.34A8,8,0,0,1,56,168.69V80a8,8,0,0,1,8-8H180.69a8,8,0,0,1,5.65,2.34L224,112h16A8,8,0,0,1,248,120Z" opacity="0.2"/><path d="M240,104H227.31L192,68.69A15.86,15.86,0,0,0,180.69,64H140V40h24a8,8,0,0,0,0-16H100a8,8,0,0,0,0,16h24V64H64A16,16,0,0,0,48,80v52H24V108a8,8,0,0,0-16,0v64a8,8,0,0,0,16,0V148H48v20.69A15.86,15.86,0,0,0,52.69,180L92,219.31A15.86,15.86,0,0,0,103.31,224h77.38A15.86,15.86,0,0,0,192,219.31L227.31,184H240a16,16,0,0,0,16-16V120A16,16,0,0,0,240,104Zm0,64H224a8,8,0,0,0-5.66,2.34L180.69,208H103.31L64,168.69V80H180.69l37.65,37.66A8,8,0,0,0,224,120h16Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M256,120v48a16,16,0,0,1-16,16H227.31L192,219.31A15.86,15.86,0,0,1,180.69,224H103.31A15.86,15.86,0,0,1,92,219.31L52.69,180A15.86,15.86,0,0,1,48,168.69V148H24v24a8,8,0,0,1-16,0V108a8,8,0,0,1,16,0v24H48V80A16,16,0,0,1,64,64h60V40H100a8,8,0,0,1,0-16h64a8,8,0,0,1,0,16H140V64h40.69A15.86,15.86,0,0,1,192,68.69L227.31,104H240A16,16,0,0,1,256,120Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M240,106H226.49L190.58,70.1a13.9,13.9,0,0,0-9.89-4.1H138V38h26a6,6,0,0,0,0-12H100a6,6,0,0,0,0,12h26V66H64A14,14,0,0,0,50,80v54H22V108a6,6,0,0,0-12,0v64a6,6,0,0,0,12,0V146H50v22.69a13.9,13.9,0,0,0,4.1,9.89L93.42,217.9a13.9,13.9,0,0,0,9.89,4.1h77.38a13.9,13.9,0,0,0,9.89-4.1L226.49,182H240a14,14,0,0,0,14-14V120A14,14,0,0,0,240,106Zm2,62a2,2,0,0,1-2,2H224a6,6,0,0,0-4.24,1.76L182.1,209.42a2,2,0,0,1-1.41.58H103.31a2,2,0,0,1-1.41-.58L62.58,170.1a2,2,0,0,1-.58-1.41V80a2,2,0,0,1,2-2H180.69a2,2,0,0,1,1.41.58l37.66,37.66A6,6,0,0,0,224,118h16a2,2,0,0,1,2,2Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M240,104H227.31L192,68.69A15.86,15.86,0,0,0,180.69,64H140V40h24a8,8,0,0,0,0-16H100a8,8,0,0,0,0,16h24V64H64A16,16,0,0,0,48,80v52H24V108a8,8,0,0,0-16,0v64a8,8,0,0,0,16,0V148H48v20.69A15.86,15.86,0,0,0,52.69,180L92,219.31A15.86,15.86,0,0,0,103.31,224h77.38A15.86,15.86,0,0,0,192,219.31L227.31,184H240a16,16,0,0,0,16-16V120A16,16,0,0,0,240,104Zm0,64H224a8,8,0,0,0-5.66,2.34L180.69,208H103.31L64,168.69V80H180.69l37.65,37.66A8,8,0,0,0,224,120h16Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M240,108H225.66L189.17,71.51A11.93,11.93,0,0,0,180.69,68H136V36h28a4,4,0,0,0,0-8H100a4,4,0,0,0,0,8h28V68H64A12,12,0,0,0,52,80v56H20V108a4,4,0,0,0-8,0v64a4,4,0,0,0,8,0V144H52v24.69a11.93,11.93,0,0,0,3.51,8.48l39.32,39.32a11.93,11.93,0,0,0,8.48,3.51h77.38a11.93,11.93,0,0,0,8.48-3.51L225.66,180H240a12,12,0,0,0,12-12V120A12,12,0,0,0,240,108Zm4,60a4,4,0,0,1-4,4H224a4,4,0,0,0-2.83,1.17l-37.66,37.66a4,4,0,0,1-2.82,1.17H103.31a4,4,0,0,1-2.82-1.17L61.17,171.51A4,4,0,0,1,60,168.69V80a4,4,0,0,1,4-4H180.69a4,4,0,0,1,2.82,1.17l37.66,37.66A4,4,0,0,0,224,116h16a4,4,0,0,1,4,4Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
