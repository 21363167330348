import { template } from "@ember/template-compiler";
import { Loop } from '../../../assistants';
import { data } from '../../../assistants/looper/data';
import type { ExerciseMediaLoop } from '../../domain-objects';
import type { TOC } from '@ember/component/template-only';
function load(id1: string) {
    const [trackId1, loop1] = id1.split('.');
    const track1 = data.find((t1)=>t1.id === trackId1);
    return {
        track: track1,
        loop: loop1
    };
}
interface LoopSignature {
    Args: {
        media: ExerciseMediaLoop;
    };
}
export const Looper: TOC<LoopSignature> = template(`
  {{#if @media.name}}
    {{#let (load @media.name) as |l|}}
      {{#if l.track}}
        <Loop @track={{l.track}} @loop={{l.loop}} />
      {{/if}}
    {{/let}}
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
