import { template } from "@ember/template-compiler";
import { isAuthenticated } from '../abilities';
import { LoginWithSpotify } from './login-with-spotify';
import type { TOC } from '@ember/component/template-only';
const WithSpotify: TOC<{
    Blocks: {
        default: [];
    };
}> = template(`
  {{#if (isAuthenticated)}}
    {{yield}}
  {{else}}
    <LoginWithSpotify/>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export { WithSpotify };
