/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M197.35,51.62l11.14-11.13a12,12,0,1,0-17-17L179.34,35.69a91.84,91.84,0,0,0-102.68,0L64.49,23.51a12,12,0,0,0-17,17L58.65,51.62A91.58,91.58,0,0,0,36,112v40a92,92,0,0,0,184,0V112A91.58,91.58,0,0,0,197.35,51.62ZM196,112v4H60v-4a68,68,0,0,1,136,0ZM128,220a68.07,68.07,0,0,1-68-68V140H196v12A68.07,68.07,0,0,1,128,220ZM140,88a16,16,0,1,1,16,16A16,16,0,0,1,140,88ZM84,88a16,16,0,1,1,16,16A16,16,0,0,1,84,88Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M208,128v24a80,80,0,0,1-160,0V128Z" opacity="0.2"/><path d="M191.83,51.48l13.83-13.82a8,8,0,0,0-11.32-11.32L179.79,40.9a87.81,87.81,0,0,0-103.58,0L61.66,26.34A8,8,0,0,0,50.34,37.66L64.17,51.48A87.72,87.72,0,0,0,40,112v40a88,88,0,0,0,176,0V112A87.72,87.72,0,0,0,191.83,51.48ZM128,40a72.08,72.08,0,0,1,72,72v8H56v-8A72.08,72.08,0,0,1,128,40Zm0,184a72.08,72.08,0,0,1-72-72V136H200v16A72.08,72.08,0,0,1,128,224ZM144,92a12,12,0,1,1,12,12A12,12,0,0,1,144,92ZM88,92a12,12,0,1,1,12,12A12,12,0,0,1,88,92Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M191.83,51.48l13.83-13.82a8,8,0,0,0-11.32-11.32L179.79,40.9a87.81,87.81,0,0,0-103.58,0L61.66,26.34A8,8,0,0,0,50.34,37.66L64.17,51.48A87.72,87.72,0,0,0,40,112v40a88,88,0,0,0,176,0V112A87.72,87.72,0,0,0,191.83,51.48ZM128,40a72.08,72.08,0,0,1,72,72v8H56v-8A72.08,72.08,0,0,1,128,40Zm16,52a12,12,0,1,1,12,12A12,12,0,0,1,144,92ZM88,92a12,12,0,1,1,12,12A12,12,0,0,1,88,92Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M189,51.47l15.22-15.23a6,6,0,1,0-8.48-8.48L180,43.54A85.76,85.76,0,0,0,76,43.54L60.24,27.76a6,6,0,0,0-8.48,8.48L67,51.47A85.7,85.7,0,0,0,42,112v40a86,86,0,0,0,172,0V112A85.7,85.7,0,0,0,189,51.47ZM128,38a74.09,74.09,0,0,1,74,74v10H54V112A74.09,74.09,0,0,1,128,38Zm0,188a74.09,74.09,0,0,1-74-74V134H202v18A74.09,74.09,0,0,1,128,226ZM146,92a10,10,0,1,1,10,10A10,10,0,0,1,146,92ZM90,92a10,10,0,1,1,10,10A10,10,0,0,1,90,92Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M191.83,51.48l13.83-13.82a8,8,0,0,0-11.32-11.32L179.79,40.9a87.81,87.81,0,0,0-103.58,0L61.66,26.34A8,8,0,0,0,50.34,37.66L64.17,51.48A87.72,87.72,0,0,0,40,112v40a88,88,0,0,0,176,0V112A87.72,87.72,0,0,0,191.83,51.48ZM128,40a72.08,72.08,0,0,1,72,72v8H56v-8A72.08,72.08,0,0,1,128,40Zm0,184a72.08,72.08,0,0,1-72-72V136H200v16A72.08,72.08,0,0,1,128,224ZM144,92a12,12,0,1,1,12,12A12,12,0,0,1,144,92ZM88,92a12,12,0,1,1,12,12A12,12,0,0,1,88,92Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M186.17,51.49l16.66-16.66a4,4,0,1,0-5.66-5.66l-17,17a83.72,83.72,0,0,0-104.26,0l-17-17a4,4,0,0,0-5.66,5.66L69.83,51.49A83.75,83.75,0,0,0,44,112v40a84,84,0,0,0,168,0V112A83.75,83.75,0,0,0,186.17,51.49ZM128,36a76.08,76.08,0,0,1,76,76v12H52V112A76.08,76.08,0,0,1,128,36Zm0,192a76.08,76.08,0,0,1-76-76V132H204v20A76.08,76.08,0,0,1,128,228ZM148,92a8,8,0,1,1,8,8A8,8,0,0,1,148,92ZM92,92a8,8,0,1,1,8,8A8,8,0,0,1,92,92Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
