/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M243,89.83A19.94,19.94,0,0,0,224,76H165.85l-18.8-57.95A19.93,19.93,0,0,0,109,18L90.15,76H32A20,20,0,0,0,20.3,112.22l47.1,33.89-18.07,55.7A20,20,0,0,0,80.07,224.2L128,189.71l47.89,34.45a20,20,0,0,0,30.78-22.35l-18.07-55.7,47.15-33.92A20,20,0,0,0,243,89.83ZM128,37.11,140.62,76H115.38ZM44.42,100H82.36l-7.18,22.13Zm31.51,97.6,11.93-36.78,19.59,14.1Zm19.71-60.75,12-36.85h40.82l12,36.85L128,160.14Zm52.91,38.07,19.59-14.1,11.93,36.78Zm32.27-52.79L173.64,100h37.94Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M228.69,102.47l-54.21,39,20.77,64a8,8,0,0,1-12.31,8.95L128,174.91,73.06,214.44a8,8,0,0,1-12.31-8.95l20.77-64-54.21-39A8,8,0,0,1,32,88H98.87l21.52-66.32a7.94,7.94,0,0,1,15.22,0L157.13,88H224A8,8,0,0,1,228.69,102.47Z" opacity="0.2"/><path d="M239.2,91.06A15.76,15.76,0,0,0,224,80h-61l-19.7-60.74a15.94,15.94,0,0,0-30.46-.05L93.06,80H32a16,16,0,0,0-9.38,29l49.47,35.59L53.14,203A15.77,15.77,0,0,0,59,220.91a15.77,15.77,0,0,0,18.78,0L128,184.77l50.24,36.14A16,16,0,0,0,202.86,203l-19-58.46,49.5-35.62A15.76,15.76,0,0,0,239.2,91.06ZM128,24.15,146.12,80H109.88ZM32,96H87.87L77.3,128.58ZM68.36,208h0l17.39-53.59,28.55,20.54Zm22.57-69.57L104.69,96h46.62l13.76,42.39L128,165.06ZM187.64,208l0,0-45.91-33,28.55-20.54Zm-8.94-79.38L168.13,96H224Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M60.18,129.52a4,4,0,0,1-6.15,2L22.66,109a15.78,15.78,0,0,1-5.82-18A16.33,16.33,0,0,1,32.43,80H70.74a4,4,0,0,1,3.81,5.24Zm-7,73.48A15.75,15.75,0,0,0,59,220.88a15.74,15.74,0,0,0,18.77,0l32.05-23.06a4,4,0,0,0,0-6.5L71.38,163.72a4,4,0,0,0-6.14,2ZM143.23,19.26a15.93,15.93,0,0,0-30.45-.05L100,58.76A4,4,0,0,0,103.76,64h48.48a4,4,0,0,0,3.81-5.23ZM160,80H96a4,4,0,0,0-3.8,2.77L73,141.77a4,4,0,0,0,1.47,4.48l51.17,36.82a4,4,0,0,0,4.68,0l51.17-36.82a4,4,0,0,0,1.47-4.48l-19.15-59A4,4,0,0,0,160,80Zm79.13,11a16.33,16.33,0,0,0-15.59-11H185.26a4,4,0,0,0-3.81,5.24l14.37,44.29a4,4,0,0,0,6.14,2l31.41-22.6A15.75,15.75,0,0,0,239.16,91Zm-54.55,72.75-38.4,27.63a4,4,0,0,0,0,6.5l32,23A16,16,0,0,0,202.85,203l-12.09-37.27A4,4,0,0,0,184.61,163.72Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M237.29,91.67A13.8,13.8,0,0,0,224,82H161.48L141.33,19.87a13.93,13.93,0,0,0-26.64-.05L94.51,82H32a14,14,0,0,0-8.21,25.35l50.65,36.44L55.05,203.63a13.78,13.78,0,0,0,5.09,15.64,13.77,13.77,0,0,0,16.43,0l51.43-37,51.41,37A14,14,0,0,0,201,203.63l-19.42-59.85,50.67-36.46A13.79,13.79,0,0,0,237.29,91.67ZM126.11,23.49a1.94,1.94,0,0,1,3.79,0l19,58.46H107.13ZM30.85,97.61a1.86,1.86,0,0,1-.73-2.23A1.88,1.88,0,0,1,32,94H90.62l-12.26,37.8Zm38.69,112a2,2,0,0,1-3.08-2.24h0l18.23-56.2,33,23.77Zm19-70.42L103.24,94h49.52l14.65,45.16L128,167.51Zm100.95,68.18a2,2,0,0,1-3.1,2.22L138.27,174.9l33-23.77ZM225.17,97.59l-47.53,34.2L165.38,94H224a1.86,1.86,0,0,1,1.9,1.39A1.83,1.83,0,0,1,225.17,97.59Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M239.18,91.05A15.75,15.75,0,0,0,224,80h-61L143.23,19.26a15.93,15.93,0,0,0-30.45-.05L93.06,80H32a16,16,0,0,0-9.37,29l49.46,35.58L53.15,203A15.75,15.75,0,0,0,59,220.88a15.74,15.74,0,0,0,18.77,0L128,184.75l50.23,36.13A16,16,0,0,0,202.85,203l-19-58.46,49.49-35.61A15.74,15.74,0,0,0,239.18,91.05ZM128,24.15,146.12,80H109.88ZM32,96H87.87L77.3,128.56Zm36.34,112h0l17.39-53.59,28.54,20.54Zm22.57-69.57L104.69,96h46.62l13.75,42.38L128,165Zm96.69,69.57,0,0-45.9-33,28.54-20.54Zm-8.93-79.38L168.13,96H224Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M235.39,92.29A11.82,11.82,0,0,0,224,84H160L139.42,20.47a11.93,11.93,0,0,0-22.83,0L96,84H32a12,12,0,0,0-7,21.72L76.82,143,57,204.25a11.83,11.83,0,0,0,4.36,13.41,11.8,11.8,0,0,0,14.09,0L128,179.83l52.58,37.83a12,12,0,0,0,18.47-13.41L179.18,143,231,105.7A11.83,11.83,0,0,0,235.39,92.29ZM124.21,22.88a3.93,3.93,0,0,1,7.59,0L151.62,84H104.38ZM29.67,99.23A4,4,0,0,1,32,92H93.37l-14,43Zm41,112a4,4,0,0,1-6.15-4.47h0l19.08-58.8,37.51,27Zm15.52-71.26L101.78,92h52.44l15.55,47.93L128,170Zm105.21,66.79a4,4,0,0,1-6.17,4.46L134.85,174.9l37.51-27Zm34.9-107.5L176.59,135l-14-43H224a4,4,0,0,1,2.36,7.22Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
