/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M232.38,68.52A20,20,0,0,0,216,60H140V36h12a12,12,0,0,0,0-24H104a12,12,0,0,0,0,24h12V60H40A20,20,0,0,0,21.2,86.83l26.19,72A20.06,20.06,0,0,0,66.18,172H92v60a12,12,0,0,0,24,0V172h24v60a12,12,0,0,0,24,0V172h25.82a20.06,20.06,0,0,0,18.79-13.17l26.19-72A20,20,0,0,0,232.38,68.52ZM114,148,102.38,84h51.24L142,148ZM45.71,84H78l11.64,64H69ZM187,148H166.38L178,84h32.27Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M223.51,82.73l-26.18,72a8,8,0,0,1-7.52,5.27H66.19a8,8,0,0,1-7.52-5.27l-26.18-72A8,8,0,0,1,40,72H216A8,8,0,0,1,223.51,82.73Z" opacity="0.2"/><path d="M229.11,70.82A16,16,0,0,0,216,64H136V32h16a8,8,0,0,0,0-16H104a8,8,0,0,0,0,16h16V64H40A16,16,0,0,0,25,85.47l26.19,72a16,16,0,0,0,15,10.53H96v64a8,8,0,0,0,16,0V168h32v64a8,8,0,0,0,16,0V168h29.82a16,16,0,0,0,15-10.53l26.19-72A16,16,0,0,0,229.11,70.82ZM110.68,152,97.58,80h60.84l-13.1,72ZM40,80H81.32l13.09,72H66.18Zm149.82,72H161.59l13.09-72H216Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M229.11,70.82A16,16,0,0,0,216,64H136V32h16a8,8,0,0,0,0-16H104a8,8,0,0,0,0,16h16V64H40A16,16,0,0,0,25,85.47l26.19,72a16,16,0,0,0,15,10.53H189.82a16,16,0,0,0,15-10.53l26.19-72A16,16,0,0,0,229.11,70.82ZM102.52,151.87a7.87,7.87,0,0,1-1.44.13,8,8,0,0,1-7.86-6.57L83,89.43a8,8,0,0,1,15.75-2.86l10.18,56A8,8,0,0,1,102.52,151.87ZM173,89.43l-10.19,56a8,8,0,0,1-7.86,6.57,7.87,7.87,0,0,1-1.44-.13,8,8,0,0,1-6.44-9.3l10.18-56A8,8,0,0,1,173,89.43ZM160,188v44a8,8,0,0,1-8,8H104a8,8,0,0,1-8-8V188a4,4,0,0,1,4-4h56A4,4,0,0,1,160,188Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M227.47,72A14,14,0,0,0,216,66H134V30h18a6,6,0,0,0,0-12H104a6,6,0,0,0,0,12h18V66H40A14,14,0,0,0,26.84,84.78l26.19,72A14,14,0,0,0,66.18,166H98v66a6,6,0,0,0,12,0V166h36v66a6,6,0,0,0,12,0V166h31.82A14,14,0,0,0,203,156.78l26.19-72A14,14,0,0,0,227.47,72ZM109,154,95.19,78h65.62L147,154ZM64.3,152.68l-26.18-72a2,2,0,0,1,.24-1.83A1.94,1.94,0,0,1,40,78H83l13.82,76H66.18A2,2,0,0,1,64.3,152.68Zm153.58-72-26.18,72a2,2,0,0,1-1.88,1.32H159.19L173,78h43a1.94,1.94,0,0,1,1.64.85A2,2,0,0,1,217.88,80.68Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M229.11,70.82A16,16,0,0,0,216,64H136V32h16a8,8,0,0,0,0-16H104a8,8,0,0,0,0,16h16V64H40A16,16,0,0,0,25,85.47l26.19,72a16,16,0,0,0,15,10.53H96v64a8,8,0,0,0,16,0V168h32v64a8,8,0,0,0,16,0V168h29.82a16,16,0,0,0,15-10.53l26.19-72A16,16,0,0,0,229.11,70.82ZM110.68,152,97.58,80h60.84l-13.1,72ZM40,80H81.32l13.09,72H66.18Zm149.82,72H161.59l13.09-72H216Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M225.83,73.11A12,12,0,0,0,216,68H132V28h20a4,4,0,0,0,0-8H104a4,4,0,0,0,0,8h20V68H40A12,12,0,0,0,28.72,84.1l26.19,72A12,12,0,0,0,66.18,164H100v68a4,4,0,0,0,8,0V164h40v68a4,4,0,0,0,8,0V164h33.82a12,12,0,0,0,11.27-7.9l26.19-72A12,12,0,0,0,225.83,73.11ZM107.34,156,92.79,76h70.42l-14.55,80Zm-44.92-2.63-26.18-72A4,4,0,0,1,40,76H84.66L99.2,156h-33A4,4,0,0,1,62.42,153.37Zm157.34-72-26.18,72a4,4,0,0,1-3.76,2.63h-33l14.55-80H216a4,4,0,0,1,3.76,5.37Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
