/* GENERATED FILE */ import { template } from "@ember/template-compiler";
import PhIcon from '../ph-icon.ts';
export default class PhAcorn extends PhIcon {
    static{
        template(`
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={{this.size}}
      height={{this.size}}
      fill={{this.color}}
      transform={{this.mirrored}}
      ...attributes
    >
      {{yield}}
      
      {{#if (this.eq this.weight "bold")}}<g><path d="M236,198.65V240a12,12,0,0,1-24,0V198.65A70.66,70.66,0,0,0,196,154v48.27a12,12,0,0,1-22,6.57l-10.67-16.3c-.13-.19-.25-.38-.36-.58a8,8,0,0,0-14,7.77l22,33.66a12,12,0,1,1-20.08,13.14l-22.26-34c-.12-.19-.24-.38-.35-.58A32,32,0,0,1,172,168.3V68h-8a12,12,0,0,1,0-24h12a20,20,0,0,1,20,20v57.52A94.91,94.91,0,0,1,236,198.65ZM88,56A12,12,0,0,0,76,44H64A20,20,0,0,0,44,64V200a12,12,0,0,0,24,0V68h8A12,12,0,0,0,88,56Zm68.49,60.48a12,12,0,0,0-17-17L132,107V16a12,12,0,0,0-24,0v91l-7.51-7.52a12,12,0,0,0-17,17l28,28a12,12,0,0,0,17,0Z"/></g>{{/if}}
      {{#if (this.eq this.weight "duotone")}}<g><path d="M184,64V202.31L173.32,186a20,20,0,0,0-36.9,14H56V64a8,8,0,0,1,8-8H176A8,8,0,0,1,184,64Z" opacity="0.2"/><path d="M232,198.65V240a8,8,0,0,1-16,0V198.65A74.84,74.84,0,0,0,192,144v58.35a8,8,0,0,1-14.69,4.38l-10.68-16.31c-.08-.12-.16-.25-.23-.38a12,12,0,0,0-20.89,11.83l22.13,33.79a8,8,0,0,1-13.39,8.76l-22.26-34-.24-.38A28,28,0,0,1,176,176.4V64H160a8,8,0,0,1,0-16h16a16,16,0,0,1,16,16v59.62A90.89,90.89,0,0,1,232,198.65ZM88,56a8,8,0,0,0-8-8H64A16,16,0,0,0,48,64V200a8,8,0,0,0,16,0V64H80A8,8,0,0,0,88,56Zm69.66,42.34a8,8,0,0,0-11.32,0L128,116.69V16a8,8,0,0,0-16,0V116.69L93.66,98.34a8,8,0,0,0-11.32,11.32l32,32a8,8,0,0,0,11.32,0l32-32A8,8,0,0,0,157.66,98.34Z"/></g>{{/if}}
      {{#if (this.eq this.weight "fill")}}<g><path d="M128,56H112V16a8,8,0,0,1,16,0Zm64,67.62V72a16,16,0,0,0-16-16H128v60.69l18.34-18.35a8,8,0,0,1,11.32,11.32l-32,32a8,8,0,0,1-11.32,0l-32-32A8,8,0,0,1,93.66,98.34L112,116.69V56H64A16,16,0,0,0,48,72V200a8,8,0,0,0,8,8h74.7c.32.67.67,1.34,1.05,2l.24.38,22.26,34a8,8,0,0,0,13.39-8.76l-22.13-33.79A12,12,0,0,1,166.4,190c.07.13.15.26.23.38l10.68,16.31A8,8,0,0,0,192,202.31V144a74.84,74.84,0,0,1,24,54.69V240a8,8,0,0,0,16,0V198.65A90.89,90.89,0,0,0,192,123.62Z"/></g>{{/if}}
      {{#if (this.eq this.weight "light")}}<g><path d="M230,198.65V240a6,6,0,0,1-12,0V198.65a76.83,76.83,0,0,0-28-59.08v62.74a6,6,0,0,1-11,3.28l-10.68-16.3a2.75,2.75,0,0,1-.17-.29,14,14,0,0,0-24.33,13.87L166,236.71a6,6,0,0,1-10,6.58l-22.26-34c-.06-.1-.12-.19-.17-.29A26,26,0,0,1,178,182.14V64a2,2,0,0,0-2-2H160a6,6,0,0,1,0-12h16a14,14,0,0,1,14,14v60.69A88.88,88.88,0,0,1,230,198.65ZM86,56a6,6,0,0,0-6-6H64A14,14,0,0,0,50,64V200a6,6,0,0,0,12,0V64a2,2,0,0,1,2-2H80A6,6,0,0,0,86,56Zm70.24,43.76a6,6,0,0,0-8.48,0L126,121.51V16a6,6,0,0,0-12,0V121.51L92.24,99.76a6,6,0,0,0-8.48,8.48l32,32a6,6,0,0,0,8.48,0l32-32A6,6,0,0,0,156.24,99.76Z"/></g>{{/if}}
      {{#if (this.eq this.weight "regular")}}<g><path d="M232,198.65V240a8,8,0,0,1-16,0V198.65A74.84,74.84,0,0,0,192,144v58.35a8,8,0,0,1-14.69,4.38l-10.68-16.31c-.08-.12-.16-.25-.23-.38a12,12,0,0,0-20.89,11.83l22.13,33.79a8,8,0,0,1-13.39,8.76l-22.26-34-.24-.38A28,28,0,0,1,176,176.4V64H160a8,8,0,0,1,0-16h16a16,16,0,0,1,16,16v59.62A90.89,90.89,0,0,1,232,198.65ZM88,56a8,8,0,0,0-8-8H64A16,16,0,0,0,48,64V200a8,8,0,0,0,16,0V64H80A8,8,0,0,0,88,56Zm69.66,42.34a8,8,0,0,0-11.32,0L128,116.69V16a8,8,0,0,0-16,0V116.69L93.66,98.34a8,8,0,0,0-11.32,11.32l32,32a8,8,0,0,0,11.32,0l32-32A8,8,0,0,0,157.66,98.34Z"/></g>{{/if}}
      {{#if (this.eq this.weight "thin")}}<g><path d="M228,198.65V240a4,4,0,0,1-8,0V198.65a78.83,78.83,0,0,0-32-63.18v66.84a4,4,0,0,1-7.35,2.19L170,188.19l-.12-.19a16,16,0,0,0-27.77,15.91l22.2,33.9a4,4,0,1,1-6.69,4.38l-22.26-34a1.22,1.22,0,0,1-.12-.19,24,24,0,0,1,41.51-24.1l3.27,5V64a4,4,0,0,0-4-4H160a4,4,0,0,1,0-8h16a12,12,0,0,1,12,12v61.78A86.84,86.84,0,0,1,228,198.65ZM80,52H64A12,12,0,0,0,52,64V200a4,4,0,0,0,8,0V64a4,4,0,0,1,4-4H80a4,4,0,0,0,0-8Zm74.83,49.17a4,4,0,0,0-5.66,0L124,126.34V16a4,4,0,0,0-8,0V126.34L90.83,101.17a4,4,0,0,0-5.66,5.66l32,32a4,4,0,0,0,5.66,0l32-32A4,4,0,0,0,154.83,101.17Z"/></g>{{/if}}
    </svg>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
